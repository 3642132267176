export interface AccAccount {
    account_id: number;
    account_code: string;
    description: string;
    classification_code: string;
    account_type: string;
    accept_cost_center: boolean;
}

export enum AccountsTypes {
    RealizedGainExchange,
    RealizedLossExchange,
    UnrealizedGainExchange,
    UnrealizedLossExchange,
    Cash,
    AccountsReceivable,
    AccountsPayable,
    Sales,
    ServicesSales,
    SalesReturns,
    Purchase,
    PurchaseRetruns,
    NotesReceivable,
    NotesPayable,
    Inventory,
    COGS,
    SalesDiscount,
    OtherRevenue,
    Waste,
    Bank,
    RetainedEarning,
    SalesTax,
    WithholdingTax,
    PurchaseDiscount,
    Adjustment,
    AddedTax,
    EndingInventory,
    PurchaseAddedTax
}

