import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { SwitchLanguageService } from '../../core/services/switch-language.service';

@Pipe({
  name: 'dateFormatter',
})

export class DateFormatter implements PipeTransform {

  format = 'longDate';
  locale = 'en';

  constructor(private switchLanguageService: SwitchLanguageService) {
    this.locale = this.switchLanguageService.currentLanguage;
  }

  transform(
    value: Date | string,
    format: string = this.format,
    locale: string = this.locale,
  ): string {
    if (!value) {
      return '';
    }

    return formatDate(
      value,
      format,
      locale
    );
  }
}
