import { Injectable } from '@angular/core';
import { AuthService } from '@auth';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

export class AppState {
  pageCssClass: string = '';
  navState: 'expanded' | 'collapsed' | 'closed' = 'expanded';
  theme: 'light' | 'dark' = 'light';
  showBodyControls: boolean = true;
  chatState = { xPos: 0, yPos: 0 };
  messengerState = { horizontal_padding: 0, vertical_padding: 0 }
}

@Injectable({
  providedIn: 'root'
})

export class AppService {
  private themeKey: string = 'theme';
  private layoutKey: string = 'layout';
  private chatKey: string = 'chat';
  public isQuickActionsOpend: boolean = false;
  private readonly _appState = new BehaviorSubject<AppState>(new AppState());
  readonly appState$ = this._appState.asObservable();

  get state(): AppState {
    return this._appState.getValue();
  }

  constructor(public authService: AuthService,
    private deviceDetector: DeviceDetectorService,
    private translateService: TranslateService
  ) { }

  /**
   * Change nav bar state
   * @param navState 'expanded' | 'collapsed' | 'closed'
   */
  public handleNavState(navState: 'expanded' | 'collapsed' | 'closed') {
    this._appState.next({ ...this.state, navState });
    document.body.classList.remove('layout--expanded', 'layout--collapsed', 'layout--closed');
    document.body.classList.add(`layout--${navState}`);
    localStorage.setItem(this.layoutKey, navState);
  }

  /**
   * Change app theme to light or dark, save current theme valur in localstorage
   * @param theme 'dark' | 'light'
   */
  public changeTheme(theme: 'dark' | 'light') {
    this._appState.next({ ...this.state, theme });
    document.body.classList.remove('light-theme', 'dark-theme');
    document.body.classList.add(`${theme}-theme`);
    localStorage.setItem(this.themeKey, theme);
  }

  /**
   * Detect device current theme based on preferences
   * @returns 'light' | 'dark'
   */
  private detectCurrentTheme(): 'light' | 'dark' {
    return window.matchMedia('(prefers-color-scheme: light)').matches ? 'light' : 'dark';
  }

  public handleBodyControls(bodyControlsState: boolean) {
    this._appState.next({ ...this.state, showBodyControls: bodyControlsState });
  }

  public toggleQuickActions() {
    this.isQuickActionsOpend = !this.isQuickActionsOpend
    document.body.classList.toggle('layout--expanded-end');
  }

  public changeChatPosition(x: number, y: number) {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const chatBoxWidth = 80;
    const chatBoxHeight = 56;
    const messengerWidth = 385;
    const chatVal = { xPos: x, yPos: y };

    this._appState.next({ ...this.state, chatState: chatVal });
    localStorage.setItem(this.chatKey, JSON.stringify(chatVal));

    // set messenger position
    if (x <= windowWidth / 2) {
      if (y <= windowHeight / 2) {
        this._appState.next({
          ...this.state,
          messengerState: {
            horizontal_padding: this.state.chatState.xPos + chatBoxWidth / 2,
            vertical_padding: windowHeight - this.state.chatState.yPos - this.getMessengerHeight() - chatBoxHeight / 2
          }
        });
        document.body.setAttribute('data-intercom-origin', 'origin-1');
      } else {
        this._appState.next({
          ...this.state,
          messengerState: {
            horizontal_padding: this.state.chatState.xPos + chatBoxWidth / 2,
            vertical_padding: windowHeight - this.state.chatState.yPos - chatBoxHeight / 2
          }
        });
        document.body.setAttribute('data-intercom-origin', 'origin-2');
      }
    } else {
      if (y <= windowHeight / 2) {
        this._appState.next({
          ...this.state,
          messengerState: {
            horizontal_padding: this.state.chatState.xPos - messengerWidth + chatBoxWidth / 2,
            vertical_padding: windowHeight - this.state.chatState.yPos - this.getMessengerHeight() - chatBoxHeight / 2
          }
        });
        document.body.setAttribute('data-intercom-origin', 'origin-3');
      } else {
        this._appState.next({
          ...this.state,
          messengerState: {
            horizontal_padding: this.state.chatState.xPos - messengerWidth + chatBoxWidth / 2,
            vertical_padding: windowHeight - this.state.chatState.yPos - chatBoxHeight / 2
          }
        });
        document.body.setAttribute('data-intercom-origin', 'origin-4');
      }
    }

  }

  private getMessengerHeight() {
    return Math.min(700, window.innerHeight - 180);
  }

  /**
   * set initial theme and layout
   */
  public init() {
    // show body
    document.body.classList.add('body--show');
    // set theme
    let theme: any = localStorage.getItem(this.themeKey) || this.detectCurrentTheme();
    if (theme) this.changeTheme(theme);

    // set side nav width
    let layout: any = localStorage.getItem(this.layoutKey);
    if (this.deviceDetector.isMobile()) {
      this.handleNavState('collapsed');
    } else {
      if (layout) this.handleNavState(layout);
      else if (this.deviceDetector.isTablet()) this.handleNavState('collapsed');
    }

    // set initial chat position
    const storedData = localStorage.getItem(this.chatKey);
    if (storedData) {
      try {
        const chatPos = JSON.parse(storedData);
        this.changeChatPosition(chatPos.xPos, chatPos.yPos)
      } catch (error) {
        console.error('Error parsing JSON from localStorage:', error);
        this.changeChatPosition(window.innerWidth - 80 - 60, window.innerHeight - 56 - 60);
      }
    } else {
      this.changeChatPosition(window.innerWidth - 80 - 60, window.innerHeight - 56 - 60);
    }
    this.setBodyPadding();
  }

  setBodyPadding() {
    if (this.authService.isAuthenticated()) {
      document.body.classList.remove('body--login');
    } else {
      document.body.classList.add('body--login');
    }
  }

  private fullLayoutEnabled$ = new Subject<boolean>();

  enableFullLayout() {
    this.fullLayoutEnabled$.next(true);
  }

  disableFullLayout() {
    this.fullLayoutEnabled$.next(false);
  }

  onFullLayoutEnabled() {
    this.fullLayoutEnabled$ ??= new Subject<boolean>();
    return this.fullLayoutEnabled$.pipe(
      tap(this.addFullLayoutStyles),
      tap(this.removeFullLayoutStyles),
    );
  }

  private addFullLayoutStyles = (enabled: boolean): void => {
    if (!enabled) {
      return;
    }
    const body = document.querySelector<HTMLBodyElement>('body');
    body?.classList.add('transition-none');
    body?.classList.add('p-0');
  }

  private removeFullLayoutStyles = (enabled: boolean): void => {
    if (enabled) {
      return;
    }
    const body = document.querySelector<HTMLBodyElement>('body');
    body?.classList.remove('p-0');
    body?.classList.remove('transition-none');
  }
}
