import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-note-alert',
  templateUrl: './note-alert.component.html',
  styleUrl: './note-alert.component.scss'
})
export class NoteAlertComponent {
  @Input() message: string = '';
}
