export interface ApiResponse<T> {
    status_code: number;
    error_message: string;
    result: T;
    total_count: number;
    items?: T;
}

export enum ErrorType {
    BadRequest = "Bad Request",
    InternalServerError = "Internal Server Error"
}