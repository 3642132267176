import { Component } from '@angular/core';
import { getColorFromEmail } from '@shared/helpers/user-avatar-color-generator';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-ag-user-email-chip-renderer',
  templateUrl: './ag-user-email-chip-renderer.component.html',
  styleUrl: './ag-user-email-chip-renderer.component.scss'
})
export class AgUserEmailChipRendererComponent implements ICellRendererAngularComp {

  userInitals?: string;
  userEmail?: string;
  backgroundColor?: string;

  agInit(params: ICellRendererParams<any, any, any>): void {
    if (!params.value) return;
    this.userEmail = params.value;
    this.userInitals = params.value.charAt(0).toLocaleUpperCase();
    this.backgroundColor = getColorFromEmail(params.value);
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return true;
  }

}
