<div class="dialog__height" [dir]="('uiDirection' | translate)">
    <mat-dialog-content>
        <p class="font-size-s margin-bottom-base margin-top-s">
            {{ "previewFeatures.header" | translate }}
        </p>
        <!-- // Feature card  -->
        <mat-card class="card-filled background-light margin-bottom-base">
        <!-- Feature page name -->
        <h3 _ngcontent-ng-cli-universal-c247="" class="font-medium font-size-base margin-bottom-xs">{{'nav-menu.pos' |
                translate}}</h3>
        <!-- Feature title and switch -->
         <div class="d-flex align-items-center justify-content-between">
                    <h4 class="typo-color--primary m-0 font-size-s font-medium"> {{ "previewFeatures.newSalesOrderLayout" | translate }} </h4>
                    <mat-slide-toggle id="toggle_newSalesOrderLayout" color="primary" [checked]="newSalesOrderLayout"
                        (change)="onNewSalesOrderLayoutChanged($event)" *ngIf="!loading">
                    </mat-slide-toggle>
                    <mat-spinner id="spinner" diameter="20" *ngIf="loading"></mat-spinner>
        </div>
        <!-- Feature description -->
        <p class="mt-2 font-size-s text-description">
            {{ "previewFeatures.newSalesOrderLayoutDetails" | translate }}
            <img src="/assets/images/smile-face.png" width="20" />
        </p>
        </mat-card>
        <!-- // End feature card  -->
    </mat-dialog-content>
</div>