import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AuditLogService } from 'src/app/core/services/audit-log.service';
import { AuditEvent, ChangeLog } from '../../models/audit-log';
import { AuditLogQuery } from '../../models/query';

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss']
})
export class AuditLogComponent implements OnInit {

  auditEvents: AuditEvent[] = [];
  query!: AuditLogQuery;
  dateLocale!: string;
  loading = false;
  endOfResults = false;

  constructor(private auditLogService: AuditLogService,
    public translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any) { }

  ngOnInit(): void {
    this.query = {
      offset: 0,
      limit: 10,
      entity_id: this.dialogData?.entity_id
    };

    this.dateLocale = this.translateService.currentLang === 'en' ? 'en-US' : 'ar-SA';

    if (this.dialogData?.entity_id) {
      this.findAuditLogs();
    }
  }

  findAuditLogs() {
    this.loading = true;
    this.auditLogService.findAuditLogs(this.query).subscribe({
      next: (res: AuditEvent[]) => {
        this.auditEvents = this.auditEvents.concat(res);
        this.endOfResults = res!.length < this.query.limit;
        this.loading = false;
      },
      error: (err: any) => {
        this.loading = false;
        this.endOfResults = true;
      }
    });
  }

  changeTitle(changeLog: ChangeLog): string {
    if (changeLog.change_type === 'UpdateHeader') {
      return this.translateService.instant('auditLog.UpdateHeader');
    }
    else {
      return `${this.translateService.instant('auditLog.' + changeLog.change_type)} ${this.translateService.instant(this.dialogData.translateKey + changeLog.detail_type)}  [${changeLog.detail_description}]`;
    }
  }

}
