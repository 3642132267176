import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { FeaturesConfigService } from '../../core/services/features-config.service';
import { OnlineOfflineService } from '../../core/services/online-offine.service';

@Directive({
  selector: '[removeFeatureToggle]'
})
export class RemoveFeaturesDirective implements OnInit {

  @Input('removeFeatureToggle') featureName?: string;

  parentNode: any;

  constructor(private el: ElementRef,
    private featuresFlagsConfigService: FeaturesConfigService,
    private onlineOfflineService: OnlineOfflineService) {
  }

  ngOnInit() {
    this.parentNode = this.el.nativeElement.parentNode;

    this.handleVisiability();
    this.onlineOfflineService.connectionChanged.subscribe(() => this.handleVisiability());
  }

  private handleVisiability() {
    if (!this.featuresFlagsConfigService.isFeatureEnabled(this.featureName ?? '')) {
      this.removeElement();
    } else {
      this.addEelement();
    }
  }

  private removeElement() {
    this.parentNode.removeChild(this.el.nativeElement);
  }

  private addEelement() {
    if (this.parentNode && !this.parentNode.contains(this.el.nativeElement)) {
      this.parentNode.appendChild(this.el.nativeElement);
    }
  }
}
