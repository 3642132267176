import { Injectable } from '@angular/core';

@Injectable()
export class SoundHelper {
    context: AudioContext;
    constructor() {
        this.context = new AudioContext();
    }

    beep() {
        this.playBeebSound();
    }

    click() {
        this.playClickSound();
    }

    private playBeebSound() {
        const audio = new Audio();
        audio.src = 'assets/sounds/short_tone.mp3';
        audio.load();
        audio.play();
    }

    private playClickSound() {
        const audio = new Audio();
        audio.src = 'assets/sounds/click_tone.mp3';
        audio.load();
        audio.play();
    }

}
