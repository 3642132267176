import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { QueryStringHelper } from 'src/app/shared/helpers/query-string.helper';
import { PagedList } from 'src/app/shared/models/paged-list';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { PurchaseInvoiceQuery, SupplierQuery } from 'src/app/shared/models/query';
import { AppSettingsService } from 'src/app/core/services/app-settings.service';
import { InvoicingProduct, PurchaseInvoice, PurchaseInvoicePaymentStatus, PurchaseOrderQuery, PurchaseRequestListing, PurchaseRequestQuery, ReceivedStatus, Supplier } from '../models';
import { PrchLocation } from 'src/app/purchasing/models/prch-location';

@Injectable({
  providedIn: 'root'
})
export class InvoicingService {

  baseUrl = '';
  supplierFilter?: BehaviorSubject<SupplierQuery>;
  purchaseRequestFilter?: BehaviorSubject<PurchaseRequestQuery>;
  purchaseRequestListing?: BehaviorSubject<PurchaseRequestListing>;
  purchaseOrderFilter?: BehaviorSubject<PurchaseOrderQuery>;
  purchaseInvocieFilter?: BehaviorSubject<PurchaseInvoiceQuery>;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = AppSettingsService.appSettings.edaraCoreApi.baseUrl;
    if (this.baseUrl.endsWith('/')) {
      this.baseUrl = this.baseUrl.slice(0, -1);
    }
  }

  //#region Supplier

  getAllSuppliersAsync(): Promise<Supplier[] | undefined> {
    return firstValueFrom(
      this.httpClient.get<Supplier[]>(this.baseUrl + '/api/Suppliers')
    ).catch(() => { return undefined; });
  }

  findSuppliersAsync(query: any): Promise<PagedList<Supplier[]> | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<PagedList<Supplier[]>>(this.baseUrl + '/api/Suppliers/find?' + queryString)
    ).catch(() => { return undefined; });
  }

  getSupplierByIdAsync(id: string): Promise<Supplier | undefined> {
    return firstValueFrom(
      this.httpClient.get<Supplier>(this.baseUrl + '/api/Suppliers/' + id)
    ).catch(() => { return undefined; });
  }

  createSupplier(supplier: Supplier): Observable<Supplier> {
    return this.httpClient.post<Supplier>(this.baseUrl + '/api/Suppliers', supplier);
  }

  updateSupplier(supplier: Supplier): Observable<boolean> {
    return this.httpClient.put<boolean>(this.baseUrl + '/api/Suppliers/' + supplier.id, supplier);
  }

  //#endregion

  getAllLocationsAsync(): Promise<PrchLocation[] | undefined> {
    return firstValueFrom(
      this.httpClient.get<PrchLocation[]>(this.baseUrl + '/api/purchasing/Locations')
    ).catch(() => { return undefined; });
  }

  searchProducts(keywords: string[]): Observable<PagedList<InvoicingProduct[]>> {
    const query = { keywords: keywords };
    const queryString = new QueryStringHelper().toQueryString(query);
    return this.httpClient.get<PagedList<InvoicingProduct[]>>(this.baseUrl + '/api/Invoicing/Products/Search?' + queryString);
  }

  postSearchProducts(keywords: string[], limit: number = 1000): Observable<PagedList<InvoicingProduct[]>> {
    return this.httpClient.post<PagedList<InvoicingProduct[]>>(this.baseUrl + '/api/Invoicing/Products/Search', {
      keywords,
      offset: 0,
      limit
    });
  }

  findProductsAsync(query: any): Promise<PagedList<InvoicingProduct[]> | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<PagedList<InvoicingProduct[]>>(this.baseUrl + '/api/Invoicing/Products/find?' + queryString)
    ).catch(() => { return undefined; });
  }

  postFindProductsAsync(query: any): Promise<PagedList<InvoicingProduct[]> | undefined> {
    return firstValueFrom(
      this.httpClient.post<PagedList<InvoicingProduct[]>>(this.baseUrl + '/api/Invoicing/Products/find', query)
    ).catch(() => { return undefined; });
  }

  importSuppliers(suppliers: Supplier[]) {
    return this.httpClient.post<boolean>(this.baseUrl + '/api/Suppliers/Import', suppliers)
      .pipe(timeout(10 * 60 * 1000));
  }

  unReceivePurchaseOrders(ordersIds: string[]) {
    return this.httpClient.put(
      `${this.baseUrl}/api/PurchaseOrders/ReceivedStatus`,
      {
        ids: ordersIds,
        receivedStatus: ReceivedStatus.NotReceived
      }
    )
  }

  receivePurchaseOrders(ordersIds: string[], receivedDate: Date) {
    return this.httpClient.put(
      `${this.baseUrl}/api/PurchaseOrders/ReceivedStatus`,
      {
        ids: ordersIds,
        receivedDate,
        receivedStatus: ReceivedStatus.Received
      }
    )
  }

  //#region Purchase Invoice

  getPurchaseInvoiceById(id: string): Promise<PurchaseInvoice | undefined> {
    return firstValueFrom(
      this.httpClient.get<PurchaseInvoice>(`${this.baseUrl}/api/PurchaseInvoices/${id}`)
    ).catch(() => { return undefined; });
  }

  getNextPurchaseInvoiceCodeAsync(): Promise<string | undefined> {
    return firstValueFrom(
      this.httpClient.get<string>(this.baseUrl + '/api/PurchaseInvoices/code')
    ).catch(() => { return undefined; });
  }

  createPurchaseInvoice(purchaseInvoice: PurchaseInvoice): Observable<PurchaseInvoice> {
    return this.httpClient.post<PurchaseInvoice>(this.baseUrl + '/api/PurchaseInvoices', purchaseInvoice);
  }

  updatePurchaseInvoice(purchaseInvoice: PurchaseInvoice): Observable<boolean> {
    return this.httpClient.put<boolean>(this.baseUrl + '/api/PurchaseInvoices/' + purchaseInvoice.id, purchaseInvoice);
  }

  setPurchaseInvoicePaymentStatus(purchaseInvoiceId: string, paymentStatus: PurchaseInvoicePaymentStatus) {
    return this.httpClient.put(
      `${this.baseUrl}/api/PurchaseInvoices/${purchaseInvoiceId}/PaymentStatus`,
      { id: purchaseInvoiceId, paymentStatus }
    );
  }

  cancelPurchaseInvoice(id: string) {
    return this.httpClient.put(`${this.baseUrl}/api/PurchaseInvoices/${id}/cancel`, {});
  }

  //#endregion Purchase Invoice

}
