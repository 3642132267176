import { Injectable, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogModel, ConfirmationDialogComponent, ConfirmDialogType } from '../components/confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {

  dialogRef: any;
  constructor(@Inject(MatDialog) private dialog: MatDialog,
    private translateService: TranslateService) { }

  public confirm(
    title: string,
    message: string,
    btnOkText: string = this.translateService.instant('buttons.ok'),
    btnCancelText: string = this.translateService.instant('buttons.cancel'),
    disableClose: boolean = false,
    showCloseIconOnTop: boolean = true,
    confirmDialogType: ConfirmDialogType = ConfirmDialogType.basic) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '80vw',
      maxWidth: '600px',
      autoFocus: false,
      restoreFocus: false,
      closeOnNavigation: true,
      disableClose: disableClose,
      direction: this.translateService.instant('uiDirection'),
      data: new ConfirmDialogModel(title, message, btnOkText, btnCancelText, showCloseIconOnTop, confirmDialogType)
    });
    this.dialogRef.afterClosed().subscribe(() => this.dialogRef = null);

    return this.dialogRef;
  }
}
