import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appPositiveNumber]'
})
export class PositiveNumberDirective {

  @Input() allowDecimals = true;

  @HostListener('keydown', ['$event']) onKeydown(event: KeyboardEvent) {
    const disallowedKeys = ['-', '+', 'e'];

    if (disallowedKeys.includes(event.key.toLowerCase())) {
      event.preventDefault();
    }

    if (!this.allowDecimals && event.key === '.') {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    const value = event.clipboardData?.getData('text/plain');
    const disallowedValues = ['-', '+', 'e', 'E'];

    if (disallowedValues.some(val => value?.includes(val))) {
      event.preventDefault();
    }

    if (!this.allowDecimals && value?.includes('.')) {
      event.preventDefault();
    }
  }
}
