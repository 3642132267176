import { Router } from '@angular/router';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { AuthService } from '@auth';
import { AppSettingsService } from 'src/app/core/services/app-settings.service';
import { AccountSettingsService } from './account-settings.service';

@Injectable({
  providedIn: 'root'
})
export class LockService {
  passCode: string = '';
  exceptionRoutes: string[] = ['/pos/customer-display'];
  passcodeLoaded: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private router: Router,
    private accountSettingsService: AccountSettingsService,
    private userIdle: UserIdleService,
    private authService: AuthService) {
    if (authService.isAuthenticated()) {
      this.accountSettingsService.loadAccountSettings()
        .then(res => {
          if (res?.passCode) {
            this.passCode = res.passCode;
            this.passcodeLoaded.emit(true);

            // Set Timeout from app settings
            this.userIdle.stopWatching();
            const userIdleConfig = this.userIdle.getConfigValue();
            userIdleConfig.idle = AppSettingsService.appSettings.lockScreen.idleTimeInSec;
            userIdleConfig.timeout = AppSettingsService.appSettings.lockScreen.timeoutInSec;
            userIdleConfig.ping = AppSettingsService.appSettings.lockScreen.pingInSec;
            this.userIdle.setConfigValues(userIdleConfig);
            this.userIdle.startWatching();

            // Start watching when user idle is starting.
            this.userIdle.onTimerStart().subscribe({
              next: () => this.show()
            });

            // Start watch when time is up.
            this.userIdle.onTimeout().subscribe({
              next: () => this.stopWatching()
            });
          }
        });
    }
  }

  private isOpen = false;
  @Output() change: EventEmitter<boolean> = new EventEmitter();

  show() {
    if (this.passCode && this.checkForExceptionRouts()) {
      this.isOpen = true;
      this.stopWatching();
      this.change.emit(this.isOpen);
    }
  }

  checkForExceptionRouts(): boolean {
    return !this.exceptionRoutes.includes(this.router.url)
  }

  hide() {
    this.isOpen = false;
    this.change.emit(this.isOpen);
  }

  correct() {
    this.resetTimer();
    this.startWatching();
  }

  wrong() {
    this.stopWatching();
    this.authService.logout();
  }

  private resetTimer() {
    const idle = this.userIdle.getConfigValue().idle || 0;
    const pinExpiresAt = (idle * 1000) + new Date().getTime();
    localStorage.setItem('pinExpiresAt', JSON.stringify(pinExpiresAt));
    this.userIdle.resetTimer();
  }

  public startWatching() {
    if (this.passCode) {
      const idle = this.userIdle.getConfigValue().idle || 0;
      const pinExpiresAt = (idle * 1000) + new Date().getTime();
      localStorage.setItem('pinExpiresAt', JSON.stringify(pinExpiresAt));
      this.userIdle.startWatching();
    }
  }

  private stopWatching() {
    localStorage.removeItem('pinExpiresAt');
    this.userIdle.stopWatching();
  }

  public isExpired(): boolean {
    const expiresAt = JSON.parse(localStorage.getItem('pinExpiresAt') ?? 'null');
    if (!expiresAt) {
      return true;
    } else {
      return new Date().getTime() >= expiresAt;
    }
  }

  updateTimer() {
    const expiresAt = JSON.parse(localStorage.getItem('pinExpiresAt') ?? 'null');
    if (expiresAt) {
      const idle = this.userIdle.getConfigValue().idle || 0;
      const pinExpiresAt = (idle * 1000) + new Date().getTime();
      localStorage.setItem('pinExpiresAt', JSON.stringify(pinExpiresAt));
      this.userIdle.resetTimer();
    }
  }

  initTimer() {
    const expiresAt = JSON.parse(localStorage.getItem('pinExpiresAt') ?? 'null');
    if (!expiresAt) {
      const idle = this.userIdle.getConfigValue().idle || 0;
      const pinExpiresAt = (idle * 1000) + new Date().getTime();
      localStorage.setItem('pinExpiresAt', JSON.stringify(pinExpiresAt));
      this.userIdle.resetTimer();
    }
  }
}
