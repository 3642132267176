import { Injectable } from '@angular/core';
import { AppSettingsService } from './app-settings.service';
import { map, shareReplay, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable, of, from, firstValueFrom, Subject } from 'rxjs';
import { PrioritizesScanningBarcodeEnum, SystemSetting, SystemSettingsEnum, UiControlToFocusOnAfterAddingItemEnum } from 'src/app/shared/models/system-settings';
import { DexieDbProvider } from '@shared';
import { AuthService } from '@auth';
import moment from 'moment';
import { QueryStringHelper } from 'src/app/shared/helpers/query-string.helper';
import { PagedList } from 'src/app/shared/models/paged-list';
import { InventoryManagementMethod } from 'src/app/shared/models/Inventory-management-method';
import { OrganizationProfile, OrdersSetting, PurchaseInvoicesSetting, SalesInvoicesSetting, ProductsSetting, GlobalSettings, ModulesActivation, EInvoiceKsaConfiguration } from 'src/app/system/models/system-settings';


@Injectable({
    providedIn: 'root'
})
export class SystemSettingsService {

    baseUrl = '';
    modulesActivationSettingsChanged$: Subject<ModulesActivation> = new Subject();

    private offlineModeEnabled$?: Observable<boolean>;
    private offlineModeExcludedUsers$?: Observable<string>;
    private hideSubTotal$?: Observable<boolean>;
    private systemStartDate$?: Observable<string>;
    private financialYearEndDate$?: Observable<string>;
    private allowSellingBySerialNumber$?: Observable<boolean>;
    private checkSerialOwner$?: Observable<boolean>;
    private checkSerialAvailability$?: Observable<boolean>;
    private checkSerialFulfillment$?: Observable<boolean>;
    private inventoryManagementMethod$?: Observable<InventoryManagementMethod>;
    private hideBalanceAndBatchDetails$?: Observable<boolean>;
    private uiControlToFocusOnAfterAddingItem$?: Observable<string>;
    private prioritizesScanningBarcode$?: Observable<PrioritizesScanningBarcodeEnum>;
    private allowRepeatingProductInPO$?: Observable<boolean>;
    private allowLineItemWithZeroPriceInPO$?: Observable<boolean>;
    private preventSellingZeroBalanceStockItems$?: Observable<boolean>;
    private hideCostOnProductionOrderPrint$?: Observable<boolean>;
    private orderTakerUsers$?: Observable<string>;
    private order3OfflineModeEnabled$?: Observable<boolean>;
    private useCashOnDelivery$?: Observable<boolean>;
    private defaultCurrency$?: Observable<string>;

    private globalSettings$?: Observable<GlobalSettings>;
    private organizationProfile$?: Observable<OrganizationProfile>;
    private ordersSettings$?: Observable<OrdersSetting>;
    private purchaseInvoicesSettings$?: Observable<PurchaseInvoicesSetting>;
    private salesInvoicesSettings$?: Observable<SalesInvoicesSetting>;
    private productsSettings$?: Observable<ProductsSetting>;

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService,
        private localDbProvider: DexieDbProvider) {
        this.baseUrl = AppSettingsService.appSettings.edaraCoreApi.baseUrl;
        this.authService.tenantSwitched$.subscribe({
            next: () => this.clearCache()
        });
    }

    public async offlineModeEnabled(): Promise<boolean> {
        if (!this.offlineModeEnabled$) {
            this.offlineModeEnabled$ = from(this.getSettingValue(SystemSettingsEnum.OfflineModeEnabled)).pipe(
                map(res => res ? (res.value === '1' || res.value === 'true') : false),
                shareReplay(1)
            );
        }
        return firstValueFrom(this.offlineModeEnabled$);
    }

    public async offlineModeExcludedUsers(): Promise<string> {
        if (!this.offlineModeExcludedUsers$) {
            this.offlineModeExcludedUsers$ = from(this.getSettingValue(SystemSettingsEnum.OfflineModeExcludedUsers)).pipe(
                map(res => res ? res.value : ''),
                shareReplay(1)
            );
        }
        return firstValueFrom(this.offlineModeExcludedUsers$);
    }

    public async hideSubTotal(): Promise<boolean> {
        if (!this.hideSubTotal$) {
            this.hideSubTotal$ = from(this.getSettingValue(SystemSettingsEnum.HideSubTotal)).pipe(
                map(res => res ? (res.value === '1' || res.value === 'true') : false),
                shareReplay(1)
            );
        }
        return firstValueFrom(this.hideSubTotal$);
    }

    public async systemStartDate(): Promise<string> {
        if (!this.systemStartDate$) {
            this.systemStartDate$ = from(this.getSettingValue(SystemSettingsEnum.SystemStartDate)).pipe(
                map(res => res ? moment(res.value, 'DD/MM/YYYY').toISOString() : ''),
                shareReplay(1)
            );
        }
        return firstValueFrom(this.systemStartDate$);
    }

    public async financialYearEndDate(): Promise<string> {
        if (!this.financialYearEndDate$) {
            this.financialYearEndDate$ = from(this.getSettingValue(SystemSettingsEnum.FinancialYearEndDate)).pipe(
                map(res => res ? res.value : ''),
                shareReplay(1)
            );
        }
        return firstValueFrom(this.financialYearEndDate$);
    }

    public async allowSellingBySerialNumber(): Promise<boolean> {
        if (!this.allowSellingBySerialNumber$) {
            this.allowSellingBySerialNumber$ = from(this.getSettingValue(SystemSettingsEnum.AllowSellingBySerialNumber)).pipe(
                map(res => res ? (res.value === '1' || res.value === 'true') : false),
                shareReplay(1)
            );
        }
        return firstValueFrom(this.allowSellingBySerialNumber$);
    }

    public async checkSerialOwner(): Promise<boolean> {
        if (!this.checkSerialOwner$) {
            this.checkSerialOwner$ = from(this.getSettingValue(SystemSettingsEnum.CheckSerialOwner)).pipe(
                map(res => res ? (res.value === '1' || res.value === 'true') : false),
                shareReplay(1)
            );
        }
        return firstValueFrom(this.checkSerialOwner$);
    }

    public async checkSerialAvailability(): Promise<boolean> {
        if (!this.checkSerialAvailability$) {
            this.checkSerialAvailability$ = from(this.getSettingValue(SystemSettingsEnum.CheckSerialAvailability)).pipe(
                map(res => res ? (res.value === '1' || res.value === 'true') : false),
                shareReplay(1)
            );
        }
        return firstValueFrom(this.checkSerialAvailability$);
    }

    public async checkSerialFulfillment(): Promise<boolean> {
        if (!this.checkSerialFulfillment$) {
            this.checkSerialFulfillment$ = from(this.getSettingValue(SystemSettingsEnum.CheckSerialFulfillment)).pipe(
                map(res => res ? (res.value === '1' || res.value === 'true') : false),
                shareReplay(1)
            );
        }
        return firstValueFrom(this.checkSerialFulfillment$);
    }

    public async inventoryManagementMethod(): Promise<InventoryManagementMethod> {
        if (!this.inventoryManagementMethod$) {
            this.inventoryManagementMethod$ = from(this.getSettingValue(SystemSettingsEnum.InventoryManagementMethod)).pipe(
                map(res => res ? res.value as InventoryManagementMethod : InventoryManagementMethod.None),
                shareReplay(1)
            );
        }
        return firstValueFrom(this.inventoryManagementMethod$);
    }

    public async hideBalanceAndBatchDetails(): Promise<boolean> {
        if (!this.hideBalanceAndBatchDetails$) {
            this.hideBalanceAndBatchDetails$ = from(this.getSettingValue(SystemSettingsEnum.HideBalanceAndBatchDetails)).pipe(
                map(res => res ? (res.value === '1' || res.value === 'true') : false),
                shareReplay(1)
            );
        }
        return firstValueFrom(this.hideBalanceAndBatchDetails$);
    }

    public async uiControlToFocusOnAfterAddingItem(): Promise<string> {
        if (!this.uiControlToFocusOnAfterAddingItem$) {
            this.uiControlToFocusOnAfterAddingItem$ = from(this.getSettingValue(SystemSettingsEnum.UiControlToFocusOnAfterAddingItem)).pipe(
                map(res => res ? res.value : UiControlToFocusOnAfterAddingItemEnum.SearchInput),
                shareReplay(1)
            );
        }
        return firstValueFrom(this.uiControlToFocusOnAfterAddingItem$);
    }

    public async prioritizesScanningBarcode(): Promise<PrioritizesScanningBarcodeEnum> {
        if (!this.prioritizesScanningBarcode$) {
            this.prioritizesScanningBarcode$ = from(this.getSettingValue(SystemSettingsEnum.PrioritizesScanningBarcode)).pipe(
                map(res => res ? +res.value : PrioritizesScanningBarcodeEnum.normalOverWeighted),
                shareReplay(1)
            );
        }
        return firstValueFrom(this.prioritizesScanningBarcode$);
    }

    public async allowRepeatingProductInPO(): Promise<boolean> {
        if (!this.allowRepeatingProductInPO$) {
            this.allowRepeatingProductInPO$ = from(this.getSettingValue(SystemSettingsEnum.AllowRepeatingProductInPO)).pipe(
                map(res => res ? (res.value === '1' || res.value === 'true') : false),
                shareReplay(1)
            );
        }
        return firstValueFrom(this.allowRepeatingProductInPO$);
    }

    public async allowLineItemWithZeroPriceInPO(): Promise<boolean> {
        if (!this.allowLineItemWithZeroPriceInPO$) {
            this.allowLineItemWithZeroPriceInPO$ = from(this.getSettingValue(SystemSettingsEnum.AllowLineItemWithZeroPriceInPO)).pipe(
                map(res => res ? (res.value === '1' || res.value === 'true') : false),
                shareReplay(1)
            );
        }
        return firstValueFrom(this.allowLineItemWithZeroPriceInPO$);
    }

    public async preventSellingZeroBalanceStockItems(): Promise<boolean> {
        if (!this.preventSellingZeroBalanceStockItems$) {
            this.preventSellingZeroBalanceStockItems$ = from(this.getSettingValue(SystemSettingsEnum.PreventSellingZeroBalanceStockItems))
                .pipe(
                    map(res => res ? (res.value === '1' || res.value === 'true') : false),
                    shareReplay(1)
                );
        }
        return firstValueFrom(this.preventSellingZeroBalanceStockItems$);
    }

    public async hideCostOnProductionOrderPrint(): Promise<boolean> {
        if (!this.hideCostOnProductionOrderPrint$) {
            this.hideCostOnProductionOrderPrint$ = from(this.getSettingValue(SystemSettingsEnum.HideCostOnProductionOrderPrint))
                .pipe(
                    map(res => res ? (res.value === '1' || res.value === 'true') : false),
                    shareReplay(1)
                );
        }
        return firstValueFrom(this.hideCostOnProductionOrderPrint$);
    }

    public async orderTakerUsers(): Promise<string> {
        if (!this.orderTakerUsers$) {
            this.orderTakerUsers$ = from(this.getSettingValue(SystemSettingsEnum.OrderTakerUsers)).pipe(
                map(res => res ? res.value : ''),
                shareReplay(1)
            );
        }
        return firstValueFrom(this.orderTakerUsers$);
    }

    public async order3OfflineModeEnabled(): Promise<boolean> {
        if (!this.order3OfflineModeEnabled$) {
            this.order3OfflineModeEnabled$ = from(new Promise<boolean>((resolve) => resolve(true)));
        }
        return firstValueFrom(this.order3OfflineModeEnabled$);
    }

    public async useCashOnDelivery(): Promise<boolean> {
        if (!this.useCashOnDelivery$) {
            this.useCashOnDelivery$ = from(this.getSettingValue(SystemSettingsEnum.UseCashOnDelivery)).pipe(
                map(res => res ? (res.value === '1' || res.value === 'true') : false),
                shareReplay(1)
            );
        }
        return firstValueFrom(this.useCashOnDelivery$);
    }

    public async defaultCurrency(): Promise<string> {
        let defualtValue = '1';
        if (!this.defaultCurrency$) {
            this.defaultCurrency$ = from(this.getSettingValue(SystemSettingsEnum.DefaultCurrency)).pipe(
                map(res => res ? res.value : defualtValue),
                shareReplay(1),
                catchError(err => of(defualtValue))
            );
        }
        return firstValueFrom(this.defaultCurrency$);
    }

    public async GlobalSettings(): Promise<GlobalSettings> {
        if (!this.globalSettings$) {
            this.globalSettings$ = from(this.getSettingValue(SystemSettingsEnum.Global)).pipe(
                map(res => res ? GlobalSettings.clone(JSON.parse(res.value)) : new GlobalSettings()),
                shareReplay(1)
            );
        }
        return firstValueFrom(this.globalSettings$);
    }

    public async OrganizationProfile(): Promise<OrganizationProfile> {
        if (!this.organizationProfile$) {
            this.organizationProfile$ = from(this.getSettingValue(SystemSettingsEnum.OrganizationProfile)).pipe(
                map(res => res ? OrganizationProfile.clone(JSON.parse(res.value)) : new OrganizationProfile()),
                shareReplay(1)
            );
        }
        return firstValueFrom(this.organizationProfile$);
    }

    public async OrdersSettings(): Promise<OrdersSetting> {
        if (!this.ordersSettings$) {
            this.ordersSettings$ = from(this.getSettingValue(SystemSettingsEnum.Order)).pipe(
                map(res => res ? OrdersSetting.clone(JSON.parse(res.value)) : new OrdersSetting()),
                shareReplay(1)
            );
        }
        return firstValueFrom(this.ordersSettings$);
    }

    public async PurchaseInvoicesSettings(): Promise<PurchaseInvoicesSetting> {
        if (!this.purchaseInvoicesSettings$) {
            this.purchaseInvoicesSettings$ = from(this.getSettingValue(SystemSettingsEnum.PurchaseInvoice)).pipe(
                map(res => res ? PurchaseInvoicesSetting.clone(JSON.parse(res.value)) : new PurchaseInvoicesSetting()),
                shareReplay(1)
            );
        }
        return firstValueFrom(this.purchaseInvoicesSettings$);
    }

    public async SalesInvoicesSettings(): Promise<SalesInvoicesSetting> {
        if (!this.salesInvoicesSettings$) {
            this.salesInvoicesSettings$ = from(this.getSettingValue(SystemSettingsEnum.SalesInvoice)).pipe(
                map(res => res ? SalesInvoicesSetting.clone(JSON.parse(res.value)) : new SalesInvoicesSetting()),
                shareReplay(1)
            );
        }
        return firstValueFrom(this.salesInvoicesSettings$);
    }

    public async ProductsSettings(): Promise<ProductsSetting> {
        if (!this.productsSettings$) {
            this.productsSettings$ = from(this.getSettingValue(SystemSettingsEnum.Product)).pipe(
                map(res => res ? ProductsSetting.clone(JSON.parse(res.value)) : new ProductsSetting()),
                shareReplay(1)
            );
        }
        return firstValueFrom(this.productsSettings$);
    }

    private async getSettingValue(name: string): Promise<SystemSetting | undefined> {
        const setting = await this.getSystemSettingByNameLocalAsync(name);
        if (setting) {
            return setting;
        } else {
            const response = await this.getSystemSettingByNameAync(name);
            this.saveSystemSettingLocal(response);
            return response;
        }
    }

    clearCache() {
        this.offlineModeEnabled$ = undefined;
        this.offlineModeExcludedUsers$ = undefined;
        this.hideSubTotal$ = undefined;
        this.systemStartDate$ = undefined;
        this.financialYearEndDate$ = undefined;
        this.allowSellingBySerialNumber$ = undefined;
        this.checkSerialOwner$ = undefined;
        this.checkSerialAvailability$ = undefined;
        this.checkSerialFulfillment$ = undefined;
        this.inventoryManagementMethod$ = undefined;
        this.hideBalanceAndBatchDetails$ = undefined;
        this.uiControlToFocusOnAfterAddingItem$ = undefined;
        this.prioritizesScanningBarcode$ = undefined;
        this.allowRepeatingProductInPO$ = undefined;
        this.allowLineItemWithZeroPriceInPO$ = undefined;
        this.preventSellingZeroBalanceStockItems$ = undefined;
        this.hideCostOnProductionOrderPrint$ = undefined;
        this.orderTakerUsers$ = undefined;
        this.order3OfflineModeEnabled$ = undefined;
        this.useCashOnDelivery$ = undefined;
        this.defaultCurrency$ = undefined;

        this.globalSettings$ = undefined;
        this.organizationProfile$ = undefined;
        this.ordersSettings$ = undefined;
        this.purchaseInvoicesSettings$ = undefined;
        this.salesInvoicesSettings$ = undefined;
        this.productsSettings$ = undefined;
    }

    //#region System Settings

    getAllSystemSettings(): Observable<SystemSetting[]> {
        return this.httpClient.get<SystemSetting[]>(this.baseUrl + 'api/systemsettings');
    }

    getAllSystemSettingsAsync(): Promise<SystemSetting[] | undefined> {
        return firstValueFrom(
            this.httpClient.get<SystemSetting[]>(this.baseUrl + 'api/systemsettings')
        ).catch(() => { return undefined; });
    }

    findSystemSettingsAsync(query: any): Promise<PagedList<SystemSetting[]> | undefined> {
        const queryString = new QueryStringHelper().toQueryString(query);
        return firstValueFrom(
            this.httpClient.get<PagedList<SystemSetting[]>>(this.baseUrl + 'api/systemsettings/find?' + queryString)
        ).catch(() => { return undefined; });
    }

    getSystemSettingByName(name: string): Observable<SystemSetting> {
        return this.httpClient.get<SystemSetting>(this.baseUrl + 'api/systemsettings/FindByName/' + name);
    }

    getSystemSettingByNameAync(name: string): Promise<SystemSetting | undefined> {
        return firstValueFrom(
            this.httpClient.get<SystemSetting>(this.baseUrl + 'api/systemsettings/FindByName/' + name)
        ).catch(() => { return undefined; });
    }

    saveSystemSetting(systemSetting: SystemSetting): Observable<SystemSetting> {
        return this.httpClient.put<SystemSetting>(this.baseUrl + 'api/systemsettings/SaveOrUpdate', systemSetting);
    }

    countSystemSettingsLocalAsync(): Promise<number> {
        return this.localDbProvider.db.systemSettings.count()
            .catch((err: any) => this.localDbProvider.handleErrors(err));
    }

    getSystemSettingByNameLocalAsync(name: string): Promise<SystemSetting> {
        return this.localDbProvider.db.systemSettings
            .where({ name: name })
            .first((element: SystemSetting) => {
                return element;
            });
    }

    addSystemSettingsToLocalDb(settings: SystemSetting[]) {
        settings.forEach(element => {
            this.saveSystemSettingLocal(element);
        });
    }

    saveSystemSettingLocal(systemSetting?: SystemSetting) {
        if (systemSetting) {
            this.localDbProvider.db.systemSettings.put(systemSetting)
                .catch((err: any) => this.localDbProvider.handleErrors(err));
        }
    }

    deleteSystemSetting(id: string): Observable<boolean> {
        return this.httpClient.delete<boolean>(this.baseUrl + 'api/systemsettings/' + id);
    }

    deleteSystemSettingLocal(id: string) {
        if (id) {
            this.localDbProvider.db.systemSettings.where({ id: id }).delete()
                .catch((err: any) => this.localDbProvider.handleErrors(err));
        }
    }

    //#endregion

    connectZatca(otp: string): Observable<EInvoiceKsaConfiguration> {
        return this.httpClient.get<EInvoiceKsaConfiguration>(this.baseUrl + 'api/SystemSettings/GetKSAEInvoiceConfiguration', {
            params: { OTP: otp }
        });
    }
}
