export function getColorFromEmail(email: string): string {
    const hash = hashString(email);
    const hue = hash % 360; // Ensure hue is within 0-360
    return `hsl(${hue}, 70%, 50%)`; // HSL with fixed saturation and lightness for vibrant colors
}

function hashString(str: string): number {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash);
}