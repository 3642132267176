import { Injectable } from '@angular/core';
import * as Sentry from "@sentry/angular";
import { FirestoreService } from './firestore.service';
import { OnlineOfflineService } from './online-offine.service';
import { EdaraNativeService } from './edara-native.service';
import { AuthService } from '@auth';
import { CorePosService } from './core-pos.service';
import { AccountSettings } from '@shared';

@Injectable({
    providedIn: 'root'
})
export class PushDataService {

    private isSyncing = false; // Flag to indicate if sync is in progress

    constructor(
        private posService: CorePosService,
        private authService: AuthService,
        private edaraNativeService: EdaraNativeService,
        private firestoreService: FirestoreService,
        private onlineOfflineService: OnlineOfflineService) {

        if (`serviceWorker` in navigator) {
            navigator.serviceWorker.addEventListener('message', event => {
                if (event.data && event.data.command === 'syncSalesOrdersFinished') {
                    console.log('Sync Sales Orders finished.');
                }
            });
        }
    }

    pushLocalUpdatesToServer() {
        if (this.isSyncing) {
            console.log('Sync already in progress, skipping this request.');
            return;
        }

        this.isSyncing = true; // Set flag to indicate sync is in progress

        this.firestoreService.waitForPendingWrites().then(async () => {
            if ('serviceWorker' in navigator && 'SyncManager' in window) {
                navigator.serviceWorker.ready.then(swRegistration => swRegistration.sync.register('pushLocalUpdatesToServer'))
                    .catch(async () => {
                        // System was unable to register for a sync, this could be an OS-level restriction
                        await this.pushSalesOrdersToServer();
                        await this.pushCashRegisterUpdatesToServer();
                    });
            } else {
                // Serviceworker/sync not supported
                await this.pushSalesOrdersToServer();
                await this.pushCashRegisterUpdatesToServer();
            }
        }).catch((error) => {
            console.error('Error waiting for pending writes.');
            Sentry.captureException(error);
        }).finally(() => {
            this.isSyncing = false; // Reset flag after sync completes
        });
    }

    private async pushSalesOrdersToServer() {
        const accountSettings = await this.firestoreService.getDocValueAsync<AccountSettings>(`accountSettings/${this.authService.currentUserId}`);
        if (accountSettings?.newSalesOrderLayout) {
            await this.pushLocalCustomersToEdara3();
            this.pushSalesOrdersToEdara3();
        }
        else {
            this.pushSalesOrdersToEdara2();
        }
    }

    private async pushSalesOrdersToEdara2() {
        try {
            if (this.onlineOfflineService.isOnline) {
                console.log('Pushing Pending Sales Orders to the server ...\n');

                // Fetch pending Sales Orders from Firestore
                console.log(`Fetching pending Sales Orders from Firestore ....\n`);
                let startDate = new Date();
                const pendingSalesOrders = await this.posService.getPendingOrProcessingSalesOrders(1000);
                let endDate = new Date();

                if (pendingSalesOrders.length > 0) {
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);

                    console.log(pendingSalesOrders);

                    await this.posService.markSalesOrdersAsProcessingAsync(pendingSalesOrders);

                    const chunkSize = 10;
                    for (let i = 0; i < pendingSalesOrders.length; i += chunkSize) {
                        const chunkOfSalesOrders = pendingSalesOrders.slice(i, i + chunkSize);

                        console.log('Syncing pending Sales Orders to the server ...\n');
                        startDate = new Date();
                        let syncResult = await this.posService.syncSalesOrdersToServerAsync(chunkOfSalesOrders);

                        endDate = new Date();

                        if (syncResult && syncResult.status_code === 200) {
                            console.log(`Synced ${chunkOfSalesOrders.length} records to the server.\n`);
                            console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);

                            console.log(`Updating sync status of ${syncResult.result.length} Sales Order(s) to IndexedDb ...\n`);
                            startDate = new Date();
                            await this.posService.updateSalesOrdersSyncStatusOnFirebase(syncResult.result);
                            // Update customers sync status
                            this.posService.updateCustomersSyncStatus(chunkOfSalesOrders, syncResult.result);
                            endDate = new Date();
                            console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                        }
                    }
                }
            }
        } catch (err) {
            Sentry.captureException(err);
            return Promise.reject('Sync pending Sales Orders failed.');
        }
    }

    private async pushSalesOrdersToEdara3() {
        try {
            if (this.onlineOfflineService.isOnline) {
                console.log('Pushing Pending Sales Orders V3 to the server ...\n');

                // Fetch pending Sales Orders V3 from Firestore
                console.log(`Fetching pending Sales Orders V3 from Firestore ....\n`);
                let startDate = new Date();
                const pendingSalesOrders = await this.posService.getPendingOrProcessingSalesOrdersV3(1000);
                let endDate = new Date();

                if (pendingSalesOrders.length > 0) {
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);

                    console.log(pendingSalesOrders);

                    await this.posService.markSalesOrdersV3AsProcessingAsync(pendingSalesOrders);

                    const chunkSize = 10;
                    for (let i = 0; i < pendingSalesOrders.length; i += chunkSize) {
                        const chunkOfSalesOrders = pendingSalesOrders.slice(i, i + chunkSize);

                        console.log('Syncing pending Sales Orders V3 to the server ...\n');
                        startDate = new Date();
                        let syncResults = await this.posService.syncSalesOrdersV3ToServerAsync(chunkOfSalesOrders);

                        endDate = new Date();

                        if (syncResults) {
                            console.log(`Synced ${chunkOfSalesOrders.length} records to the server.\n`);
                            console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);

                            console.log(`Updating sync status of ${syncResults.length} Sales Order(s) V3 to IndexedDb ...\n`);
                            startDate = new Date();
                            await this.posService.updateSalesOrdersV3SyncStatusOnFirebase(syncResults);
                            await this.edaraNativeService.updateSalesOrdersV3SyncStatus(syncResults);
                            endDate = new Date();
                            console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);
                        }
                    }
                }
            }
        } catch (err) {
            Sentry.captureException(err);
            return Promise.reject('Sync pending Sales Orders V3 failed.');
        }
    }

    private async pushCashRegisterUpdatesToServer() {
        const edaraNativeServiceHealthy = await this.posService.checkEdaraNativeServiceHealthAsync();
        if (!edaraNativeServiceHealthy) return;

        const cashRegister = await this.posService.findCashRegisterByUserEmail(this.authService.currentUserEmail);
        if (cashRegister) {
            await this.edaraNativeService.syncCashRegisterUpdates(cashRegister);
        }
    }

    private async pushLocalCustomersToEdara3() {
        try {
            if (this.onlineOfflineService.isOnline) {
                console.log('Pushing Local Customers V3 to the server ...\n');

                // Fetch pending Customers V3 from indexedDb
                console.log(`Fetching unsynced Customers V3 from IndexedDb ....\n`);
                let startDate = new Date();
                const pendingCustomers = await this.posService.getUnsyncedCustomersV3Async();
                let endDate = new Date();

                if (pendingCustomers.length > 0) {
                    console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);

                    console.log(pendingCustomers);

                    const chunkSize = 10;

                    for (let i = 0; i < pendingCustomers.length; i += chunkSize) {
                        const chunkOfCustomers = pendingCustomers.slice(i, i + chunkSize);

                        console.log('Syncing local Customers V3 to the server ...\n');
                        startDate = new Date();
                        let syncResults = await this.posService.syncCustomersV3ToServerAsync(chunkOfCustomers);

                        endDate = new Date();

                        if (syncResults) {
                            console.log(`Synced ${chunkOfCustomers.length} records to the server.\n`);
                            console.log(`It took ${(endDate.getTime() - startDate.getTime())} ms.\n`);

                            console.log(`Updating sync status of ${syncResults.length} Customer(s) V3 to IndexedDb ...\n`);
                            this.posService.saveSyncedCustomersV3ToLocalDb(syncResults);
                        }
                    }
                }
            }
        }
        catch (err) {
            Sentry.captureException(err);
            return Promise.reject('Sync local Customers V3 failed.');
        }
    }

}

