import { AfterViewInit, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessageFormatter } from '@shared';
import { AutoCompleteComponent } from 'src/app/shared/components/auto-complete/auto-complete.component';
import { SystemSettingsService } from 'src/app/core/services/system-settings.service';
import { Currency, ConfirmationService } from '@shared';
import moment from 'moment';
import { AbstractControl, NgModel } from "@angular/forms";
import { CommonService } from '@core/services/common.service';
import { PurchaseInvoiceService } from "../../services/purchase-invoice.service";
import { PurchaseInvoice, PurchaseInvoiceItem, PurchaseInvoicePaymentStatus, Supplier, InvoicingProductUnitOfMeasure, Location, InvoicingSettings } from "../../models";
import { CodeConfigurationComponent } from "@shared/components/code-configuration/code-configuration.component";
import { EntityCode } from "@shared/models/entity-code";
import { PurchaseInvoicePrintComponent } from "../purchase-invoice-print/purchase-invoice-print.component";
import { InvoicingPurchaseInvoiceFormService } from "../../services/invoicing-purchase-invoice-form.service";
import { InvoicingSearchProductResult } from "../invoicing-search-products/invoicing-search-products.component";
import { InvoicingService } from "../../services/invoicing.service";
import { PageEvent } from "@angular/material/paginator";
import { flatMap } from "lodash";
import { CompoundDiscountComponent } from "../compound-discount/compound-discount.component";

@Component({
  selector: 'app-purchase-invoice-form',
  templateUrl: './purchase-invoice-form.component.html',
  styleUrls: ['./purchase-invoice-form.component.scss'],
  providers: [InvoicingPurchaseInvoiceFormService]
})
export class PurchaseInvoiceFormComponent implements OnInit, AfterViewInit {

  displayedLineItemsColumns: string[] = ['select', 'product', 'quantity', 'price', 'lineDiscount', 'tax', 'lineTotal', 'actions'];
  locations: Location[] = [];
  suppliers: Supplier[] = [];
  currencies: Currency[] = [];
  invoiceDate = { startDate: new Date(), endDate: new Date() };
  dueDate = { startDate: new Date(), endDate: new Date() };
  invoicingSettings = new InvoicingSettings();
  lineItemsPage = 1;
  lineItemsPageSize = 10;
  showSidePanel = true;
  submitted = false;
  saveLoading = false;
  locationsLoading = false;
  suppliersLoading = false;
  regex = /^[ ~`!@#$%^&*()_+=[\]{\}|;':",.\/<>?a-zA-Z0-9-]+$/;
  isStatusChanged = false;
  statusCssClassess = {
    Paid: "success",
    Undue: "warning",
    Overdue: "danger",
    DueToday: "primary",
    Cancelled: "secondary",
  };
  statusText = "";
  statusCssClass = "";
  isAllLineItemsSelectedValue: boolean = false;
  selectedLineItemsValue: PurchaseInvoiceItem[] = [];
  focusedLineItemDiscountIndex?: number;
  selectedCompoundDiscountIndex?: number;

  get hasRepeatedProduct(): boolean {
    const productIds = this.purchaseInvoice.lineItems.map(item => item.productId);
    return productIds.some((productId, index) => productIds.indexOf(productId) !== index);
  }

  get hasLineItemWithZeroPrice(): boolean {
    return this.purchaseInvoice.lineItems.findIndex(item => item.price === 0) > -1;
  }

  @ViewChild('locationAutoComplete') locationAutoComplete!: AutoCompleteComponent;
  @ViewChild('supplierAutoComplete') supplierAutoComplete!: AutoCompleteComponent;
  @ViewChild('DueDateModel') DueDateModel!: NgModel;

  constructor(private purchaseInvoiceService: PurchaseInvoiceService,
    private systemSettingsService: SystemSettingsService,
    private commonService: CommonService,
    private confirmationService: ConfirmationService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private errorMessageFormatter: ErrorMessageFormatter,
    private dialogRef: MatDialogRef<PurchaseInvoiceFormComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public purchaseInvoice: PurchaseInvoice,
    private purchaseInvoiceFormService: InvoicingPurchaseInvoiceFormService,
    private invoicingService: InvoicingService
  ) {
    this.purchaseInvoiceFormService.purchaseInvoiceRef.set(this.purchaseInvoice);
  }

  async ngOnInit() {
    if (this.purchaseInvoice) {
      await this.refreshInvoice();
      this.invoiceDate = { startDate: this.purchaseInvoice.invoiceDate, endDate: this.purchaseInvoice.invoiceDate };
      this.dueDate = { startDate: this.purchaseInvoice.dueDate, endDate: this.purchaseInvoice.dueDate };
      if (this.purchaseInvoiceFormService.isProposedFromPurchaseOrders() && (!this.purchaseInvoice.code || !this.purchaseInvoice.code.fullCode)) {
        this.loadPurchaseInvoiceCode();
      }
      this.loadAllCurrencies(false);
    } else {
      this.initPurchaseInvoice();
      this.loadPurchaseInvoiceCode();
      this.loadAllCurrencies(true);
    }
    this.loadAllLocations();
    this.loadAllSuppliers();
    this.invoicingSettings.loadSettings(this.systemSettingsService);

    this.loadStatus();
  }

  async refreshInvoice() {
    if (!!this.purchaseInvoice.id) {
      const invoice = await this.purchaseInvoiceService.getPurchaseInvoiceById(this.purchaseInvoice.id);
      this.purchaseInvoice = PurchaseInvoice.clone(invoice)!;
      this.purchaseInvoiceFormService.purchaseInvoiceRef.set(this.purchaseInvoice);
    }
    const productsIds = this.purchaseInvoice.lineItems.map(x => x.productId);
    const linesProducts = await this.invoicingService.postFindProductsAsync({ ids: productsIds, offset: 0, limit: productsIds.length });
    const invoiceItemsRelatedOrdersIds = this.purchaseInvoice.lineItems
      .filter(x => !!x.purchaseOrderItemId)
      .map(x => x.purchaseOrderItemId!);
    if (invoiceItemsRelatedOrdersIds.length > 0) {
      const purchaseOrders = await this.purchaseInvoiceService.getPurchaseOrdersByDetailsIds(invoiceItemsRelatedOrdersIds);
      const purchaseOrderItems = flatMap(purchaseOrders, order => order.lineItems);
      this.purchaseInvoice.lineItems.forEach(li => {
        const lineProduct = linesProducts?.items.find(x => x.id === li.productId);
        li.purchaseOrderItemAvailableToInvoice = purchaseOrderItems
          .find(x => x.id === li.purchaseOrderItemId)?.availableToInvoice;
        li.baseUom = lineProduct?.unitOfMeasures.find(x => x.baseUnit);
        li.productUnitOfMeasures = lineProduct?.unitOfMeasures ?? [];
      });
    } else {
      this.purchaseInvoice.lineItems.forEach(line => {
        const lineProduct = linesProducts?.items.find(x => x.id === line.productId);
        line.productUnitOfMeasures = lineProduct?.unitOfMeasures ?? [];
        line.baseUom = lineProduct?.unitOfMeasures.find(x => x.baseUnit);
      });
    }
  }

  ngAfterViewInit(): void {
    this.DueDateModel.control.addValidators(((control: AbstractControl) => {
      if (!control.value)
        return null;

      const dueDate = moment(control.value.startDate);
      const invoiceDate = moment(this.invoiceDate.startDate);

      if (dueDate.startOf('day').isBefore(invoiceDate.startOf('day')))
        return { dueDateOlderThanInvoiceDate: true };

      return null;
    }).bind(this));
  }

  invoiceDateChanged(event: { startDate: Date; endDate: Date }) {
    this.purchaseInvoice.invoiceDate = event.startDate;
    this.DueDateModel.control.updateValueAndValidity();
    this.DueDateModel.control.markAsTouched();
  }

  onClose() {
    this.dialogRef.close(this.isStatusChanged);
  }

  onSearchProductsCompleted(result: InvoicingSearchProductResult) {
    const product = result.product;
    const line = new PurchaseInvoiceItem(product.id, product.name, product.description, 1, product.purchasePrice);
    line.baseUom = product.unitOfMeasures.find(x => x.baseUnit);
    line.productUnitOfMeasures = product.unitOfMeasures;
    line.unitOfMeasure = result.uom;
    this.purchaseInvoice.addLineItem(line);
  }

  get countSelectedLineItems() {
    return this.purchaseInvoice.lineItems.filter(itm => itm.isSelected === true).length;
  }

  selectAllLineItems() {
    this.purchaseInvoice.lineItems.forEach(itm => itm.isSelected = true);
    this.lineItemsSelectedChanged();
  }

  unSelectAllLineItems() {
    this.purchaseInvoice.lineItems.forEach(itm => itm.isSelected = false);
    this.lineItemsSelectedChanged();
  }

  isAllLineItemsSelected() {
    return this.purchaseInvoice.lineItems.length === this.purchaseInvoice.lineItems
      .filter(itm => itm.isSelected === true).length;
  }

  loadStatus() {
    const status = PurchaseInvoice.getStatus(this.purchaseInvoice);
    const statusTranslateKey = `invoicing.purchaseInvoice.status.${status.status}Msg`;
    this.statusText = this.translateService.instant(statusTranslateKey, { days: status.daysUntilDueDate });
    this.statusCssClass = this.statusCssClassess[status.status as keyof typeof this.statusCssClassess];
  }

  setPurchaseInvoicePaymentStatus(paymentStatus: PurchaseInvoicePaymentStatus) {
    this.purchaseInvoiceService.setPurchaseInvoicePaymentStatus(this.purchaseInvoice.id, paymentStatus).subscribe({
      next: res => {
        const successMsgKey = paymentStatus === PurchaseInvoicePaymentStatus.Paid ? "PurchaseInvoiceMarkAsPaidSuccess" : "PurchaseInvoiceMarkAsUnpaidSuccess";
        this.snackBar.open(
          this.translateService.instant('alerts.' + successMsgKey, { code: this.purchaseInvoice.code?.fullCode }),
          '✖', { direction: this.translateService.instant('uiDirection') }
        );
        this.purchaseInvoice.paymentStatus = paymentStatus;
        this.isStatusChanged = true;
        this.loadStatus();
      },
      error: err => {
        this.handleError(err.error, "alerts.setPurchaseInvoicePaymentStatusFail");
      }
    })
  }

  markAsPaid() {
    this.setPurchaseInvoicePaymentStatus(PurchaseInvoicePaymentStatus.Paid);
  }

  markAsUnpaid() {
    this.setPurchaseInvoicePaymentStatus(PurchaseInvoicePaymentStatus.Unpaid);
  }

  lineItemsMasterToggle() {
    this.isAllLineItemsSelected() ?
      this.unSelectAllLineItems() :
      this.selectAllLineItems();
  }

  onDeleteSelectedLineItems() {
    const selectedItems = this.purchaseInvoice.lineItems.filter(itm => itm.isSelected === true);
    selectedItems.forEach(element => {
      this.onDeleteLineItem(element.sequence!);
    });
    this.selectedLineItemsValue = this.purchaseInvoice.lineItems.filter(itm => itm.isSelected === true);
  }

  onDeleteLineItem(sequence: number) {
    this.purchaseInvoice.removeLineItem(sequence);
  }

  onLineItemsPageChange(event: PageEvent) {
    if (event) {
      this.lineItemsPageSize = event.pageSize;
      this.lineItemsPage = event.pageIndex + 1;
    }
  }

  addCompoundDiscount(lineItem: PurchaseInvoiceItem, index: number) {
    this.selectedCompoundDiscountIndex = index;
    const compoundDiscountDialogRef = this.dialog.open(CompoundDiscountComponent, {
      width: "80vw",
      maxWidth: "420px",
      autoFocus: false,
      restoreFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      direction: this.translateService.instant("uiDirection"),
      data: lineItem
    });
    compoundDiscountDialogRef.afterClosed().subscribe((result: number) => {
      if (result || result === 0) {
        lineItem.discount = result;
      }
      this.selectedCompoundDiscountIndex = undefined;
    });
  }

  onSubmit() {
    this.submitted = true;

    if(this.saveLoading) return;
    this.saveLoading = true;

    // Stop here if form is invalid
    if (!this.purchaseInvoice.isValid) {
      this.locationAutoComplete.autoCompleteControl.markAsTouched();
      this.supplierAutoComplete.autoCompleteControl.markAsTouched();
      this.saveLoading = false;
      return;
    }

    if (this.purchaseInvoice.id) {
      this.updatePurchaseInvoice();
    } else {
      this.createPurchaseInvoice();
    }
  }

  private initPurchaseInvoice() {
    this.purchaseInvoice = new PurchaseInvoice();
    this.purchaseInvoice.code = undefined;
    this.purchaseInvoice.invoiceDate = this.invoiceDate.startDate;
    this.purchaseInvoice.dueDate = this.dueDate.startDate;
  }

  private async loadPurchaseInvoiceCode() {
    await this.purchaseInvoiceService
      .getNextPurchaseInvoiceCode()
      .subscribe((res) => {
        this.purchaseInvoice.code = Object.assign(new EntityCode(), res);
      });
  }

  private async loadAllLocations() {
    this.locationsLoading = true;
    this.locations = await this.purchaseInvoiceService.getAllLocationsAsync() ?? [];
    this.locationsLoading = false;
  }

  private async loadAllSuppliers() {
    this.suppliersLoading = true;
    this.suppliers = await this.purchaseInvoiceService.getAllSuppliersAsync() ?? [];
    this.suppliersLoading = false;
  }

  private async loadAllCurrencies(isNewMode: boolean) {
    this.currencies = await this.commonService.getAllCurrenciesAsync() ?? [];
    if (isNewMode && this.currencies.length > 0)
      this.purchaseInvoice.currency = this.invoicingSettings.defaultCurrency ? this.currencies.filter(x => x.id === +this.invoicingSettings.defaultCurrency!)[0] : this.currencies[0];
  }

  compareCurrencyObj(option: Currency, selecteValue: Currency) {
    return option.id === selecteValue?.id;
  }

  private updatePurchaseInvoice() {
    this.purchaseInvoiceService.updatePurchaseInvoice(this.purchaseInvoice).subscribe(res => {
      this.snackBar.open(this.translateService.instant('alerts.updatePurchaseInvoiceSuccess'), '✖', { direction: this.translateService.instant('uiDirection') });
      this.dialogRef.close(true);
    }, err => {
      this.saveLoading = false;
      this.handleError(err.error, 'alerts.savePurchaseInvoiceFail');
    });
  }

  private createPurchaseInvoice() {
    this.purchaseInvoiceService.createPurchaseInvoice(this.purchaseInvoice).subscribe(res => {
      this.snackBar.open(
        this.translateService.instant('alerts.createPurchaseInvoiceSuccess'),
        '✖', { direction: this.translateService.instant('uiDirection') }
      );
      if (this.delayClose()) {
        setTimeout(() => { this.dialogRef.close(true); }, this.getCloseDelayInMS())
      }
      else {
        this.dialogRef.close(true);
      }
    }, err => {
      this.saveLoading = false;
      this.handleError(err.error, 'alerts.savePurchaseInvoiceFail');
    });
  }

  private handleError(err: any, generalMassageKey: string) {
    this.snackBar.open(this.errorMessageFormatter.getErrorMessage(err, generalMassageKey), '✖', { direction: this.translateService.instant('uiDirection') });
  }

  cancel() {
    if (this.purchaseInvoice.paymentStatus === PurchaseInvoicePaymentStatus.Paid) {
      this.snackBar.open(
        this.translateService.instant("alerts.cannotCancelPaidPurchaseInvoice"),
        '✖', { direction: this.translateService.instant('uiDirection') }
      );
      return;
    }

    let dialogRef = this.confirmationService.confirm(
      this.translateService.instant("invoicing.purchaseInvoice.form.cancelConfirmation.title", { code: this.purchaseInvoice.code?.fullCode }),
      this.translateService.instant("invoicing.purchaseInvoice.form.cancelConfirmation.message"),
      this.translateService.instant("invoicing.purchaseInvoice.form.cancelConfirmation.btnOkText"),
      this.translateService.instant("invoicing.purchaseInvoice.form.cancelConfirmation.btnCancelText")
    ) as MatDialogRef<any>;

    dialogRef.afterClosed().subscribe((res) => {
      if (!res) return;

      this.purchaseInvoiceService.cancelPurchaseInvoice(this.purchaseInvoice.id)
        .subscribe({
          next: () => {
            this.snackBar.open(
              this.translateService.instant("alerts.cancelPurchaseInvoiceSuccess", { code: this.purchaseInvoice.code?.fullCode }),
              '✖', { direction: this.translateService.instant('uiDirection') }
            );
            this.purchaseInvoice.isCancelled = true;
            this.isStatusChanged = true;
            this.loadStatus();
          },
          error: (err) => {
            this.handleError(err.error, "alerts.cancelPurchaseInvoiceFail");
          },
        });
    });
  }

  openCodeConfigurationDialog() {
    const codeConfigurationDialogRef = this.dialog.open(
      CodeConfigurationComponent,
      {
        width: "80vw",
        maxWidth: "600px",
        autoFocus: false,
        restoreFocus: false,
        closeOnNavigation: true,
        disableClose: true,
        direction: this.translateService.instant("uiDirection"),
        data: Object.assign(new EntityCode(), this.purchaseInvoice.code)
      }
    );
    codeConfigurationDialogRef.afterClosed().subscribe((result: EntityCode) => {
      if (result) {
        this.purchaseInvoice.code = result;
      }
    });
  }

  private lineItemsCountToDelay = 700;
  private baseDelayMS = 2000;
  private maxDelayMS = 5000;
  delayClose() {
    return this.purchaseInvoiceFormService.isProposedFromPurchaseOrders() && this.purchaseInvoice.lineItems.length >= this.lineItemsCountToDelay;
  }

  getCloseDelayInMS(): number {
    const invoiceItemsCount = this.purchaseInvoice.lineItems.length;
    const delayFactor = (invoiceItemsCount % this.lineItemsCountToDelay) / 100;
    const delayMS = (delayFactor * 1000) + this.baseDelayMS;
    return Math.min(delayMS, this.maxDelayMS);
  }

  async openPurchaseInvoicePrintDialog() {
    const invoice = await this.purchaseInvoiceService.getPurchaseInvoiceById(this.purchaseInvoice.id);
    this.dialog.open(PurchaseInvoicePrintComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      autoFocus: false,
      restoreFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      direction: this.translateService.instant('uiDirection'),
      data: PurchaseInvoice.clone(invoice)
    });
  }

  lineItemsSelectedChanged() {
    this.selectedLineItemsValue = this.purchaseInvoice.lineItems.filter(itm => itm.isSelected === true);
    this.isAllLineItemsSelectedValue = this.isAllLineItemsSelected();
  }

  lineUomCompareWith = (opt1: InvoicingProductUnitOfMeasure, opt2: InvoicingProductUnitOfMeasure) => {
    return opt1?.id === opt2?.id;
  }
}
