import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom, iif, of, throwError, timer } from 'rxjs';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { QueryStringHelper } from 'src/app/shared/helpers/query-string.helper';
import { CashRegister, CashRegisterClosing, CashRegisterOpening, CashRegisterPaymentType, CashRegisterStatus, CashRegisterStatusLog, SyncCashRegisterUpdates } from 'src/app/shared/models/cash-register';
import { CashInCashRegister, CashOutCashRegister, CashTransaction, CashTransactionLog } from 'src/app/shared/models/cash-transaction';
import { NativeServiceSyncStatus, SyncResult } from 'src/app/shared/models/native-service-syncable';
import { DeviceInfo, DeviceCounter } from 'src/app/shared/models/device-info';
import { PagedList } from 'src/app/shared/models/paged-list';
import { SalesOrder } from 'src/app/shared/models/sales-order';
import { AppSettingsService } from './app-settings.service';
import { SalesOrderV3 } from '@shared';
import { replicationInfo } from '@shared/models/metadata';
import { AuthService } from '@auth';
import { FirestoreService } from '@core';
import { setDoc } from 'firebase/firestore';
import { SalesOrderV3Payment } from '@shared/models/sales-order-v3-payment';
import { SalesRefund } from 'src/app/point-of-sale/models/sales-refund-v3';
import { CashRegisterV3 } from 'src/app/point-of-sale/models/cash-register-v3';
import { error } from 'console';

export enum EdaraNativeServiceActionType {
  Created,
  Updated
}

@Injectable({
  providedIn: 'root'
})
export class EdaraNativeService {

  edaraNativeServiceBaseUrl = '';
  edaraCoreBaseUrl = '';

  constructor(private httpClient: HttpClient,
    private authService: AuthService,
    private firestoreService: FirestoreService) {
    this.edaraNativeServiceBaseUrl = AppSettingsService.appSettings.edaraNativeService.baseUrl;
    if (this.edaraNativeServiceBaseUrl.endsWith('/')) {
      this.edaraNativeServiceBaseUrl = this.edaraNativeServiceBaseUrl.slice(0, -1);
    }

    this.edaraCoreBaseUrl = AppSettingsService.appSettings.edaraCoreApi.baseUrl;
    if (this.edaraCoreBaseUrl.endsWith('/')) {
      this.edaraCoreBaseUrl = this.edaraCoreBaseUrl.slice(0, -1);
    }
  }

  //#region DeviceInfo

  getDeviceInfoAsync(): Promise<DeviceInfo | undefined> {
    return firstValueFrom(
      this.httpClient.get<DeviceInfo>(this.edaraNativeServiceBaseUrl + '/api/device/info')
    ).catch(() => { return undefined; });
  }

  getCounterAsync(entityType: string): Promise<DeviceCounter | undefined> {
    return firstValueFrom(
      this.httpClient.get<DeviceCounter>(this.edaraNativeServiceBaseUrl + `/api/device/counters/find?entityType=${entityType}`, { observe: 'response' }).pipe(
        catchError((err: HttpErrorResponse) => {
          if(err.status === 404) {
            return of(undefined);
          }
          return throwError(() => err);
        }),
        retry({
          count: 3,
          resetOnSuccess: false,
          delay: (_, retryCount) => timer(retryCount * 2000)
        }),
        map(x => x?.body as DeviceCounter)
      )
    );
  }

  incrementCounterAsync(entityType: string): Promise<DeviceCounter | undefined> {
    return firstValueFrom(
      this.httpClient.put<DeviceCounter>(this.edaraNativeServiceBaseUrl + `/api/device/counters/increment?entityType=${entityType}`, null)
    ).catch(() => { return undefined; });
  }

  resetCounterAsync(entityType: string): Promise<DeviceCounter | undefined> {
    return firstValueFrom(
      this.httpClient.put<DeviceCounter>(this.edaraNativeServiceBaseUrl + `/api/device/counters/reset?entityType=${entityType}`, null)
    ).catch(() => { return undefined; });
  }

  setCounterAsync(counterObj: any): Promise<DeviceCounter | undefined> {
    return firstValueFrom(
      this.httpClient.put<DeviceCounter>(this.edaraNativeServiceBaseUrl + '/api/device/counters/set', counterObj)
    ).catch(() => { return undefined; });
  }

  //#endregion

  //#region SalesOrder

  findSalesOrdersAsync(query: any): Promise<SalesOrder[] | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<SalesOrder[]>(this.edaraNativeServiceBaseUrl + '/SalesOrders/Find?' + queryString)
    ).catch(() => { return undefined; });
  }

  createSalesOrder(salesOrder: SalesOrder): Observable<SalesOrder> {
    return this.httpClient.post<SalesOrder>(this.edaraNativeServiceBaseUrl + '/SalesOrders', salesOrder);
  }

  //#endregion

  //#region SalesOrderV3

  getSalesOrderV3ByLocalId(salesOrderId: string): Promise<SalesOrderV3 | undefined> {
    return firstValueFrom(
      this.httpClient.get<SalesOrderV3>(this.edaraNativeServiceBaseUrl + `/SalesOrdersV3/${salesOrderId}`)
    ).catch(() => { return undefined; });
  }

  findSalesOrdersV3Async(query: any): Promise<SalesOrderV3[] | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<SalesOrderV3[]>(this.edaraNativeServiceBaseUrl + '/SalesOrdersV3/Find?' + queryString)
        .pipe(tap(res => res?.forEach(element => { this.sortSalesOrderPayment(element.payments) })))
    ).catch(() => { return undefined; });
  }


  findSalesOrdersV3WithRetry(query: any): Observable<SalesOrderV3[] | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return  this.httpClient.get<SalesOrderV3[]>(this.edaraNativeServiceBaseUrl + '/SalesOrdersV3/Find?' + queryString)
    .pipe(
      catchError((err: HttpErrorResponse) => iif(
        () => err.status === 404,
        of([]),
        throwError(() => err)
      )),
      retry({
        count: 3,
        resetOnSuccess: false,
        delay: (_, retryCount) => timer(retryCount * 2000)
      }),
      tap(res => res?.forEach(element => { this.sortSalesOrderPayment(element.payments) })),
    );
  }

  private sortSalesOrderPayment(payments: SalesOrderV3Payment[]) {
    payments?.sort((a, b) => {
      if (a.paymentTypeName === 'Cash') return -1;
      if ((a.paymentTypeName === 'Bank' || a.paymentTypeName === 'Card') && b.paymentTypeName !== 'Cash') return -1;
      return 0;
    });
  }

  createSalesOrderV3(salesOrder: SalesOrderV3): Observable<SalesOrderV3> {
    return this.httpClient.post<SalesOrderV3>(this.edaraNativeServiceBaseUrl + '/SalesOrdersV3', salesOrder);
  }

  createSalesOrderV3Async(salesOrder: SalesOrderV3): Promise<SalesOrderV3 | undefined> {
    return firstValueFrom(
      this.httpClient.post<SalesOrderV3>(this.edaraNativeServiceBaseUrl + '/SalesOrdersV3', salesOrder)
    ).catch(() => { return undefined; });
  }

  createSalesRefund(salesRefund: SalesRefund): Observable<SalesRefund> {
    return this.httpClient.post<SalesRefund>(this.edaraNativeServiceBaseUrl + '/SalesOrdersV3', salesRefund);
  }

  updateSalesOrderV3(salesOrder: SalesOrderV3): Observable<boolean> {
    return this.httpClient.put<boolean>(this.edaraNativeServiceBaseUrl + `/SalesOrdersV3/${salesOrder.metadata!.localId}`, salesOrder);
  }

  updateSalesOrderV3Async(salesOrder: SalesOrderV3): Promise<boolean | undefined> {
    return firstValueFrom(
      this.httpClient.put<boolean>(this.edaraNativeServiceBaseUrl + `/SalesOrdersV3/${salesOrder.metadata!.localId}`, salesOrder)
    ).catch(() => { return undefined; });
  }

  getSalesOrderV3ByLocalIdOrNull(salesOrderId: string): Promise<SalesOrderV3 | undefined> {
    return firstValueFrom(
      this.httpClient.get<SalesOrderV3>(this.edaraNativeServiceBaseUrl + `/SalesOrdersV3/${salesOrderId}`)
    ).catch(() => { return undefined; });
  }

  async createOrUpdateSalesOrderV3(salesOrder: SalesOrderV3): Promise<EdaraNativeServiceActionType> {
    const retrivedSalesOrder = await this.getSalesOrderV3ByLocalIdOrNull(salesOrder.metadata!.localId);
    if (retrivedSalesOrder) {
      if (retrivedSalesOrder.metadata) {
        salesOrder.metadata = retrivedSalesOrder.metadata;
      }
      await this.updateSalesOrderV3Async(salesOrder);
      return EdaraNativeServiceActionType.Updated;
    } else {
      this.createSalesOrderV3Async(salesOrder);
      return EdaraNativeServiceActionType.Created;
    }
  }

  async deleteSalesOrderV3Async(salesOrderId: string): Promise<boolean | undefined> {
    return firstValueFrom(
      this.httpClient.delete<boolean>(this.edaraNativeServiceBaseUrl + `/SalesOrdersV3/${salesOrderId}`)
    ).catch(() => { return undefined; });
  }

  async restoreReplicatedSalesOrdersV3ToIndexedDb() {
    const isFireStoreLoggedIn = await this.firestoreService.isLoggedIn();
    if (!isFireStoreLoggedIn) {
      await this.authService.renewFirebaseToken();
    }

    const pendingSalesOrders = await this.findSalesOrdersV3Async({
      TenantId: this.authService.currentTenant,
      UserId: this.authService.userProfile?.id,
      Status: NativeServiceSyncStatus[NativeServiceSyncStatus.Pending]
    });
    if (pendingSalesOrders && pendingSalesOrders.length > 0) {
      console.log('Copying sales orders v3 to indexeddb ...');
      pendingSalesOrders.forEach(async so => {
        const orderExists = await this.offlineSalesOrderV3Exists(so.metadata!.localId);
        if (!orderExists) {
          this.setReplicationInfo(so);
          await this.saveOfflineSalesOrderV3ToFirebase(so);
        }
        // else {
        //   // Check sync status, if synced then delete it from oflline mode service
        //   await this.deleteSalesOrderV3Async(so.metadata.localId);
        // }
      });
    }
  }

  async offlineSalesOrderV3Exists(salesOrderLocalId: string) {
    try {
      const doc = await this.firestoreService.getDocSnapshotAsync('salesOrdersV3/' + salesOrderLocalId);
      return doc && doc.data() && doc.id === salesOrderLocalId && !doc.metadata.fromCache;
    } catch (error) {
      return false;
    }
  }

  private setReplicationInfo(salesOrder: SalesOrderV3) {
    const replicaInfo = new replicationInfo();
    replicaInfo.replicationDate = new Date(salesOrder.orderDate);
    replicaInfo.restoreDate = new Date();
    salesOrder.metadata!.replicationInfo = Object.assign({}, replicaInfo);
  }

  async saveOfflineSalesOrderV3ToFirebase(salesOrder: SalesOrderV3) {
    const documentRef = this.firestoreService.doc('salesOrdersV3/' + salesOrder.metadata!.localId);
    await setDoc(documentRef, salesOrder);
  }

  async updateSalesOrdersV3SyncStatus(syncResults: SyncResult[]) {

    if (!syncResults?.length) return;

    const dateNow = new Date();
    syncResults.forEach(async syncResult => {
      const retreivedSalesOrder = await this.getSalesOrderV3ByLocalId(syncResult.localId);
      if (retreivedSalesOrder) {
        retreivedSalesOrder.metadata!.syncInfo = {
          syncedDate: dateNow,
          syncStatus: NativeServiceSyncStatus[syncResult.syncStatus],
          syncStatusMessage: syncResult.error,
          syncedBy: this.authService.userProfile
        };
        this.updateSalesOrderV3(retreivedSalesOrder).subscribe();
      }
    });
  }

  //#endregion

  //#region CashRegister

  getCashRegistersByUserEmailAsync(email: string): Promise<CashRegister | undefined> {
    return firstValueFrom(
      this.httpClient.get<CashRegister>(this.edaraNativeServiceBaseUrl + `/cashregisters/ByEmail?email=${email}`)
        .pipe(tap(res => this.sortCashRegisterPaymentTypes(res.paymentTypes)))
    ).catch(() => { return undefined; });
  }

  getCashRegisterByIdAsync(id: string): Promise<CashRegister | undefined> {
    return firstValueFrom(
      this.httpClient.get<CashRegister>(this.edaraNativeServiceBaseUrl + '/cashregisters/' + id)
        .pipe(tap(res => this.sortCashRegisterPaymentTypes(res.paymentTypes)))
    ).catch(() => { return undefined; });
  }

  createCashRegister(cashRegister: CashRegister | CashRegisterV3): Observable<CashRegister> {
    return this.httpClient.post<CashRegister>(this.edaraNativeServiceBaseUrl + '/cashRegisters', cashRegister);
  }

  updateCashRegister(cashRegister: CashRegister | CashRegisterV3): Observable<boolean> {
    return this.httpClient.put<boolean>(this.edaraNativeServiceBaseUrl + '/cashRegisters/' + cashRegister.id, cashRegister);
  }

  openCashRegister(cashRegisterId: string, cashRegisterOpening: CashRegisterOpening): Observable<CashRegister> {
    return this.httpClient.put<CashRegister>(this.edaraNativeServiceBaseUrl + '/cashRegisters/open/' + cashRegisterId, cashRegisterOpening);
  }

  closeCashRegister(cashRegisterId: string, cashRegisterClosing: CashRegisterClosing): Observable<CashRegister> {
    return this.httpClient.put<CashRegister>(this.edaraNativeServiceBaseUrl + '/cashregisters/close/' + cashRegisterId, cashRegisterClosing);
  }

  cashInCashRegister(cashRegisterId: string, cashInCashRegister: CashInCashRegister): Observable<CashRegister> {
    return this.httpClient.put<CashRegister>(this.edaraNativeServiceBaseUrl + '/cashregisters/cash-in/' + cashRegisterId, cashInCashRegister);
  }

  cashOutCashRegister(cashRegisterId: string, cashOutCashRegister: CashOutCashRegister): Observable<CashRegister> {
    return this.httpClient.put<CashRegister>(this.edaraNativeServiceBaseUrl + '/cashregisters/cash-out/' + cashRegisterId, cashOutCashRegister);
  }

  findCashTransactions(query: any): Observable<PagedList<CashTransaction[]>> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return this.httpClient.get<PagedList<CashTransaction[]>>(this.edaraNativeServiceBaseUrl + '/cashregisters/cash-transactions?' + queryString);
  }

  getCashRegisterStatusLogsAsync(query: any): Promise<CashRegisterStatusLog[] | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<CashRegisterStatusLog[]>(this.edaraNativeServiceBaseUrl + '/cashRegisters/status-logs?' + queryString)
    ).catch(() => { return undefined; });
  }

  getCashTransactionsLogsAsync(query: any): Promise<CashTransactionLog[] | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<CashTransactionLog[]>(this.edaraNativeServiceBaseUrl + '/cashregisters/cash-transaction-logs?' + queryString)
    ).catch(() => { return undefined; });
  }

  async unpairUserFromCashRegisterLocal(cashRegister?: CashRegister) {
    if (!cashRegister) return;
    cashRegister.activated_user = undefined;
    cashRegister.device_id = undefined;
    this.updateCashRegister(cashRegister).subscribe();
  }

  async updateCashRegisterLocal(cashRegisters: CashRegister[]) {
    if (!cashRegisters?.length) return;
    const retrievedCashRegister = await this.getCashRegisterByIdAsync(cashRegisters[0].id);
    if (!retrievedCashRegister) return;
    retrievedCashRegister.allow_open_close = cashRegisters[0].allow_open_close;
    retrievedCashRegister.device_id = cashRegisters[0].device_id;
    retrievedCashRegister.location = cashRegisters[0].location;
    retrievedCashRegister.customer = cashRegisters[0].customer;
    retrievedCashRegister.defaultPrintTemplate = cashRegisters[0].defaultPrintTemplate;
    retrievedCashRegister.printTemplates = cashRegisters[0].printTemplates;
    retrievedCashRegister.paymentTypes = cashRegisters[0].paymentTypes;
    retrievedCashRegister.orderLayout = cashRegisters[0].orderLayout;
    retrievedCashRegister.cardLayout = cashRegisters[0].cardLayout;
    retrievedCashRegister.active = cashRegisters[0].active;

    this.updateCashRegister(retrievedCashRegister).subscribe();
  }

  async syncCashRegisterUpdates(cashRegister: CashRegister) {
    const query = {
      cashRegisterId: cashRegister.id,
      syncStatus: NativeServiceSyncStatus.Pending
    };
    const statusLogs = await this.getCashRegisterStatusLogsAsync(query);
    const cashTransactions = await this.getCashTransactionsLogsAsync(query);

    if (statusLogs?.length || cashTransactions?.length) {
      const syncCashRegisterUpdates: SyncCashRegisterUpdates = {
        id: cashRegister.id,
        cashRegisterStatusLogs: statusLogs ?? [],
        cashTransactionLogs: cashTransactions ?? []
      };
      this.syncCashRegisterUpdatesServer(cashRegister.id, syncCashRegisterUpdates).subscribe({
        next: () => {
          syncCashRegisterUpdates.cashRegisterStatusLogs?.forEach(element => {
            if (element.status === CashRegisterStatus.Open) {
              element.openingDetails!.syncMetadata!.syncStatus = NativeServiceSyncStatus.Success;
            } else if (element.status === CashRegisterStatus.Closed) {
              element.closingDetails!.syncMetadata!.syncStatus = NativeServiceSyncStatus.Success;
            }
          });
          syncCashRegisterUpdates.cashTransactionLogs?.forEach(element => {
            element.syncMetadata!.syncStatus = NativeServiceSyncStatus.Success;
          });
          this.updateSyncStatus(cashRegister.id, syncCashRegisterUpdates).subscribe();
        }
      });
    }
  }

  private syncCashRegisterUpdatesServer(cashRegisterId: string, syncCashRegisterUpdates: SyncCashRegisterUpdates) {
    return this.httpClient.put(this.edaraCoreBaseUrl + '/api/pos/cashregisters/sync/' + cashRegisterId, syncCashRegisterUpdates);
  }

  private updateSyncStatus(cashRegisterId: string, syncCashRegisterUpdates: SyncCashRegisterUpdates) {
    return this.httpClient.put(this.edaraNativeServiceBaseUrl + '/cashregisters/update-sync-status/' + cashRegisterId, syncCashRegisterUpdates);
  }

  private sortCashRegisterPaymentTypes(paymentTypes: CashRegisterPaymentType[]) {
    paymentTypes?.sort((a, b) => {
      if (a.paymentType.name === 'Cash') return -1;
      if ((a.paymentType.name === 'Bank' || a.paymentType.name === 'Card') && b.paymentType.name !== 'Cash') return -1;
      return 0;
    });
  }

  //#endregion

  async getVersionAsync(): Promise<string> {
    const version = await firstValueFrom(
      this.httpClient.get(this.edaraNativeServiceBaseUrl + `/version`, { responseType: 'text' })
    ).catch(() => { return undefined; });
    return version ? version.split('"').join('') : '';
  }
}
