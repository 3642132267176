import { Timestamp } from "@angular/fire/firestore";

export function convertToDate(date: any): Date {
    if (typeof date === 'string') {
        return new Date(date)
    } else if (date instanceof Date) {
        return date as Date;
    } else if (date instanceof Timestamp) {
        return (date as any)?.toDate();
    } else {
        return date;
    }
}
