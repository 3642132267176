import { Pipe, PipeTransform } from '@angular/core';
import { ErrorMessageFormatter } from '@shared';

@Pipe({
    name: 'descriptiveError'
})
export class DescriptiveErrorPipe implements PipeTransform {
    constructor(private errorMessageFormatter: ErrorMessageFormatter) { }

    transform(error: any) {
        return this.errorMessageFormatter.getErrorMessage(error, 'integrations.errorLogs.generalErrorMassage');
    }
}

