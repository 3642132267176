import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeaturesConfig } from '../../shared/models/features-config';
import { OnlineOfflineService } from './online-offine.service';

@Injectable({
  providedIn: 'root'
})
export class FeaturesConfigService {

  static featuresFlags: FeaturesConfig;
  private httpClient: HttpClient;

  constructor(
    private handler: HttpBackend,
    private onlineOfflineService: OnlineOfflineService) {
    this.httpClient = new HttpClient(handler);
  }

  load() {
    return new Promise((resolve, reject) => {
      this.httpClient.get<FeaturesConfig>(`/assets/features-config.json`).subscribe({
        next: (res: FeaturesConfig) => {
          FeaturesConfigService.featuresFlags = res;
          resolve(true);
        },
        error: (error: any) => {
          reject(`Could not load file features-config.json: ${JSON.stringify(error)}`);
        }
      });
    });
  }

  isFeatureEnabled(featureName: string) {
    // Read the value from the config service
    const feature = FeaturesConfigService.featuresFlags.features.find(f => f.feature === featureName);
    if (this.onlineOfflineService.isOnline) {
      return feature && feature.enabled;
    } else {
      return feature && feature.enabledOffline;
    }
  }

}


