import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-scoped-dialog',
  templateUrl: './scoped-dialog.component.html',
  styleUrls: ['./scoped-dialog.component.scss']
})
export class ScopedDialogComponent implements OnInit {
  @Input() get isVisible(): boolean {return this.isVisibleValue;}
  set isVisible(value: boolean) {
  this.isVisibleValue = value;
    this.toggleDialog()
  }
  isVisibleValue: boolean = false;
  @Input() zIndex: number = 10;
  @Input() showClose: boolean = true;
  @Input() showHeader: boolean = true;
  @Input() dataTitle: string = '';
  @Input() class: string = '';
  @Output() onClose : EventEmitter<boolean>= new EventEmitter();

  constructor(private elmRef:ElementRef, private renderer: Renderer2) { }

  ngOnInit(): void {
  }

  toggleDialog() {
    const elementParent : HTMLElement= this.elmRef.nativeElement.parentNode;
    if(elementParent) {
      this.isVisibleValue ?  this.renderer.addClass(elementParent, 'scoped-dialog-parent') : this.renderer.removeClass(elementParent, 'scoped-dialog-parent');
      this.isVisibleValue ?  this.renderer.setStyle(elementParent, 'z-index',this.zIndex) : this.renderer.removeStyle(elementParent, 'z-index');
    }
  }
  close() {
    this.isVisible = false;
    this.onClose.emit(false)
  }
}
