import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-auto-complete-list-box',
  templateUrl: './auto-complete-list-box.component.html',
  styleUrls: ['./auto-complete-list-box.component.scss']
})
export class AutoCompleteListBoxComponent implements OnInit {

  @Input() options: any[] = [];
  private _selectedOptions: any[] = [];
  @Input()
  set selectedOptions(value) {
    if (value) {
      this._selectedOptions = typeof value === 'string' ? String(value).split(',') : value;
    }
  }
  get selectedOptions() {
    return this._selectedOptions;
  }
  @Input() textProperty: string = '';
  @Input() searchProperties: string[] = [];
  @Input() label: string = '';
  @Input() placeHolder: string = '';
  @Input() inputId: string = '';
  @Input() selectedOptionsLoading = false;
  @Input() disabled = false;
  @Output() searchCompleted = new EventEmitter<any>();

  autoCompleteControl = new FormControl({ value: '', disabled: this.disabled });
  filteredOptions?: Observable<any[]>;
  selectedListItem?: any;

  ngOnInit() {
    this.filteredOptions = this.autoCompleteControl.valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value?.[this.textProperty]),
      map(queryText => queryText ? this._filter(queryText) : this.options.slice(0, 100))
    );
    if (this.searchProperties.indexOf(this.textProperty) === -1) {
      this.searchProperties.push(this.textProperty);
    }
  }

  onOptionSelected(option: any) {
    if (option) {
      this.autoCompleteControl.setValue('');
      const index = this.selectedOptions.indexOf(option[this.textProperty]);
      if (index === -1) {
        this.selectedOptions.push(option[this.textProperty]);
        this.selectedListItem = undefined;
        this.searchCompleted.emit(this.selectedOptions.toString());
      }
    }
  }

  onAddAllOptions() {
    this.selectedOptions = this.options.map(option => option[this.textProperty]);
    this.selectedListItem = undefined;
    this.searchCompleted.emit(this.selectedOptions.toString());
  }

  onRemoveOption() {
    const index = this.selectedOptions.indexOf(this.selectedListItem);
    if (index > -1) {
      this.selectedListItem = undefined;
      this.selectedOptions.splice(index, 1);
      this.searchCompleted.emit(this.selectedOptions.toString());
    }
  }

  onRemoveAllOptions() {
    this.selectedOptions = [];
    this.selectedListItem = undefined;
    this.searchCompleted.emit(this.selectedOptions.toString());
  }

  private _filter(queryText: string): any[] {
    const filterValue = queryText?.toLowerCase();
    return this.options.filter(option => this.searchProperties.some(property => option[property]?.toLowerCase()?.includes(filterValue)))
      .slice(0, 100);
  }
}
