import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'toNumber'
})
export class ToNumberPipe implements PipeTransform {
  transform(value?: string): any {
    if (!value) {
      return value;
    }

    const regex = /,/gi;
    value = value.replace(regex, '');
    const retNumber = Number(value);
    return isNaN(retNumber) ? 0 : retNumber;
  }
}
