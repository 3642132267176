import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@auth';
@Pipe({
  name: 'hasPermissions'
})
export class HasPermissionsPipe implements PipeTransform {
  constructor(private authService: AuthService) {}
    transform(permissions: string[]): boolean  {
      if (this.authService.userPermissions?.length > 0) {
        return this.authService.userPermissions.find(element => permissions.indexOf(element) > -1) !== undefined;
      }
      return false;
    }

  }
