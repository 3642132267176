import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EntityCode } from '@shared/models/entity-code';
import { notEmptyValidator } from '@shared/validators/not-empty.validator';

@Component({
  selector: 'app-code-configuration',
  templateUrl: './code-configuration.component.html',
  styleUrls: ['./code-configuration.component.scss']
})
export class CodeConfigurationComponent implements OnInit {

  submitted = false;

  codeConfigurationForm = this.formBuilder.group({
    prefix: [''],
    sequence: ['', [Validators.required, notEmptyValidator, Validators.pattern("[0-9.]+")]]
  });

  get f() { return this.codeConfigurationForm.controls; }

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CodeConfigurationComponent>,
    @Inject(MAT_DIALOG_DATA) public entityCode: EntityCode) { }

  ngOnInit(): void {
    this.setFormValues();
  }

  onSubmit() {
    this.submitted = true;
    // Stop here if form is invalid
    if (this.codeConfigurationForm.invalid) {
      return;
    }

    this.createEntityCodeObject();
    this.dialogRef.close(this.entityCode);
  }

  private setFormValues() {
    this.f.prefix.setValue(this.entityCode.prefix ?? '');
    this.f.sequence.setValue(this.entityCode.sequence);
  }

  private createEntityCodeObject() {
    this.entityCode.prefix = this.f.prefix.value ?? '';
    this.entityCode.sequence = this.f.sequence.value ?? '';
  }

}
