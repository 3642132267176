import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Auth0DecodedHash, Auth0Error, WebAuth } from 'auth0-js';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, Subject, Subscription, firstValueFrom, timer } from 'rxjs';
import { UserProfile, Organization, AppMetadata } from '../../shared/models/user-profile';
import { AppSettingsService, FirestoreService } from '@core';
import { DexieDbProvider } from '@shared';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _edaraCoreApiBaseUrl: string = '';
  private _accessToken: string = '';
  private _currentTenant: string = '';
  private _userProfile?: UserProfile;
  private _appMetadata?: AppMetadata;
  private _userRoles?: string[];
  private _userPermissions?: string[];

  auth0 = new WebAuth({
    clientID: environment.authConfig.clientID,
    domain: environment.authConfig.domain,
    responseType: 'token id_token',
    redirectUri: `${window.location.origin}/callback`,
    audience: environment.authConfig.apiIdentifier,
    scope: environment.authConfig.scope
  });

  public userLoggedIn$: EventEmitter<UserProfile> = new EventEmitter();
  public userLoggedOut$: EventEmitter<string> = new EventEmitter();
  public tenantSwitched$: EventEmitter<string> = new EventEmitter();

  constructor(
    private httpClient: HttpClient,
    public router: Router,
    private firestoreService: FirestoreService,
    private localDbProvider: DexieDbProvider
  ) {
    this._edaraCoreApiBaseUrl = AppSettingsService.appSettings.edaraCoreApi.baseUrl;
    if (this._edaraCoreApiBaseUrl.endsWith('/')) {
      this._edaraCoreApiBaseUrl = this._edaraCoreApiBaseUrl.slice(0, -1);
    }
  }

  public get accessToken(): string {
    if (!this._accessToken) {
      this._accessToken = localStorage.getItem('accessToken') ?? '';

      // Retry reading accessToken
      if (!this._accessToken) {
        this._accessToken = localStorage.getItem('accessToken') ?? '';
      }
    }

    return this._accessToken;
  }

  public get userProfile(): UserProfile | undefined {
    if (!this._userProfile) {
      let userProfileJson = localStorage.getItem('userProfile');

      // Retry reading user profile
      if (!userProfileJson) {
        userProfileJson = localStorage.getItem('userProfile');
      }

      this._userProfile = userProfileJson ? JSON.parse(userProfileJson) : undefined;
    }

    return this._userProfile;
  }

  public get currentUserId(): string {
    return this.userProfile ? this.userProfile.id.substring(6) : '';
  }

  public get currentUserEmail(): string {
    return this.userProfile ? this.userProfile.email : '';
  }

  public get userRoles(): string[] {
    if (!this._userRoles) {
      this._userRoles = this.getRolesFromToken();
    }
    return this._userRoles;
  }

  public get userPermissions(): string[] {
    if (!this._userPermissions) {
      this._userPermissions = this.getPermissionsFromToken();
    }
    return this._userPermissions;
  }

  public get userOrganizations(): Organization[] {
    return this.appMetadata ? this.appMetadata.organizations : [];
  }

  public get currentTenant(): string {
    if (!this._currentTenant) {
      this._currentTenant = localStorage.getItem('currentTenant') ?? '';

      // Retry reading currentTenant
      if (!this._currentTenant) {
        this._currentTenant = localStorage.getItem('currentTenant') ?? '';
      }
    }

    return this._currentTenant;
  }
  public set currentTenant(tenantId: string) {
    if (tenantId) {
      this._currentTenant = tenantId;
      // Set the current tenant into localStorage
      localStorage.setItem('currentTenant', tenantId);
    }
  }

  public get currentOrganization(): Organization | undefined {
    if (this.currentTenant && this.appMetadata) {
      return this.appMetadata.organizations.find(org => org.id === this.currentTenant);
    }
    return undefined;
  }

  public login(email: string, password: string): Observable<string> {
    const result = new Subject<string>();
    this.auth0.login({
      realm: 'Username-Password-Authentication',
      email,
      password
    }, (err: Auth0Error | null) => {
      if (err?.description) {
        result.next(err.description);
      }
    });
    return result.asObservable();
  }

  public handleAuthentication(): void {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.localLogin(authResult);
        this.firebaseLogin().then(() => {
          this.fetchUserProfile(authResult.accessToken!);
          this.router.navigate(['/']);
        });
      } else if (err) {
        this.router.navigate(['/']);
        console.log(err);
      }
    });
  }

  public renewToken(): void {
    this.unscheduleRenewal();

    if (localStorage.getItem('isLoggedIn') === 'true') {
      this.auth0.checkSession({}, (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.localLogin(authResult);
        } else if (err) {
          console.log(`Could not get a new token (${err.error}: ${err.error_description}).`);
        }
      });
    }
  }

  public async renewFirebaseToken(): Promise<void> {
    this.unscheduleFirebaseRenewal();

    if (this.isAuthenticated()) {
      await this.firebaseLogin();
    }
  }

  public logout(): void {
    // Remove tokens and expiry time
    this._accessToken = '';
    // Remove isLoggedIn flag from localStorage
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('expiresAt');
    localStorage.removeItem('userProfile');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('firebaseExpiresAt');

    this.auth0.logout({
      clientID: environment.authConfig.clientID,
      returnTo: window.location.origin
    });

    this.unscheduleRenewal();
    this.unscheduleFirebaseRenewal();

    this.firestoreService.waitForPendingWrites().then(() => this.firestoreService.signOut());

    this.clearUserData();
  }

  public isAuthenticated(): boolean {
    // Get the expiry time from localStorage
    const expiresAtString = localStorage.getItem('expiresAt');

    // Parse the expiry time or default to 0 if not available
    const expiresAt = expiresAtString ? JSON.parse(expiresAtString) : 0;

    // Check if the expiry time is a valid timestamp and whether it's in the past
    return new Date().getTime() < expiresAt;
  }

  public isInRole(roleName: string): boolean {
    return this.userRoles.includes(roleName);
  }

  public hasPermission(permissionName: string): boolean {
    return this.userPermissions.includes(permissionName);
  }

  public hasPermissions(permissions: string[]): boolean {
    return this.userPermissions.some(permission => permissions.includes(permission));
  }

  public switchTenant(tenantId: string): void {
    if (tenantId) {
      this.currentTenant = tenantId;
    }
    this.tenantSwitched$.emit(this.currentTenant);
    this.router.navigate(['/']);
  }


  public requestResetPassword(captchaCode: string, email: string): Observable<boolean> {
    return this.httpClient.post<boolean>(this._edaraCoreApiBaseUrl + '/api/users/requestResetPassword', { email: email },
      { headers: { captchaCode: captchaCode } });
  }

  public validateResetPasswordCode(code: string): Observable<boolean> {
    return this.httpClient.post<boolean>(this._edaraCoreApiBaseUrl + '/api/users/validateResetPasswordCode', { code: code });
  }

  public resetPassword(code: string, password: string): Observable<boolean> {
    const options = { headers: { 'X-Ignore-Logging': 'true' } };
    return this.httpClient.post<boolean>(this._edaraCoreApiBaseUrl + '/api/users/resetPassword', { code: code, password: password }, options);
  }

  public authenticateAsync(): Promise<string | undefined> {
    const options = { headers: { 'X-Ignore-Logging': 'true' } };
    return firstValueFrom(
      this.httpClient.get<string>(this._edaraCoreApiBaseUrl + '/api/users/authenticate', options)
    ).catch(() => { return undefined; });
  }


  private get appMetadata(): AppMetadata | undefined {
    if (!this._appMetadata) {
      this._appMetadata = this.getAppMetadataFromToken();
    }

    return this._appMetadata;
  }

  private getAppMetadataFromToken(): AppMetadata | undefined {
    if (this.accessToken) {
      const decodedToken = new JwtHelperService().decodeToken(this.accessToken);
      return decodedToken ? decodedToken['http://api.getedara.com/app_metadata'] : undefined;
    }
    return undefined;
  }

  private getRolesFromToken(): string[] {
    if (this.accessToken) {
      const decodedToken = new JwtHelperService().decodeToken(this.accessToken);
      if (decodedToken && Array.isArray(decodedToken['https://api.getedara.com/roles'])) {
        return decodedToken['https://api.getedara.com/roles'];
      }
    }
    return [];
  }

  private getPermissionsFromToken(): string[] {
    if (this.accessToken) {
      const decodedToken = new JwtHelperService().decodeToken(this.accessToken);
      if (decodedToken && Array.isArray(decodedToken['permissions'])) {
        return decodedToken['permissions'];
      }
    }
    return [];
  }

  private fetchUserProfile(accessToken: string): void {
    // Use access token to retrieve user's profile and set session
    this.auth0.client.userInfo(accessToken, (err, profile) => {
      if (profile) {
        // Set User Profile
        this._userProfile = {
          id: profile.sub,
          name: profile.name,
          nickname: profile.nickname,
          email: profile.email!,
          picture: profile.picture
        };
        localStorage.setItem('userProfile', JSON.stringify(this._userProfile));
        this.userLoggedIn$.emit(this._userProfile);
      } else if (err) {
        console.warn(`Error retrieving profile: ${err.error}`);
      }
    });
  }

  private localLogin(authResult: Auth0DecodedHash): void {
    if (authResult.accessToken) {
      // Set access token and login status in localStorage
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('accessToken', authResult.accessToken);

      this._accessToken = authResult.accessToken;
    }

    if (authResult.expiresIn) {
      // Set the time that the access token will expire at
      const expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
      localStorage.setItem('expiresAt', JSON.stringify(expiresAt));
    }

    if (authResult.idTokenPayload) {
      this._appMetadata = authResult.idTokenPayload['http://api.getedara.com/app_metadata'];
      this._userRoles = authResult.idTokenPayload['https://api.getedara.com/roles'];

      // Set current tenant
      this.setPrimaryTenent();
    }

    this.scheduleRenewal();
  }

  private setPrimaryTenent(): void {
    if (!this._appMetadata || !this._appMetadata.organizations) return;

    const organizations = this._appMetadata.organizations;

    if (!this.currentTenant || !organizations.find(org => org.id === this.currentTenant)) {
      const primaryTenant = organizations.find(org => org.is_primary === true) ?? organizations[0];
      this.currentTenant = primaryTenant.id;
      this.tenantSwitched$.emit(this.currentTenant);
    }
  }

  private _refreshSubscription$?: Subscription;
  private scheduleRenewal() {
    if (!this.isAuthenticated()) return;

    const now = Date.now();

    // Use the delay in a timer to run the refresh at the proper time
    var refreshAt = now + (2 * 60 * 60 * 1000); // Refresh after 2 hours
    const source = timer(Math.max(1, refreshAt - now));

    // Once the delay time from above is reached, get a new JWT and schedule additional refreshes
    this._refreshSubscription$ = source.subscribe({
      next: () => {
        this.renewToken();
      }
    });
  }

  private unscheduleRenewal() {
    if (!this._refreshSubscription$) return;
    this._refreshSubscription$.unsubscribe();
  }

  private async firebaseLogin() {
    const firebaseAccessToken = await this.authenticateAsync();
    if (firebaseAccessToken) {
      await this.firestoreService.signIn(firebaseAccessToken);
      const decodedToken = new JwtHelperService().decodeToken(firebaseAccessToken);
      if (decodedToken) {
        // Set the time that the firebase access token will expire at
        const expiresAt = decodedToken['exp'] * 1000;
        localStorage.setItem('firebaseExpiresAt', JSON.stringify(expiresAt));

        this.scheduleFirebaseRenewal();
      }
    }
  }

  private _refreshFirebaseSubscription$?: Subscription;
  private scheduleFirebaseRenewal() {
    if (!this.isAuthenticated()) return;
    // Get the expiry time from localStorage
    const expiresAtString = localStorage.getItem('firebaseExpiresAt');

    // Parse the expiry time or default to 0 if not available
    const expiresAt = expiresAtString ? JSON.parse(expiresAtString) : 0;

    const now = new Date(new Date().toUTCString()).getTime();

    // Use the delay in a timer to run the refresh at the proper time
    var refreshAt = expiresAt - (1000 * 30); // Refresh 30 seconds before expiry

    const source = timer(Math.max(1, refreshAt - now));

    // Once the delay time from above is reached, get a new JWT and schedule additional refreshes
    this._refreshFirebaseSubscription$ = source.subscribe({
      next: () => {
        this.renewFirebaseToken();
      }
    });
  }

  private unscheduleFirebaseRenewal() {
    if (!this._refreshFirebaseSubscription$) return;
    this._refreshFirebaseSubscription$.unsubscribe();
  }

  private async clearUserData() {
    await this.clearAccounts();
  }

  private async clearAccounts() {
    this.localDbProvider.db.accounts.clear();

    const syncMetadata = await this.localDbProvider.db.syncMetadata
      .where('tenantId').equalsIgnoreCase(this.currentTenant)
      .and((itm: any) => itm.entityName === 'Account')
      .and((itm: any) => itm.statusCode === 200)
      .toArray();

    if (syncMetadata.length > 0) {
      this.localDbProvider.db.syncMetadata
        .bulkDelete(syncMetadata.map((itm: any) => itm.id))
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }
}
