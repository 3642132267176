import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { SwitchLanguageService } from '../../core/services/switch-language.service';
import moment from 'moment';

@Pipe({
  name: 'auditLogValueFormatter',
})
export class AuditLogValueFormatter implements PipeTransform {

  format = 'short';
  locale = 'en';

  constructor(private switchLanguageService: SwitchLanguageService) {
    this.locale = this.switchLanguageService.currentLanguage;
  }

  transform(value: string): string {
    if (!value) {
      return 'NA';
    }

    // Check if the value is a valid date string
    if (moment(value, moment.ISO_8601, true).isValid() && isNaN(Number(value))) {
      return formatDate(
        new Date(value),
        this.format,
        this.locale
      );
    }

    // Return the value as is if it doesn't match any of the above conditions
    return value;
  }

}
