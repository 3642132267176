import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { EdaraSettingsService } from '../../core/services/edara-settings.service';
import { MoneyHelper } from '@shared';

@Pipe({
  name: 'quantityFormatter',
})
export class QuantityFormatter implements PipeTransform {
  locale = 'en';
  constructor(private edaraSettingsService: EdaraSettingsService,
    private moneyHelper: MoneyHelper) {
  }

  async transform(
    value: number
  ) {
    let noOfDecimalPlaces = await this.edaraSettingsService.noOfDecimalPlacesQty();
    if (!noOfDecimalPlaces || noOfDecimalPlaces < 2) {
      noOfDecimalPlaces = 2;
    }

    const digitsInfo = `1.0-${noOfDecimalPlaces}`;
    return formatNumber(
      this.moneyHelper.round(value, noOfDecimalPlaces),
      this.locale,
      digitsInfo
    );
  }
}
