import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { LockService } from '../../services/lock.service';

@Component({
  selector: 'app-lock-screen',
  templateUrl: './lock-screen.component.html',
  styleUrls: ['./lock-screen.component.scss']
})
export class LockScreenComponent implements OnInit {

  ACDelbuttons: boolean = false;
  passcodeWrong: boolean = false;
  passcodeAttempts = 0;
  enteredPasscode = '';
  touchLabel: string = '';

  selected: any;

  @ViewChild('focusElement', { static: true }) focusElement!: ElementRef;

  constructor(private lockService: LockService) { }

  ngOnInit() {
    // set focus to lock screen
    this.focusElement.nativeElement.focus();
  }

  allClear(): void {
    this.enteredPasscode = '';
  }

  remove(): void {
    this.enteredPasscode = this.enteredPasscode.slice(0, -1);
  }

  digit(digit: any): void {
    this.selected = +digit;
    if (this.passcodeWrong) {
      return;
    }
    this.enteredPasscode += '' + digit;

    if (this.enteredPasscode.length >= 4) {
      if (this.enteredPasscode === '' + this.lockService.passCode) {
        this.enteredPasscode = '';
        this.passcodeAttempts = 0;
        this.onCorrect();
        this.lockService.hide();
      } else {
        this.passcodeWrong = true;
        this.passcodeAttempts++;
        this.onWrong(this.passcodeAttempts);
        setTimeout(() => {
          this.enteredPasscode = '';
          this.passcodeWrong = false;
        }, 800);
      }
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    for (let i = 0; i <= 9; i++) {
      if (event.key === `${i}`) {
        this.digit(i);
      }
    }
  }

  onCorrect() {
    this.lockService.correct();
    this.lockService.hide();
  }

  onWrong(passcodeAttempts: number) {
    if (passcodeAttempts === 5) {
      this.lockService.wrong();
    }
  }
}
