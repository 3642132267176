import { Component, OnInit, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { AuthService } from '@auth';
import { UserProfile, Organization } from 'src/app/shared/models/user-profile';
import { SwitchLanguageService } from 'src/app/core/services/switch-language.service';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { AllowIn, ShortcutInput, KeyboardShortcutsComponent } from 'ng-keyboard-shortcuts';
import { Router } from '@angular/router';
import { KeyboardShortcutsHelpComponent } from '../keyboard-shortcuts-help/keyboard-shortcuts-help.component';
import { SyncService } from '../../services/sync.service';
import { PagePermissions } from 'src/app/shared/models/permissions';
import { AppService } from '../../services/app.service';
import { PreviewFeaturesComponent } from '../preview-features/preview-features.component';
import { TranslateService } from '@ngx-translate/core';
import { HelpersService } from '@shared';

@Component({
  selector: 'app-toolbar',
  templateUrl: './tool-bar.component.html',
  styleUrls: ['./tool-bar.component.scss']

})
export class ToolBarComponent implements OnInit, AfterViewInit {
  isSwitchOrganization: boolean = false;
  isPreviewFeatures: boolean = false;
  isSwitchLanguage: boolean = false;
  userProfile?: UserProfile;
  currentOrganization?: Organization;
  userOrganizations: Organization[] = [];
  filterdUserOrganizations: any;
  isExpanded = false;
  syncCompleted = true;

  shortcuts: ShortcutInput[] = [];

  public get pagePermissions(): typeof PagePermissions {
    return PagePermissions;
  }

  @ViewChild('clickMenuTrigger') clickMenuTrigger!: MatMenuTrigger;
  @ViewChild('switchTenantTrigger') switchTenantTrigger!: MatMenuTrigger;
  @ViewChild('changeLanguageTrigger') changeLanguageTrigger!: MatMenuTrigger;

  constructor(
    public authService: AuthService,
    public helpersService: HelpersService,
    private syncService: SyncService,
    private router: Router,
    public switchLanguageService: SwitchLanguageService,
    public appService: AppService,
    private translateService: TranslateService,
    @Inject(MatDialog) private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.authService.userLoggedIn$.subscribe({
      next: (userProfile: UserProfile) => {
        if (userProfile) {
          this.userProfile = userProfile;
          this.currentOrganization = this.authService.currentOrganization;
        }
      }
    });

    this.authService.tenantSwitched$.subscribe({
      next: () => {
        this.currentOrganization = this.authService.currentOrganization;
      }
    });

    if (this.authService.isAuthenticated()) {
      this.userProfile = this.authService.userProfile;
      this.currentOrganization = this.authService.currentOrganization;
      this.userOrganizations = this.authService.userOrganizations;
      this.filterdUserOrganizations = this.authService.userOrganizations;
    }

    this.syncService.syncStatusChanged.subscribe({
      next: (res: boolean) => {
        this.syncCompleted = res;
      }
    });
  }

  ngAfterViewInit() {
    this.initShortcuts();
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  switchTenant(tenantId: string) {
    this.authService.switchTenant(tenantId);
  }

  logout() {
    this.authService.logout();
  }

  help(): void {
    this.dialog.open(KeyboardShortcutsHelpComponent, {
      width: '950px',
      height: '95vh',
      autoFocus: false,
      restoreFocus: false,
      closeOnNavigation: true
    });
  }

  openPreviewFeaturesDialog() {
    this.dialog.open(PreviewFeaturesComponent, {
      width: '400px',
      height: '95vh',
      autoFocus: false,
      restoreFocus: false,
      closeOnNavigation: true,
      position: this.translateService.currentLang === 'en' ? { top: '0px', right: '14px' } : { top: '0px', left: '14px' },
      panelClass: this.translateService.currentLang === 'en' ? ['previewFeatures_panel', 'animate__animated', 'animate__slideInDown'] :
        ['previewFeatures_panel', 'animate__animated', 'animate__slideInDown']
    });
  }

  initShortcuts() {
    this.shortcuts.push(
      {
        key: ['alt + shift + s'],
        allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
        label: 'Navigation',
        description: 'Settings',
        command: () => this.router.navigate(['/settings']),
        preventDefault: true
      },
      {
        key: ['alt + shift + t'],
        allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
        label: 'Navigation',
        description: 'Switch Tenant',
        command: () => this.openSwitchTenantMenu(),
        preventDefault: true
      },
      {
        key: ['alt + shift + x'],
        allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
        label: 'Navigation',
        description: 'Change Language',
        command: () => this.openChangeLanguageMenu(),
        preventDefault: true
      },
    );
  }

  private openSwitchTenantMenu() {
    this.clickMenuTrigger.openMenu();
    this.switchTenantTrigger.openMenu();
  }

  private openChangeLanguageMenu() {
    this.clickMenuTrigger.openMenu();
    this.changeLanguageTrigger.openMenu();
  }

  showSwitchOrganization() {
    this.isSwitchOrganization = !this.isSwitchOrganization
  }

  showSwitchLanguage() {
    this.isSwitchLanguage = !this.isSwitchLanguage
  }
  showPreviewFeatures() {
    this.isPreviewFeatures = !this.isPreviewFeatures
  }
  closedMenu() {
    setTimeout(() => {
      this.isSwitchOrganization = false;
      this.isPreviewFeatures = false;
      this.isSwitchLanguage = false;
    }, 100);
  }
  filterUserMenu(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (!filterValue) {
      this.filterdUserOrganizations = [...this.userOrganizations];
    } else {
      this.filterdUserOrganizations = [];
      this.filterdUserOrganizations = this.userOrganizations.filter((item) => {
        return item.name.toUpperCase().indexOf(filterValue.toUpperCase()) > -1;
      });
    }
  }

}
