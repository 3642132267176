import { CollectionReference, orderBy, query, where } from "@angular/fire/firestore";
import { Observable, firstValueFrom } from "rxjs";
import { PotentialFraudDetector } from 'src/app/shared/models/potential-fraud-detector';
import { DevToolsEvent } from 'devtools-detect';
import { FirestoreService } from "./firestore.service";
import { AuthService } from "@auth";
import { Injectable } from "@angular/core";
import { DexieDbProvider, IPHelper } from '@shared';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from './app-settings.service';
import { ClientJS } from 'clientjs';
import devtools from 'devtools-detect';

@Injectable({
  providedIn: 'root'
})
export class PotentialFraudDetectorService {

  private indexeddb: any;
  private devToolChanged_TimeStamp?: number;
  private appSettings: any = {};

  constructor(private authService: AuthService,
    private firestoreService: FirestoreService,
    private localDbProvider: DexieDbProvider,
    private ipHelper: IPHelper,
    private appSettingsService: AppSettingsService
  ) { }

  private loadSettings() {
    if (!AppSettingsService.appSettings) {
      this.appSettingsService.load().then(loaded => {
        if (loaded)
          this.appSettings = AppSettingsService.appSettings;
      });
    }
    else {
      this.appSettings = AppSettingsService.appSettings;
    }
  }

  public start() {
    if (environment.production) {
      // Check if it's open
      console.log('Is DevTools open:', devtools.isOpen);
      // Check it's orientation, `undefined` if not open
      console.log('DevTools orientation:', devtools.orientation);
      //*****************Start >> To detect case that devTools has been opend ****************/
      window.addEventListener('devtoolschange', (event: DevToolsEvent) => {
        if (event.detail.isOpen) {
          //************** Start >> For fix bug of duplicate log into firestore ******************
          if (this.devToolChanged_TimeStamp && this.devToolChanged_TimeStamp === event.timeStamp)
            return;
          else
            this.devToolChanged_TimeStamp = event.timeStamp;
          //************** END >> For fix bug of duplicate log into firestore ******************
          this.sendToFirestore("DevToolsOpen", true);
        }
      });
      //*****************End >> To detect case that devTools opend ****************/

      //*****************Start >> To detect case that indexDB has been deleted/clear site data****************/
      this.indexeddb = this.localDbProvider.db.idbdb;
      if (!this.indexeddb) {
        this.localDbProvider.db.on("ready", () => this.start())
        return;
      }
      this.indexeddb.onclose = () => this.sendToFirestore("ClearSiteData", false);
      //*****************End >> To detect case that indexDB has been deleted/clear site data****************/
    }
  }

  public stop() {
    this.indexeddb.onclose = null;
    this.indexeddb = null;
  }

  private get potentialFraudDetectorCollection(): CollectionReference<PotentialFraudDetector> {
    return this.firestoreService.col<PotentialFraudDetector>('PotentialFraudDetector');
  }

  loadPotentialFraudDetectorItems(): Observable<PotentialFraudDetector[]> {
    const queryRef = query(this.potentialFraudDetectorCollection,
      where('UserOrganization.name', '==', this.authService.currentOrganization?.name),
      orderBy('ActionDate', 'desc')
    );
    return this.firestoreService.colValueChanges$(queryRef);
  }

  private async sendToFirestore(action: string, ignoreCallStart: boolean): Promise<void> {
    this.loadSettings();
    let deviceFPrint: any = await this.getCustomFingerprint();
    let actionDate = new Date().getTime();
    let potentialFraudDetectorObj: PotentialFraudDetector = {
      ID: 0,
      Action: action,
      UserTenant: this.authService.currentTenant,
      Username: this.authService.userProfile!.nickname,
      Email: this.authService.userProfile!.email,
      UserOrganization: this.authService.currentOrganization!,
      ActionDate: new Date(),
      ActionTime: actionDate,
      DeviceFingerPrint_Encrypted: deviceFPrint.encrypted,
      DeviceFingerPrint_Decrypted: deviceFPrint.decrypted,
      OfflineModeEnabled: this.appSettings.offlineMode.enabled
    };
    this.firestoreService.addDoc(this.potentialFraudDetectorCollection, potentialFraudDetectorObj);
    if (!ignoreCallStart) {
      this.localDbProvider.createDatabase();
      this.start();
    }
  }

  private getCustomFingerprint(): any {
    return this.getClientIp().then((ip) => {
      let clientIP = ip;
      let client = new ClientJS();
      let customFingerPrint = {
        clientIP: clientIP, browserData: client.getBrowser(), cpu: client.getCPU(),
        isWindows: client.isWindows(), isMac: client.isMac(), isLinux: client.isLinux(),
        isMobile: client.isMobile(), isIphone: client.isIphone(), isIpad: client.isIpad(), isIpod: client.isIpod(),
        colorDepth: client.getColorDepth(), currentResolution: client.getCurrentResolution(), availableResolution: client.getAvailableResolution(),
        canvasPrint: client.getCanvasPrint()
      };
      return { encrypted: client.getCustomFingerprint(JSON.stringify(customFingerPrint)), decrypted: customFingerPrint };
    });
  }

  private async getClientIp(): Promise<string> {
    return firstValueFrom(
      this.ipHelper.getClientIP()
    ).then(data => {
      return data.ip;
    }).catch(() => "N/A");
  }

}
