import { Pipe, PipeTransform } from '@angular/core';
import { lowerCase } from 'lodash';

function capitalize(str: string): string {
  const regex = /[1-9a-zA-Z]/;
  let result = '';

  for (let i = 0; i < str.length; i++) {
    const shouldCapitalize = (i === 0 && regex.test(str[i]))
      || (!regex.test(str[i - 1]) && regex.test(str[i]));

    if(shouldCapitalize)
      result += str[i].toUpperCase();
    else
      result += str[i].toLowerCase();
  }

  return result;
}

@Pipe({
  name: 'capitalize'
})
export class Capitalize implements PipeTransform {

  transform(value: string): string {
    if (!value) return value;

    let capitalizeTitles = capitalize(value);
    let regEx = /\b(of)\b/gi;
    let match = regEx.exec(capitalizeTitles)
    if (match !== null) {
      match.forEach((match) => {
        capitalizeTitles = capitalizeTitles.replaceAll(match, lowerCase(match))
      });
    }
    return capitalizeTitles;
  }
}
