<mat-form-field class="mat-custom__input mat-custom__input--bg-white"  floatLabel="always">
  <mat-label>{{ label }}</mat-label>
  <input matInput [id]="inputId" type="text" [formControl]="formCtrl" [required]="required"
    (countrychange)="onSelectCountryCode()" #intlTelInput>
  <mat-error *ngIf="validationText && formCtrl.errors?.['invalidNumber'] && formSubmitted">
    {{ validationText }}
  </mat-error>
  <mat-error *ngIf="required && requiredText && formCtrl.errors?.['required'] && formSubmitted">
    {{ requiredText }}
  </mat-error>
</mat-form-field>
