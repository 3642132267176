export interface INativeServiceSyncable {
    localId: string;
    syncStatus: NativeServiceSyncStatus;
}

export interface SyncResult {
    localId: string;
    syncStatus: NativeServiceSyncStatus;
    error: string;
}

export enum NativeServiceSyncStatus {
    Pending = 1,
    Processing = 2,
    Success = 3,
    ConflictOrError = 4
}