export class PrchWorkflow {
    id?: string;
    area!: WorkFlowArea;
    name!: string;
    conditions: WorkFlowCondition[] = [];
    action!: WorkFlowAction;
    approvers: string[] = [];
}

export enum WorkFlowArea {
    PurchaseOrder = 0
}

export class WorkFlowCondition {
    type!: WorkFlowConditionType;
    amount!: number;
    operator!: ComparisonOperator;
}

export enum WorkFlowConditionType {
    OrderTotal = 0
}

export enum ComparisonOperator {
    LessThanOrEqual = 0,
    GreaterThanOrEqual = 1,
    LessThan = 2,
    GreaterThan = 3
}

export enum WorkFlowAction {
    CannotProceedWithoutApproval = 0
}