import { Component, inject, OnInit } from '@angular/core';
import { ModulesLandingControllerComponent } from '../modules-landing-controller/modules-landing-controller.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss'
})
export class LandingPageComponent extends ModulesLandingControllerComponent implements OnInit {
  pageTitle: string = ''
  landingModuleName: string = ''

  ngOnInit(): void {
    const routeUrl = this.route.url;
    if (routeUrl.includes('/fixed-assets/landing')) {
      this.pageTitle = 'nav-menu.fixedAssets'
      this.landingModuleName = this.landingModulesNames.fixedAssets
    } else if (routeUrl.includes('/purchasing/landing')) {
      this.pageTitle = 'nav-menu.purchasing'
      this.landingModuleName = this.landingModulesNames.purchasing
    } else if (routeUrl.includes('/pos/landing')) {
      this.pageTitle = 'nav-menu.pos'
      this.landingModuleName = this.landingModulesNames.pos
    } else if (routeUrl.includes('/manufacturing/landing')) {
      this.pageTitle = 'nav-menu.manufacturing'
      this.landingModuleName = this.landingModulesNames.manufacturing
    } else if (routeUrl.includes('/invoicing/landing')) {
      this.pageTitle = 'nav-menu.invoicing'
      this.landingModuleName = this.landingModulesNames.invoicing
    } else if (routeUrl.includes('/data/landing')) {
      this.pageTitle = 'nav-menu.data'
      this.landingModuleName = this.landingModulesNames.dataModule
    } else if (routeUrl.includes('/system/landing')) {
      this.pageTitle = 'nav-menu.system'
      this.landingModuleName = this.landingModulesNames.systemModule
    }
  }
}
