import { PaymentType } from './sales-order';
export class SalesOrderInstallment {
    id?: number;
    document_id?: number;
    days_limit?: number;
    amount: number;
    due_date: Date;
    account_id: number;
    account_name: string;
    account_type: string;
    payment_type: PaymentType;
    constructor(amount: number, accountId: number, accountName: string, accountType: string, dueDate: Date, paymentType: PaymentType) {
        this.amount = amount;
        this.account_id = accountId;
        this.account_name = accountName;
        this.account_type = accountType;
        this.due_date = dueDate;
        this.payment_type = paymentType;
    }
}
