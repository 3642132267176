import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PurchaseInvoice } from "../../models/purchasing-invoice.model";

@Component({
  selector: 'app-purchase-invoice-print',
  templateUrl: './purchase-invoice-print.component.html',
  styleUrls: ['./purchase-invoice-print.component.scss']
})
export class PurchaseInvoicePrintComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public purchaseInvoice: PurchaseInvoice) { }

  onShowPrintDialog() {
    window.print();
  }

}


