import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appRowHighlightOnMenuOpen]'
})
export class RowHighlightOnMenuOpenDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('menuOpened')
  onMenuOpened() {
    this.toggleRowHighlight(true);
  }

  @HostListener('menuClosed')
  onMenuClosed() {
    this.toggleRowHighlight(false);
  }

  private toggleRowHighlight(isHighlight: boolean) {
    const rowElement = this.findClosestRow();
    if (rowElement) {
      const action = isHighlight ? 'addClass' : 'removeClass';
      this.renderer[action](rowElement, 'row-highlight');

      // Handling pinned cells by their class names
      const pinnedRowElements = document.querySelectorAll(`[row-id="${rowElement.getAttribute('row-id')}"]`);
      pinnedRowElements.forEach((pinnedRow: Element) => {
        this.renderer[action](pinnedRow, 'row-highlight');
      });
    }
  }

  private findClosestRow(): HTMLElement | null {
    let parent = this.el.nativeElement;
    while (parent && !parent.classList.contains('ag-row')) {
      parent = parent.parentElement;
    }
    return parent;
  }
}
