
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';
import { DexieDbProvider, PrioritizesScanningBarcodeEnum } from '@shared';
import { StockItem, StockItemSerial, WarehouseStock } from '../../shared/models/stock-item';
import { Warehouse } from '../../shared/models/warehouse';
import { ApiResponse } from '../../shared/models/api-response';
import { of, Observable, from, firstValueFrom } from 'rxjs';
import { QueryStringHelper } from '../helpers/query-string.helper';
import { AppSettingsService } from '../../core/services/app-settings.service';
import { OnlineOfflineService } from '../../core/services/online-offine.service';
import { OtherParty } from 'src/app/shared/models/other-party';
import { ServiceResult } from 'src/app/shared/models/service-result';
import { StockItemGlobalBalance } from 'src/app/shared/models/stock-item-global-balance';
import { SalesOrderItem } from 'src/app/shared/models/sales-order-item';
import { SalesOrder } from 'src/app/shared/models/sales-order';
import { StockItemClassification } from 'src/app/shared/models/stock-item-classification';
import { SalesSettings } from 'src/app/point-of-sale/models/pos-settings';

@Injectable({
  providedIn: 'root'
})

export class WarehouseService {

  baseUrl = '';
  constructor(private httpClient: HttpClient,
    private localDbProvider: DexieDbProvider,
    private onlineOfflineService: OnlineOfflineService) {
    this.baseUrl = AppSettingsService.appSettings.edaraApi.baseUrl;
  }

  //#region Stock Items

  searchStockItems(searchKeyWords: string) {
    if (searchKeyWords === '') {
      return of([]);
    }
    return from(this.searchStockItemsAsync(searchKeyWords)) as Observable<StockItem[]>;
  }

  searchStockItemsLocal(searchKeyWords: string, salesSettings: SalesSettings, warehouseId?: number): Observable<StockItem[]> {
    if (searchKeyWords === '') {
      return of([]);
    }
    return from(this.searchStockItemsLocalAsync(searchKeyWords, salesSettings, warehouseId));
  }

  searchStockItemsServer(searchKeyWords: string, preventSellingZeroBalance: boolean = false, warehouseId?: number): Observable<StockItem[]> {
    return this.httpClient.get<ApiResponse<StockItem[]>>(this.baseUrl + `v2.0/stockItems/SearchStockItems?searchKeyWords=${searchKeyWords}`)
      .pipe(map((res: ApiResponse<StockItem[]>) => {
        if (res.result) {
          this.addStockItemsToLocalDb(res.result);

          if (preventSellingZeroBalance) {
            res.result = res.result.filter(item => item.warehouse_stocks.filter(stock => stock.warehouse_id === warehouseId)
              .map(x => x.balance).reduce((a, b) => a + b, 0) > 0);
          }

          return res.result;
        }
        return [];
      }));
  }

  private async searchStockItemsAsync(searchKeyWords: string) {
    const localResult = await this.searchStockItemsLocalAsync(searchKeyWords);
    if (localResult.length > 0) {
      return localResult;
    } else {
      return await this.searchStockItemsServerAsync(searchKeyWords);
    }
  }

  private searchStockItemsServerAsync(searchKeyWords: string) {
    return firstValueFrom(
      this.searchStockItemsServer(searchKeyWords)
    ).catch(() => { return undefined; });
  }

  // Create a Map object to serve as the cache
  barcodeCache = new Map<string, StockItem[]>();

  private async searchStockItemsLocalAsync(searchKeyWords: string, salesSettings?: SalesSettings, warehouseId?: number): Promise<StockItem[]> {
    let items: StockItem[] = [];

    if (salesSettings && salesSettings.prioritizesScanningBarcode === PrioritizesScanningBarcodeEnum.normalOverWeighted) {
      // Check if the results are already cached
      items = this.barcodeCache.get(searchKeyWords) ?? [];
      if (items?.length > 0) {
        console.log('Stock Items loaded from Cache');
        return items;
      }

      items = await this.localDbProvider.db.stockItems
        .where('part_number').equalsIgnoreCase(searchKeyWords)
        .and((itm: any) => itm.active === 1)
        .toArray();

      // Cache the results if the search is done by barcode
      if (items?.length > 0) {
        this.barcodeCache.set(searchKeyWords, [...items]);
        return items;
      }
    }
    else if (salesSettings && salesSettings.prioritizesScanningBarcode === PrioritizesScanningBarcodeEnum.weightedOverNormal &&
      this.matchesWeightedBarcodePattern(searchKeyWords)) {

      const stockItemCode = searchKeyWords.substring(1, 6);
      items = await this.localDbProvider.db.stockItems
        .where('code').equalsIgnoreCase(stockItemCode)
        .or('part_number').startsWithIgnoreCase(searchKeyWords)
        .or('uom_part_numbers').startsWithIgnoreCase(searchKeyWords)
        .and((itm: any) => itm.active === 1)
        .offset(0)
        .limit(100)
        .toArray();

      // Sort items to move the barcode matches to the top of the list over code matches
      if (items.length > 1) {
        items = items.sort((a, b) => this.compareStockItems(a, b, searchKeyWords));
      }
    }

    if (items?.length === 0) {
      items = await this.localDbProvider.db.stockItems
        .where('part_number').startsWithIgnoreCase(searchKeyWords)
        .or('code').startsWithIgnoreCase(searchKeyWords)
        .or('description').startsWithIgnoreCase(searchKeyWords)
        .or('uom_part_numbers').startsWithIgnoreCase(searchKeyWords)
        .or('description_keywords').startsWithIgnoreCase(searchKeyWords)
        .and((itm: any) => itm.active === 1)
        .offset(0)
        .limit(100)
        .toArray();

      // Search stock items by serial
      if (salesSettings?.allowSellingBySerialNumber) {
        const itemsBySerial = await this.searchStockItemsBySerialLocalAsync(searchKeyWords);
        if (itemsBySerial?.length > 0) {
          items.push(...itemsBySerial);
        }
      }
    }

    if (items?.length === 0 && searchKeyWords.includes("+") && searchKeyWords.split("+").join("").length > 0) {
      const keyWordsArray = searchKeyWords.toLowerCase().split('+').filter(keyword => keyword);
      items = await this.localDbProvider.db.stockItems
        .where('description_keywords').startsWithIgnoreCase(keyWordsArray[0])
        .and((itm: any) => keyWordsArray.every(keyword => itm.description.toLowerCase().includes(keyword)))
        .and((itm: any) => itm.active === 1)
        .offset(0)
        .limit(100)
        .toArray();
    }

    if (salesSettings?.preventSellingZeroBalanceStockItems) {
      const filteredItems = await Promise.all(
        items.map(async item => {
          const warehouseStocks = item.warehouse_stocks.filter(stock => stock.warehouse_id === warehouseId);
          const totalBalance = warehouseStocks.map(stock => stock.balance).reduce((a, b) => a + b, 0);
          if (totalBalance > 0) {
            return item;
          }
          return null;
        })
      );
      return filteredItems.filter((item): item is StockItem => item !== null);
    }

    return items;
  }

  searchStockItemsBySerialLocal(searchKeyWords: string): Observable<StockItem[]> {
    if (searchKeyWords === '') {
      return of([]);
    }
    return from(this.searchStockItemsBySerialLocalAsync(searchKeyWords));
  }

  public async searchStockItemsBySerialLocalAsync(searchKeyWords: string): Promise<StockItem[]> {
    const serials = await this.localDbProvider.db.stockItemSerials
      .where('serial_number').startsWithIgnoreCase(searchKeyWords)
      .toArray();

    const stockItems: StockItem[] = [];
    if (serials.length > 0) {
      for (let index = 0; index < serials.length; index++) {
        const serial = serials[index];
        const stockItem = await this.getStockItemByIdLocalAsync(serial.stock_item_id);
        if (stockItem) {
          stockItem.stockItemSerial = serial;
          stockItems.push(stockItem);
        }
      }
    }

    return stockItems;
  }

  private compareStockItems(a: StockItem, b: StockItem, searchKeyWords: string) {
    if (a.part_number === searchKeyWords || (a.uom_part_numbers && a.uom_part_numbers.findIndex(uom => uom === searchKeyWords) !== -1)) {
      return -1;
    }

    return 0;
  }

  private matchesWeightedBarcodePattern(barcode: string): boolean {
    return !!barcode && barcode.length === 12 && !isNaN(Number(barcode));
  }

  async loadStockItems(query: any): Promise<ServiceResult<StockItem[]>> {
    const serviceResult = new ServiceResult<StockItem[]>();
    const count = await this.countStockItemsLocalAsync();
    if (!this.onlineOfflineService.isOnline || count > 0) {
      serviceResult.statusCode = 200;
      serviceResult.result = await this.getStockItemsLocalAsync(query);
      serviceResult.totalCount = count;
      return serviceResult;
    } else {
      const res = await this.getStockItemsAsync(query);
      if (res) {
        this.addStockItemsToLocalDb(res.result);
        serviceResult.statusCode = res.status_code;
        serviceResult.result = res.result;
        serviceResult.statusMessage = res.error_message;
        serviceResult.totalCount = res.total_count;
      }
      return serviceResult;
    }
  }

  private async countStockItemsLocalAsync(): Promise<number> {
    return this.localDbProvider.db.stockItems.count()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  private async getStockItemsLocalAsync(query: any): Promise<StockItem[]> {
    return this.localDbProvider.db.stockItems
      .offset(query.offset)
      .limit(query.limit)
      .toArray();
  }

  getStockItemsAsync(query: any): Promise<ApiResponse<StockItem[]> | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);

    return firstValueFrom(
      this.httpClient.get<ApiResponse<StockItem[]>>(this.baseUrl + 'v2.0/stockItems?' + queryString)
        .pipe(map(res => {
          if (res.result) {
            res.result.forEach(stockItem => this.mapStockItem(stockItem));
          }
          return res;
        }))
    ).catch(() => { return undefined; });
  }

  addStockItemsToLocalDb(stockItems: StockItem[]) {
    if (stockItems && stockItems.length > 0) {
      stockItems.forEach(stockItem => this.mapStockItem(stockItem));
      this.localDbProvider.db.stockItems.bulkPut(stockItems)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  async loadAllStockItems(): Promise<ServiceResult<StockItem[]>> {
    const query = {
      offset: 0,
      limit: 1000
    };
    const serviceResult = new ServiceResult<StockItem[]>();
    const count = await this.countStockItemsLocalAsync();
    if (!this.onlineOfflineService.isOnline || count > 0) {
      query.limit = count;
      serviceResult.result = await this.getStockItemsLocalAsync(query);
      serviceResult.statusCode = 200;
      serviceResult.totalCount = count;
      return serviceResult;
    } else {
      let response: ApiResponse<StockItem[]> | undefined;
      let stockItems: StockItem[] = [];
      do {
        response = await this.getStockItemsAsync(query);
        if (response && response.status_code === 200) {
          query.offset += query.limit;
          stockItems = stockItems.concat(response.result);
          this.addStockItemsToLocalDb(response.result);
        }
      } while (response && response.status_code === 200 && response.total_count > query.offset);
      serviceResult.statusCode = response?.status_code;
      serviceResult.result = stockItems;
      serviceResult.statusMessage = response?.error_message;
      serviceResult.totalCount = response?.total_count;
      return serviceResult;
    }
  }

  async getStockItemById(stockItemId: number) {
    const serviceResult = new ServiceResult<StockItem>();
    const stockItem = await this.getStockItemByIdLocalAsync(stockItemId);
    if (stockItem && stockItem.id) {
      serviceResult.statusCode = 200;
      serviceResult.result = stockItem;
      return serviceResult;
    } else {
      const res = await this.getStockItemByIdAsync(stockItemId);
      if (res) {
        this.addStockItemToLocalDb(res.result);
        serviceResult.statusCode = res.status_code;
        serviceResult.result = res.result;
        serviceResult.statusMessage = res.error_message;
        serviceResult.totalCount = res.total_count;
      }
      return serviceResult;
    }
  }

  private getStockItemByIdLocalAsync(stockItemId: number): Promise<StockItem> {
    return this.localDbProvider.db.stockItems.get(+stockItemId);
  }

  getStockItemByIdAsync(stockItemId: number): Promise<ApiResponse<StockItem> | undefined> {
    return firstValueFrom(
      this.httpClient.get<ApiResponse<StockItem>>(this.baseUrl + `v2.0/stockItems/${stockItemId}`)
    ).catch(() => { return undefined; });
  }

  private addStockItemToLocalDb(stockItem: StockItem) {
    if (stockItem) {
      this.mapStockItem(stockItem);
      this.localDbProvider.db.stockItems.put(stockItem)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  private mapStockItem(stockItem: StockItem) {
    if (stockItem.unit_of_measures) {
      stockItem.uom_part_numbers = stockItem.unit_of_measures.map((item) => item['uom_part_number']).filter(itm => !!itm);
    }

    if (stockItem.description && stockItem.description.trim().includes(' ')) {
      stockItem.description_keywords = stockItem.description.split(' ').filter(itm => !!itm);
    }
  }

  getStockItemGlobalBalanceAsync(stockItemId: number): Promise<ApiResponse<StockItemGlobalBalance> | undefined> {
    return firstValueFrom(
      this.httpClient.get<ApiResponse<StockItemGlobalBalance>>(this.baseUrl + `v2.0/stockItems/${stockItemId}/global-balance`)
    ).catch(() => { return undefined; });
  }

  createStockItem(stockItem: StockItem) {
    return this.httpClient.post<ApiResponse<string>>(this.baseUrl + 'v2.0/stockItems', stockItem);
  }

  updateStockItem(stockItem: StockItem) {
    return this.httpClient.put<ApiResponse<boolean>>(this.baseUrl + 'v2.0/stockItems', stockItem);
  }

  async deleteStockItemAsync(stockItemId: number): Promise<ApiResponse<boolean> | undefined> {
    return firstValueFrom(
      this.httpClient.delete<ApiResponse<boolean>>(this.baseUrl + `v2.0/stockItems/${stockItemId}`)
    ).catch(() => { return undefined; });
  }

  async getStockItemSerialsAsync(query: any): Promise<ApiResponse<StockItemSerial[]> | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<ApiResponse<StockItemSerial[]>>(this.baseUrl + 'v2.0/stockItems/serials?' + queryString)
    ).catch(() => { return undefined; });
  }

  async addStockItemSerialsToLocalDb(stockItemSerials: StockItemSerial[]) {
    if (stockItemSerials && stockItemSerials.length > 0) {
      this.localDbProvider.db.stockItemSerials.bulkPut(stockItemSerials)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  getStockItemBalanceAsync(stockItemId: number): Promise<ApiResponse<WarehouseStock[]> | undefined> {
    return firstValueFrom(
      this.httpClient.get<ApiResponse<WarehouseStock[]>>(this.baseUrl + `v2.0/stockItems/${stockItemId}/balance`)
    ).catch(() => { return undefined; });
  }

  getStockItemsBalancesAsync(query: any): Promise<ApiResponse<WarehouseStock[]> | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<ApiResponse<WarehouseStock[]>>(this.baseUrl + 'v2.0/stockItems/balances?' + queryString)
    ).catch(() => { return undefined; });
  }

  async addStockItemsBalancesToLocalDb(warehouseStocks: WarehouseStock[]) {
    if (warehouseStocks && warehouseStocks.length > 0) {
      let stockItemIds: number[] = warehouseStocks.map(item => item.stock_item_id);
      stockItemIds = stockItemIds.filter((element, index) => {
        return stockItemIds.indexOf(element) === index;
      });
      for (let index = 0; index < stockItemIds.length; index++) {
        const stockItem = await this.getStockItemByIdLocalAsync(stockItemIds[index]);
        if (stockItem) {
          const stockItemBalances = warehouseStocks.filter(item => item.stock_item_id === stockItem.id);
          stockItem.warehouse_stocks = !stockItem.warehouse_stocks ? stockItemBalances :
            stockItemBalances.concat(
              stockItem.warehouse_stocks.filter(currentStock => !stockItemBalances.find(newStock => newStock.stock_item_id === currentStock.stock_item_id))
            );
          this.localDbProvider.db.stockItems.put(stockItem)
            .catch((err: any) => this.localDbProvider.handleErrors(err));
        }
      }
    }
  }

  async decreaseStockItemSerialsQuantities(itemSerials: string[]) {
    if (itemSerials && itemSerials.length > 0) {
      const localSerials = await this.localDbProvider.db.stockItemSerials
        .where('serial_number').anyOf(itemSerials)
        .toArray() as StockItemSerial[];

      if (!localSerials?.length)
        return;

      const serialsToRemove: StockItemSerial[] = [];
      itemSerials.forEach((serial) => {
        const stockItemSerial = localSerials.find(element => element.serial_number === serial);
        if (stockItemSerial && stockItemSerial.serial_count > 1)
          stockItemSerial.serial_count--;
        else if (stockItemSerial && stockItemSerial.serial_count <= 1) {
          serialsToRemove.push(stockItemSerial);
          const index = localSerials.findIndex(element => element.serial_number === serial);
          localSerials.splice(index, 1);
        }
      });

      this.localDbProvider.db.stockItemSerials.bulkPut(localSerials)
        .catch((err: any) => this.localDbProvider.handleErrors(err));

      this.localDbProvider.db.stockItemSerials
        .where('serial_number').anyOf(serialsToRemove.map(itm => itm.serial_number))
        .delete()
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  async getStockItemSerialFromLocalDb(serialNumber: string) {
    return this.localDbProvider.db.stockItemSerials
      .where('serial_number').equals(serialNumber)
      .first()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  deductOfflineSerials(offlineSalesOrders: SalesOrder[], stockItemSerials: StockItemSerial[]) {
    if (offlineSalesOrders?.length && stockItemSerials?.length) {
      offlineSalesOrders.forEach(async salesOrder => {
        const serialedOrderItems = salesOrder.salesOrder_details.filter(item => item.related_serials?.length > 0);
        const serials = ([] as string[]).concat(...serialedOrderItems.map(item => item.related_serials));

        if (serials.length) {
          serials.forEach(serial => {
            const stockItemSerial = stockItemSerials.find(element => element.serial_number === serial);
            if (stockItemSerial && stockItemSerial.serial_count > 1)
              stockItemSerial.serial_count--;
            else if (stockItemSerial && stockItemSerial.serial_count <= 1) {
              const index = stockItemSerials.findIndex(element => element.serial_number === serial);
              stockItemSerials.splice(index, 1);
            }
          });
        }
      });
    }
  }

  decreaseStockItemBatchsBalances(salesOrderItems: SalesOrderItem[], warehouseId: number) {
    if (salesOrderItems && salesOrderItems.length > 0 && warehouseId) {
      salesOrderItems.forEach(async element => {
        const localStockItem: StockItem = await this.localDbProvider.db.stockItems.get(element.stock_item_id);
        if (localStockItem) {
          const soldBatchs = element.batchDetails?.filter(batch => batch.quantity > 0) ?? [];
          soldBatchs.forEach(batch => {
            const warehouseStock = localStockItem.warehouse_stocks.find(stock => stock.batch_number === batch.batch_number && stock.warehouse_id === warehouseId);
            if (warehouseStock)
              warehouseStock.balance -= batch.quantity;
          });
          this.localDbProvider.db.stockItems.put(localStockItem)
            .catch((err: any) => this.localDbProvider.handleErrors(err));
        }
      });
    }
  }

  getStockItemsClassificationsAsync(): Promise<ApiResponse<StockItemClassification[]> | undefined> {
    return firstValueFrom(
      this.httpClient.get<ApiResponse<StockItemClassification[]>>(this.baseUrl + 'v2.0/stockItems/classifications')
    ).catch(() => { return undefined; });
  }

  async loadStockItemsCache(prioritizesScanningBarcode: PrioritizesScanningBarcodeEnum): Promise<void> {
    if (prioritizesScanningBarcode === PrioritizesScanningBarcodeEnum.normalOverWeighted) {
      const stockItems = await this.getAllStockItemsLocalAsync();
      stockItems.forEach(stockItem => {
        if (!!stockItem.part_number) {
          const items = stockItems.filter(item => item.part_number === stockItem.part_number);
          this.barcodeCache.set(stockItem.part_number, items);
        }
      });
    }
  }

  private async getAllStockItemsLocalAsync(): Promise<StockItem[]> {
    return this.localDbProvider.db.stockItems
      .where("active").equals(1)
      .toArray();
  }

  //#endregion

  //#region Warehouse

  async loadAllWarehouses(): Promise<ServiceResult<Warehouse[]>> {
    const query = {
      offset: 0,
      limit: 1000
    };
    const serviceResult = new ServiceResult<Warehouse[]>();
    const count = await this.countWarehousesLocalAsync();
    if (!this.onlineOfflineService.isOnline || count > 0) {
      query.limit = count;
      serviceResult.statusCode = 200;
      serviceResult.result = await this.getWarehousesLocalAsync(query);
      serviceResult.totalCount = count;
      return serviceResult;
    } else {
      let response: ApiResponse<Warehouse[]> | undefined;
      let warehouses: Warehouse[] = [];
      do {
        response = await this.getWarehousesAsync(query);
        if (response && response.status_code === 200) {
          query.offset += query.limit;
          warehouses = warehouses.concat(response.result);
          this.addWarehousesToLocalDb(response.result);
        }
      } while (response && response.status_code === 200 && response.total_count > query.offset);
      serviceResult.statusCode = response?.status_code;
      serviceResult.result = warehouses;
      serviceResult.statusMessage = response?.error_message;
      serviceResult.totalCount = response?.total_count;
      return serviceResult;
    }
  }

  private async countWarehousesLocalAsync(): Promise<number> {
    return this.localDbProvider.db.warehouses.count()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  private getWarehousesLocalAsync(query: any): Promise<Warehouse[]> {
    return this.localDbProvider.db.warehouses
      .offset(query.offset)
      .limit(query.limit)
      .toArray();
  }

  async getWarehousesAsync(query: any): Promise<ApiResponse<Warehouse[]> | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<ApiResponse<Warehouse[]>>(this.baseUrl + 'v2.0/warehouses?' + queryString)
    ).catch(() => { return undefined; });
  }

  addWarehousesToLocalDb(warehouses: Warehouse[]) {
    if (warehouses && warehouses.length > 0) {
      this.localDbProvider.db.warehouses.bulkPut(warehouses)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  async getWarehouseById(warehouseId: number): Promise<ServiceResult<Warehouse>> {
    const serviceResult = new ServiceResult<Warehouse>();
    const warehouse = await this.getWarehouseByIdLocalAsync(warehouseId);
    if (warehouse && warehouse.id > 0) {
      serviceResult.statusCode = 200;
      serviceResult.result = warehouse;
      return serviceResult;
    } else {
      const res = await this.getWarehouseByIdAsync(warehouseId);
      if (res) {
        this.addWarehouseToLocalDb(res.result);
        serviceResult.statusCode = res.status_code;
        serviceResult.result = res.result;
        serviceResult.statusMessage = res.error_message;
        serviceResult.totalCount = res.total_count;
      }
      return serviceResult;
    }
  }

  private getWarehouseByIdLocalAsync(warehouseId: number): Promise<Warehouse> {
    return this.localDbProvider.db.warehouses.get(warehouseId);
  }

  private getWarehouseByIdAsync(warehouseId: number): Promise<ApiResponse<Warehouse> | undefined> {
    return firstValueFrom(
      this.httpClient.get<ApiResponse<Warehouse>>(this.baseUrl + `v2.0/warehouses/${warehouseId}`)
    ).catch(() => { return undefined; });
  }

  private addWarehouseToLocalDb(warehouse: Warehouse) {
    if (warehouse) {
      this.localDbProvider.db.warehouses.put(warehouse)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  createWarehouse(warehouse: Warehouse) {
    return this.httpClient.post<ApiResponse<string>>(this.baseUrl + 'v2.0/warehouses', warehouse);
  }

  //#endregion

  //#region  Work Orders

  getWorkOrderOtherParties(query: any) {
    const queryString = new QueryStringHelper().toQueryString(query);
    return this.httpClient.get<ApiResponse<OtherParty[]>>(this.baseUrl + 'v2.0/workOrderOtherParties?' + queryString)
      .pipe(shareReplay(1));
  }

  //#endregion

}
