import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FeaturesConfigService } from '../../core/services/features-config.service';
import { OnlineOfflineService } from '../../core/services/online-offine.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[disableFeatureToggle]'
})
export class DisableFeaturesDirective implements OnInit, OnDestroy {
  @Input('disableFeatureToggle') disableFeatureToggle?: IDisableFeatureValues;
  // @Input('disableCssClass') disableCssClass: string;
  connectionChangedSubcription?: Subscription;
  constructor(private el: ElementRef,
    private renderer: Renderer2,
    private featuresFlagsConfigService: FeaturesConfigService,
    private onlineOfflineService: OnlineOfflineService) {
  }


  ngOnInit() {
    this.handleEnablement();
    this.connectionChangedSubcription = this.onlineOfflineService.connectionChanged.subscribe(() => this.handleEnablement());
  }

  ngOnDestroy(): void {
    this.connectionChangedSubcription?.unsubscribe();
  }

  private handleEnablement() {
    if (this.disableFeatureToggle && !this.featuresFlagsConfigService.isFeatureEnabled(this.disableFeatureToggle.feature)) {
      this.disableEelement();
    } else {
      this.enableEelement();
    }
  }

  private enableEelement() {
    this.el.nativeElement.disabled = false;
    if (this.disableFeatureToggle?.cssClass) {
      this.renderer.removeClass(this.el.nativeElement, this.disableFeatureToggle.cssClass);
    }
  }

  private disableEelement() {
    this.el.nativeElement.disabled = true;
    if (this.disableFeatureToggle?.cssClass) {
      this.renderer.addClass(this.el.nativeElement, this.disableFeatureToggle.cssClass);
    }
  }
}

export interface IDisableFeatureValues {
  feature: string;
  cssClass?: string;
}
