import { DiscountType } from "@shared/enums/discount-type";
import { DiscountDto } from "src/app/point-of-sale/models/discount";
import { ProductType, ProductUnitOfMeasure, ProductVariant } from "src/app/point-of-sale/models/product";
import { Product } from "src/app/point-of-sale/models/product";

export class SalesOrderItemV3 {
    id?: string;
    productId!: string;
    productName!: string;
    productSku!: string;
    productPartNumber!: string;
    productCost!: number;
    productType!: ProductType;
    productPrice!: number;
    unitOfMeasure?: ProductUnitOfMeasure;
    quantity: number;
    price!: number;
    total!: number;
    discount!: DiscountDto;
    refundedQuantity?: number;
    refundQuantityAvailable?: number;
    discountAmount?: number;
    discountAmountPerUnit?: number;
    taxRate?: number;
    productVariant?: ProductVariant;

    isSelected?: boolean;
    productUnitOfMeasures?: ProductUnitOfMeasure[];
    baseUom?: ProductUnitOfMeasure;
    extraAttributes: SalesOrderItemV3ExtraAttributes = {};

    constructor(product: Product | undefined, quantity: number, unitOfMeasure?: ProductUnitOfMeasure, variant?: ProductVariant) {
        if (product) {
            this.productId = product.id!;
            this.productName = product.name;
            this.productSku = product.sku;
            this.productPartNumber = product.partNumber;
            this.productCost = product.cost && !isNaN(product.cost) ? product.cost : 0;
            this.productType = product.type;
            this.productPrice = product.salesPrice && !isNaN(product.salesPrice) ? product.salesPrice : 0;
            this.price = product.salesPrice && !isNaN(product.salesPrice) ? product.salesPrice : 0;
            this.taxRate = product.tax?.rate ?? 0;
            this.extraAttributes.taxId = product.tax?.id;
        }

        if (variant) {
            this.productVariant = variant;
            this.productSku = variant.sku;
            this.productPartNumber = variant.partNumber;
            this.productPrice = variant.salesPrice && !isNaN(variant.salesPrice) ? variant.salesPrice : 0;
            this.price = variant.salesPrice && !isNaN(variant.salesPrice) ? variant.salesPrice : 0;
            this.taxRate = variant.tax?.rate ?? this.taxRate;
            this.extraAttributes.taxId = variant.tax?.id ?? this.extraAttributes.taxId;
        }

        if (unitOfMeasure) {
            this.productSku = unitOfMeasure.sku!;
            this.productPartNumber = unitOfMeasure.partNumber!;
            this.productPrice = unitOfMeasure.salesPrice && !isNaN(+unitOfMeasure.salesPrice) ? +unitOfMeasure.salesPrice : 0;
            this.price = unitOfMeasure.salesPrice && !isNaN(+unitOfMeasure.salesPrice) ? +unitOfMeasure.salesPrice : 0;
            this.unitOfMeasure = unitOfMeasure;
            this.productUnitOfMeasures = product?.unitOfMeasures;
            this.baseUom = product?.unitOfMeasures.find(uom => uom.baseUnit);
        }

        this.quantity = quantity;
        this.discount = {
            value: 0,
            type: DiscountType.Amount
        };
    }

    get unitOfMeasureId(): string {
        return this.unitOfMeasure?.id!;
    }

    get netPrice(): number {
        return this.price - this._discountValue;
    }

    get grossTotal(): number {
        return this.price * this.quantity;
    }

    set grossTotal(value) {
        this.price = this.quantity > 0 ? value / this.quantity : 0;
    }

    get taxRatePercentage(): number {

        if (!this.taxRate) return 0;

        return this.taxRate / 100;
    }

    private get _discountValue(): number {
        if (this.discount?.type === DiscountType.Amount) {
            return this.quantity ? this.discount.value / this.quantity : 0;
        } else if (this.discount?.type === DiscountType.Percentage) {
            return this.price * this.discount.value / 100;
        }
        return 0;
    }

    get netAmount(): number {
        return this.netPrice * this.quantity;
    }

    public static clone(salesOrderItem: SalesOrderItemV3): SalesOrderItemV3 {
        const newSalesOrderItem = new SalesOrderItemV3(undefined, 0);
        const clonedSalesOrderItem = Object.assign(newSalesOrderItem, salesOrderItem)
        return clonedSalesOrderItem;
    }

    public changeUOM(unitOfMeasure: ProductUnitOfMeasure) {
        this.productSku = unitOfMeasure.sku!;
        this.productPartNumber = unitOfMeasure.partNumber!;
        this.productPrice = unitOfMeasure.salesPrice!;
        this.price = unitOfMeasure.salesPrice!;
    }

    public get totalDiscount(): number {
        if (this.discount?.type === DiscountType.Amount) {
            return this.discount.value;
        } else if (this.discount?.type === DiscountType.Percentage) {
            return this.price * this.quantity * this.discount.value / 100;
        }
        return 0;
    }

    public validatePercentageDiscount() {
        if (this.discount?.type === DiscountType.Percentage && this.discount.value > 100) {
            this.discount.value = 0;
        }
    }

    public minify() {
        delete this.isSelected;
        delete this.productUnitOfMeasures;
        delete this.baseUom;

        return this;
    }
}

export class SalesOrderItemV3ExtraAttributes {
    serial?: number;
    taxId?: string;
    [key: string]: any
}
