export class SalesBundle {
    id?: number;
    description!: string;
    bundle_details: SalesBundleItem[] = [];
    description_keywords: string[] = [];

    public static clone(salesBundle: SalesBundle): SalesBundle {
        return Object.assign(new SalesBundle(), salesBundle);
    }

    public get totalPrice(): number {
        let price = 0;
        for (let index = 0; index < this.bundle_details.length; index++) {
            const item = this.bundle_details[index];
            price += item.price * item.quantity;
        }
        return price;
    }

    public get taxRate(): number {
        return this.totalTax / this.totalPrice;
    }

    private get totalTax(): number {
        let totalTax = 0;
        for (let index = 0; index < this.bundle_details.length; index++) {
            const item = this.bundle_details[index];
            if (item.tax_rate > 0) {
                totalTax += item.price * item.quantity * item.tax_rate;
            }
        }
        return totalTax;
    }
}

export interface SalesBundleItem {
    id: number;
    description: string;
    quantity: number;
    unit_price: number;
    price: number;
    tax_rate: number;
    tax_id: number;
    stock_item_id: number;
    stock_item_code: string;
    stock_item_description: string;
    unit_of_measure_id: number;
    dimensions_value: number;
    dimensions: string;
    comments: string;
}
