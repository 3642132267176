import { Country, State } from "@shared/models/customer-v3";

export class ShippingAddressDto {
    id?: string;
    country!: Country;
    state!: State;
    district!: string;
    street?: string;
    phone?: string;

    description?: string;

    public static clone(shippingAddress: ShippingAddressDto): ShippingAddressDto {
        const newShippingAddressObj = Object.assign(new ShippingAddressDto(), shippingAddress);
        const street = newShippingAddressObj.street ? `${newShippingAddressObj.street}, ` : '';
        newShippingAddressObj.description = `${street}${newShippingAddressObj.district}, ${newShippingAddressObj.state.name}, ${newShippingAddressObj.country.name}`;
        return newShippingAddressObj;
    }
}