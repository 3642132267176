import { Injectable } from '@angular/core';
import { ErrorType } from '../models/api-response';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorMessageFormatter {
    constructor(private translateService: TranslateService) {
    }

    getErrorMessage(error: any, generalMsgKey: string): string {
        let descriptiveError = `${this.translateService.instant(generalMsgKey)}`;
        if (error) {
            if (typeof error === 'string') {
                descriptiveError = this.getDescriptiveErrorByEdaraErrorCodesFromat(error);
            } else if (Array.isArray(error)) {
                descriptiveError = this.getDescriptiveErrorByEdaraErrorCodesFromat(error[0]) || error[0];
            }
            else if (typeof error === 'object' && !!error.errors) {
                descriptiveError = this.getDescriptiveErrorByValidationErrorFromat(error.errors) || this.getDescriptiveErrorFromKeyValueObj(error.errors);
            }
            else if (typeof error === 'object' && !!error.detail) {
                descriptiveError = error.detail;
            }
        }
        return descriptiveError || `${this.translateService.instant(generalMsgKey)}`;
    }

    private getDescriptiveErrorByEdaraErrorCodesFromat(error: string): string {
        const errorParts = error.split('.');
        if (errorParts.length === 1)
            return '';

        const errorType = errorParts[0];
        if (errorType !== ErrorType.BadRequest)
            return '';

        const errorCode = errorParts[1].substring(0, errorParts[1].indexOf(':')).trim();
        const errorDescription = errorParts[1].substring(errorParts[1].indexOf(':') + 1);
        if (errorCode === 'EdaraBusinessError')
            return `${errorDescription}`;

        if (`${this.translateService.instant('DataErrorCodes.' + errorCode)}` === `DataErrorCodes.${errorCode}`)
            return '';

        return `${this.translateService.instant('DataErrorCodes.' + errorCode, { details: errorDescription })}`;
    }

    private getDescriptiveErrorByValidationErrorFromat(errorObj: object): string {
        const errorKey = Object.keys(errorObj)[0];
        if (!errorKey)
            return '';

        const errorValue = Object.values(errorObj)[0][0];
        if (!errorValue)
            return '';

        const errorParts = errorValue.split(':');
        if (errorParts.length === 1)
          return '';

        const translationKey = errorKey.trim() + errorParts[0].trim();

        if (`${this.translateService.instant('DataErrorCodes.' + translationKey)}` === `DataErrorCodes.${translationKey}`)
            return '';

        return `${this.translateService.instant('DataErrorCodes.' + translationKey)}`;
    }

    private getDescriptiveErrorFromKeyValueObj(errorObj: object): string {
        const errorValues = Object.values(errorObj)[0][0].split(':');
        return (`${this.translateService.instant('DataErrorCodes.' + Object.keys(errorObj)[0])}` === `DataErrorCodes.${Object.keys(errorObj)[0]}` ?
            errorValues[errorValues.length - 1] :
            `${this.translateService.instant('DataErrorCodes.' + Object.keys(errorObj)[0])}`);
    }
}
