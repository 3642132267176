import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  @Input() name: string = '';
  @Input() fill: '1' | '0' = '0';
  @Input() wght: '100' | '200' | '300' | '400' | '500' | '600' | '700' = '400';
  @Input() grad: '-25' | '0' | '200' = '0';
  @Input() opsz: '20' | '24' | '40' | '48' = '48';
  @Input() fontSize: '18' | '24' | '32' | '48' | '64' = '24';
  @Input() color: string = '';
  @Input() badge: string = '';
  @Input() width: string = '24';
  @Input() height: string = '24';
  @Input() svgFill: string = '';
  @Input() svgStroke: string = '';
  @Input() isSvg: boolean = false;

}
