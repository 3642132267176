import { Component } from '@angular/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';
import { ILoadingOverlayParams } from 'ag-grid-community';
@Component({
  selector: 'app-ag-loading-overlay',
  templateUrl: './ag-loading-overlay.component.html',
  styleUrls: ['./ag-loading-overlay.component.scss']
})
export class AgLoadingOverlayComponent implements ILoadingOverlayAngularComp {
  public params!: ILoadingOverlayParams & { loadingMessage: string };

  agInit(params: ILoadingOverlayParams & { loadingMessage: string }): void {
    this.params = params;
  }
}
