import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'findItem'
})
export class FindItemInArrayPipe implements PipeTransform {
    transform(id: any, arry: any[] | null | undefined, key?: string | null | undefined): any {
        if (id && arry) return arry.find((el) => el?.[key || 'id'] === id);
    }
}
