import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DexieDbProvider, PagedList, QueryStringHelper } from '@shared';
import { AppSettingsService } from './app-settings.service';
import { AssetLocation, AssetType, AssetUnitOfMeasure } from '@shared/models/service-worker-core.model';
import { OnlineOfflineService } from './online-offine.service';
import { PosUnitOfMeasure } from 'src/app/point-of-sale/models/pos-unit-of-measure';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerCoreService {
  edaraCoreBaseUrl = '';

  constructor(private httpClient: HttpClient,
    private localDbProvider: DexieDbProvider, private onlineOfflineService: OnlineOfflineService) {
    this.edaraCoreBaseUrl = AppSettingsService.appSettings.edaraCoreApi.baseUrl;

    if (this.edaraCoreBaseUrl.endsWith('/')) {
      this.edaraCoreBaseUrl = this.edaraCoreBaseUrl.slice(0, -1);
    }
  }

  findUnitOfMeasuresAsync(query: any): Promise<PagedList<AssetUnitOfMeasure[]> | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<PagedList<AssetUnitOfMeasure[]>>(this.edaraCoreBaseUrl + 'api/UnitOfMeasures/find?' + queryString)
    ).catch(() => { return undefined; });
  }

  addUnitOfMeasuresToLocalDb(assetUnitOfMeasure: AssetUnitOfMeasure[]) {
    if (assetUnitOfMeasure && assetUnitOfMeasure.length > 0) {
      this.localDbProvider.db.FA_UnitOfMeasures.bulkPut(assetUnitOfMeasure)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  findPosUnitOfMeasuresAsync(query: any): Promise<PagedList<PosUnitOfMeasure[]> | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<PagedList<PosUnitOfMeasure[]>>(this.edaraCoreBaseUrl + 'api/pos/UnitOfMeasures/find?' + queryString)
    ).catch(() => { return undefined; });
  }

  addPosUnitOfMeasuresToLocalDb(posUnitOfMeasure: PosUnitOfMeasure[]) {
    if (posUnitOfMeasure && posUnitOfMeasure.length > 0) {
      this.localDbProvider.db.POS_UnitOfMeasures.bulkPut(posUnitOfMeasure)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  findAssetLocationsAsync(query: any): Promise<PagedList<AssetLocation[]> | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<PagedList<AssetLocation[]>>(this.edaraCoreBaseUrl + 'api/AssetLocations/find?' + queryString)
    ).catch(() => { return undefined; });
  }

  addAssetLocationsToLocalDb(assetLocation: AssetLocation[]) {
    if (assetLocation && assetLocation.length > 0) {
      this.localDbProvider.db.FA_AssetLocations.bulkPut(assetLocation)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  findAssetTypessAsync(query: any): Promise<PagedList<AssetType[]> | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<PagedList<AssetType[]>>(this.edaraCoreBaseUrl + 'api/AssetTypes/find?' + queryString)
    ).catch(() => { return undefined; });
  }

  addAssetsTypesToLocalDb(assetTypes: AssetType[]) {
    if (assetTypes && assetTypes.length > 0) {
      this.localDbProvider.db.FA_AssetTypes.bulkPut(assetTypes)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  async loadAllAssetLocations(): Promise<AssetLocation[]> {
    let response: AssetLocation[];
    const count = await this.countAllAssetLocationsLocalAsync();
    if (!this.onlineOfflineService.isOnline || count > 0) {
      response = await this.getAssetLocationsLocalAsync();
      return response;
    } else {
      response = await this.getAssetLocationsAsync() ?? [];
      this.addAssetLocationsToLocalDb(response);
      return response;
    }
  }

  getAssetLocationsAsync(): Promise<AssetLocation[] | undefined> {
    return firstValueFrom(
      this.httpClient.get<AssetLocation[]>(this.edaraCoreBaseUrl + 'api/AssetLocations')
    ).catch(() => { return undefined; });
  }

  private async getAssetLocationsLocalAsync(): Promise<AssetLocation[]> {
    return this.localDbProvider.db.FA_AssetLocations.toArray();
  }

  private countAllAssetLocationsLocalAsync(): Promise<number> {
    return this.localDbProvider.db.FA_AssetLocations.count()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

}
