import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MoneyHelper {

  round(value: number, noOfDecimals: number = 2) {
    if (!value) {
      return 0;
    }
    const roundingFactor = Math.pow(10, noOfDecimals);
    // return Math.round(value * roundingFactor) / roundingFactor;
    const scaledValue = Math.abs(value * roundingFactor);

    // (70.725 * 100) % 1 =            0.4999999999990905
    // (70.87499999999999 * 100) % 1 = 0.499999999998181
    let roundedValue = 0;
    if (scaledValue % 1 >= 0.49999999999)
      roundedValue = Math.ceil(scaledValue) / roundingFactor;
    else
      roundedValue = Math.floor(scaledValue) / roundingFactor;

    return value > 0 ? roundedValue : (roundedValue * -1);
  }

}
