import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'moneyRounding',
})
export class MoneyRounding implements PipeTransform {
  async transform(value: number) {
    const noOfDecimals = 2;
    const roundingFactor = Math.pow(10, noOfDecimals);
    return Math.round(value * roundingFactor) / roundingFactor;
  }
}
