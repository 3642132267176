<ul class="quick-actions">
  <li class="quick-actions__item">
    <button mat-mini-fab class="button--surface">
      <icon name="home"></icon>
    </button>
  </li>
  <li class="quick-actions__item">
    <button mat-mini-fab class="button--surface">
      <icon name="home"></icon>
    </button>
  </li>
  <li class="quick-actions__item">
    <button mat-mini-fab class="button--surface">
      <icon name="home"></icon>
    </button>
  </li>
  <li class="quick-actions__item">
    <button mat-mini-fab class="button--surface">
      <icon name="home"></icon>
    </button>
  </li>
</ul>
