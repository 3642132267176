import { PrintTemplateType } from "@shared/enums/print-template-type";
import { PosLocation } from "src/app/point-of-sale/models/pos-location";
import { AccountsTypes } from "./account";
import { CashTransactionLog } from "./cash-transaction";
import { CustomerV3 } from "./customer-v3";
import { INativeServiceSyncable } from "./native-service-syncable";
import { PaymentType } from "./payment-type";
import { PrintTemplateV3 } from "./print-template-v3";
import { OrderLayout } from "src/app/point-of-sale/enums/order-layout";
import { CardLayout } from "src/app/point-of-sale/models/product-card-layout.model";

export class CashRegister {
    id!: string;
    code!: string;
    name!: string;
    device_id?: string;
    activated_user?: string;
    allow_open_close!: boolean;
    location?: PosLocation;
    customer?: CustomerV3;
    status?: CashRegisterStatus;
    opening_details?: CashRegisterOpening;
    closing_details?: CashRegisterClosing;
    defaultPrintTemplate?: PrintTemplateType;
    printTemplates: PrintTemplateV3[] = [];
    paymentTypes: CashRegisterPaymentType[] = [];
    orderLayout: OrderLayout = OrderLayout.Cards;
    cardLayout?: CardLayout;
    isSelected: boolean = false;
    active: boolean = true;

    public static clone(cashRegister: CashRegister): CashRegister | undefined {
        if (!cashRegister)
            return undefined;

        const newCashRegister: CashRegister = Object.assign(new CashRegister(), cashRegister);
        newCashRegister.closing_details = CashRegisterClosing.clone(cashRegister.closing_details);
        return newCashRegister;
    }

    public setDefaultPaymentType(paymentType: PaymentType) {
        this.clearDefaultPaymentType();

        if (paymentType) {
            const newDefaultPaymentType = this.paymentTypes?.find(itm => itm.paymentType.id === paymentType.id);
            if (newDefaultPaymentType) {
                newDefaultPaymentType.isDefault = true;
            }
        }
    }

    public clearDefaultPaymentType() {
        const defaultPaymentType = this.paymentTypes?.find(itm => itm.isDefault);
        if (defaultPaymentType) {
            defaultPaymentType.isDefault = false;
        }
    }
}

export interface DevicePairing {
    id: string;
    device_id: string;
}

export enum CashRegisterStatus {
    Open = 1,
    Closed = 2
}

export class CashRegisterOpening {
    id?: string;
    opening_amount!: number;
    opening_date!: Date;
    opened_by!: string;
    syncMetadata?: INativeServiceSyncable;
}

export class CashRegisterClosing {
    id?: string;
    code!: string;
    closing_amount!: number;
    expected_cash_payments!: CashPaymentBreakdown;
    actual_cash_payments!: CashPaymentBreakdown;
    closing_date!: Date;
    closed_by!: string;
    syncMetadata?: INativeServiceSyncable;

    public static clone(cashRegisterClosing: CashRegisterClosing | undefined): CashRegisterClosing | undefined {
        if (!cashRegisterClosing)
            return undefined;

        const newCashRegisterClosing = Object.assign(new CashRegisterClosing(), cashRegisterClosing);
        newCashRegisterClosing.expected_cash_payments = Object.assign(new CashPaymentBreakdown(), cashRegisterClosing.expected_cash_payments);
        newCashRegisterClosing.actual_cash_payments = Object.assign(new CashPaymentBreakdown(), cashRegisterClosing.actual_cash_payments);
        return newCashRegisterClosing;
    }
}

export class CashPaymentBreakdown {
    cash_sales_payments: CashPayment[] = [];
    on_account_amount?: number = 0;
    cash_in_amount?: number = 0;
    cash_out_amount?: number = 0;

    get cashSales(): number {
        const cashSales = this.cash_sales_payments.filter(element => element.payment_type_name === AccountsTypes[AccountsTypes.Cash]);
        if (!cashSales.length) return 0;

        return cashSales.map(item => item.amount)
            .reduce((a, b) => a + b);
    }

    get bankSales(): number {
        const bankSales = this.cash_sales_payments.filter(element => element.payment_type_name === AccountsTypes[AccountsTypes.Bank] || element.payment_type_name === 'Card');
        if (!bankSales.length) return 0;

        return bankSales.map(item => item.amount)
            .reduce((a, b) => a + b);
    }

    get totalCashSalesPayments(): number {
        if (!this.cash_sales_payments?.length)
            return 0;

        return this.cash_sales_payments.map(item => item.amount).reduce((a, b) => a + b, 0);
    }

    get totalAmounts(): number {
        return this.totalCashSalesPayments +
            (this.cash_in_amount ?? 0) -
            (this.cash_out_amount ?? 0);
    }
}

export class CashPayment {
    account_id?: number;
    account_name?: string;
    account_type?: string;
    payment_type_name?: string;
    amount: number = 0;
}

export class SyncCashRegisterUpdates {
    id?: string;
    cashRegisterStatusLogs: CashRegisterStatusLog[] = [];
    cashTransactionLogs: CashTransactionLog[] = [];
}

export class CashRegisterStatusLog {
    status!: CashRegisterStatus;
    changeDate!: Date;
    changedBy!: string;
    openingDetails?: CashRegisterOpening;
    closingDetails?: CashRegisterClosing;
}

export class CashRegisterPaymentType {
    id?: string;
    paymentType!: PaymentType;
    isDefault?: boolean;
}
