import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AllowedBrowser, AllowedOS } from 'src/app/shared/models/app-settings';
import { AppSettingsService } from './app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class SupportOfflineModeService {

  private osObj?: AllowedOS;
  private browserObj?: AllowedBrowser;

  constructor(private deviceDetectorService: DeviceDetectorService,
    private translateService: TranslateService) {
    this.osObj = AppSettingsService.appSettings.offlineMode.allowedOS.find(item => item.os === this.deviceDetectorService.os);
    this.browserObj = AppSettingsService.appSettings.offlineMode.allowedBrowsers.find(item => item.browser === this.deviceDetectorService.browser);
  }

  isDeviceAllowed(): boolean {
    return !!this.osObj && (this.osObj.minVersion === 'any' || this.osObj.minVersion === this.deviceDetectorService.os_version) &&
      !!this.browserObj && this.browserObj.minVersion <= +this.deviceDetectorService.browser_version.split('.')[0];
  }

  handleWarningMessage(): string {
    return this.browserObj ? this.translateService.instant('alerts.supportOfflineModeSpecificWarning', { browser: this.browserObj.browser, browserMinVersion: this.browserObj.minVersion }) :
      this.translateService.instant('alerts.supportOfflineModeGeneralWarning');
  }
}