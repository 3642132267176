import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@auth';

@Pipe({
  name: 'hasPermission'
})
export class HasPermissionPipe implements PipeTransform {
constructor(private authService: AuthService) {}
  transform(permissionName: string): boolean {
      if (this.authService.userPermissions) {
        return this.authService.userPermissions.indexOf(permissionName) > -1;
      }
      return false;
  }

}
