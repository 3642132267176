import { EventEmitter, Injectable } from '@angular/core';
import {
  Firestore,
  Timestamp,
  waitForPendingWrites,
  enableNetwork,
  disableNetwork,
  DocumentReference,
  doc,
  docSnapshots,
  CollectionReference,
  collection,
  collectionChanges,
  Query,
  docData,
  collectionData,
  addDoc,
  getDoc,
  getDocs,
  DocumentSnapshot,
  DocumentChange
} from '@angular/fire/firestore';
import { Observable, firstValueFrom } from 'rxjs';
import { map, first, distinctUntilChanged, takeUntil, filter } from 'rxjs/operators';
import { Auth, authState, idToken, signInWithCustomToken, signOut, User } from '@angular/fire/auth';
import { isEqual } from 'lodash';

type DocPredicate<T> = string | DocumentReference<T>;
type CollectionPredicate<T> = string | CollectionReference<T>;

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  loggedIn: EventEmitter<User> = new EventEmitter();
  loggedOut: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private angularFirestore: Firestore,
    private angularFireAuth: Auth) { }

  _customToken: string = '';
  async signIn(token: string) {
    this._customToken = token;
    await signInWithCustomToken(this.angularFireAuth, token);
  }

  async signOut() {
    const currentUser = await this.isLoggedIn();
    if (currentUser) {
      signOut(this.angularFireAuth).then(() => this.loggedOut.emit(true));
    }
  }

  isLoggedIn(): Promise<User | null> {
    return firstValueFrom(authState(this.angularFireAuth).pipe(first()));
  }

  getIdToken(): Promise<string | null> {
    return firstValueFrom(idToken(this.angularFireAuth));
  }

  serverDateTime() {
    let dateTimeNow = Timestamp.now().toDate();
    return dateTimeNow ? dateTimeNow : new Date();
  }

  async waitForPendingWrites() {
    return waitForPendingWrites(this.angularFirestore);
  }

  async enableNetwork() {
    enableNetwork(this.angularFirestore);
  }

  async disableNetwork() {
    disableNetwork(this.angularFirestore);
  }

  doc<T>(ref: DocPredicate<T>): DocumentReference<T> {
    return typeof ref === 'string' ? doc(this.angularFirestore, ref) as DocumentReference<T> : ref;
  }

  docSnapshots$<T>(ref: DocPredicate<T>): Observable<T> {
    return docSnapshots<T>(this.doc(ref)).pipe(map((doc: DocumentSnapshot<T>) => {
      return doc.data() as T;
    }))
      .pipe(distinctUntilChanged((prev, curr) => isEqual(prev, curr)), takeUntil(this.loggedOut));
  }

  async getDocSnapshotAsync<T>(ref: DocPredicate<T>): Promise<DocumentSnapshot<T>> {
    return await getDoc(this.doc(ref));
  }

  async getDocValueAsync<T>(ref: DocPredicate<T>): Promise<T | undefined> {
    const docSnapshot = await getDoc(this.doc(ref));
    const data = docSnapshot.data();
    return data;
  }

  docValueChanges$<T>(ref: DocPredicate<T>): Observable<T | undefined> {
    return docData<T | undefined>(this.doc(ref))
      .pipe(
        filter(data => data !== undefined),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        takeUntil(this.loggedOut)
      );
  }

  col<T>(ref: CollectionPredicate<T>): CollectionReference<T> {
    return typeof ref === 'string' ? collection(this.angularFirestore, ref) as CollectionReference<T> : ref;
  }

  colSnapshots$<T>(queryRef: Query<T>): Observable<T[]> {
    return collectionChanges<T>(queryRef).pipe(map((docs: DocumentChange<T>[]) => {
      return docs.map(a => a.doc.data()) as T[];
    }))
      .pipe(distinctUntilChanged((prev, curr) => isEqual(prev, curr)), takeUntil(this.loggedOut));
  }

  async getCollectionWithIdsAsync<T>(queryRef: Query<T>): Promise<T[]> {
    return (await getDocs(queryRef)).docs.map(doc => {
      return { ...doc.data(), doc_id: doc.id };
    });
  }


  async getCollectionAsync<T>(queryRef: Query<T>): Promise<T[]> {
    return (await getDocs(queryRef)).docs.map(doc => {
      return doc.data();
    });
  }

  colValueChanges$<T>(queryRef: Query<T>): Observable<T[]> {
    return collectionData<T>(queryRef)
      .pipe(distinctUntilChanged((prev, curr) => isEqual(prev, curr)), takeUntil(this.loggedOut));
  }

  async addDoc<T>(reference: CollectionReference<T>, data: T): Promise<DocumentReference<T>> {
    const isLoggedIn = await this.isLoggedIn();
    if (!isLoggedIn && this._customToken)
      await signInWithCustomToken(this.angularFireAuth, this._customToken);

    return addDoc(reference, data);
  }

  async getCollectionWithDocIdAsync<T>(queryRef: Query<T>): Promise<T[]> {
    return (await getDocs(queryRef)).docs.map(doc => {
      return { ...doc.data(), docId: doc.id };
    });
  }

}
