export class SalesOrderNumber {
    format: string;
    prefix: string;
    cash_register_code: string;
    serial: number;

    elementFactory: { [elementType: string]: () => string; } = {
        'Prefix': () => this.prefix,
        'YYYY': () => new Date().getFullYear().toString(),
        'YY': () => new Date().getFullYear().toString().substr(-2),
        'MM': () => ('0' + (new Date().getMonth() + 1)).slice(-2),
        'Aug': () => this.monthShortNames[new Date().getMonth()],
        '#': () => '#' + this.serial.toString(),
        'CashRegisterCode': () => this.cash_register_code,
    };
    monthShortNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    constructor(format: string, prefix: string, cashRegisterCode: string, serial: number) {
        this.format = format;
        this.prefix = prefix;
        this.cash_register_code = cashRegisterCode;
        this.serial = serial;
    }

    // public static test() {
    //     console.log((new SalesOrderNumber('Prefix_YYYY_MM_#', 'SO', 'CA01', 1).toString()));
    //     console.log((new SalesOrderNumber('Prefix_YY_MM_#', 'SO', 'CA01', 1).toString()));
    //     console.log((new SalesOrderNumber('Prefix_YYYY_Aug_#', 'SO', 'CA01', 1).toString()));
    //     console.log((new SalesOrderNumber('Prefix_YY_Aug_#', 'SO', 'CA01', 1).toString()));
    //     console.log((new SalesOrderNumber('Prefix_YYYY_#', 'SO', 'CA01', 1).toString()));
    //     console.log((new SalesOrderNumber('Prefix_YY_#', 'SO', 'CA01', 1).toString()));
    //     console.log((new SalesOrderNumber('Prefix_MM_#', 'SO', 'CA01', 1).toString()));
    //     console.log((new SalesOrderNumber('Prefix_Aug_#', 'SO', 'CA01', 1).toString()));
    //     console.log((new SalesOrderNumber('Prefix_#', 'SO', 'CA01', 1).toString()));
    //     console.log((new SalesOrderNumber('#', 'SO', 'CA01', 1).toString()));
    //     console.log((new SalesOrderNumber('CashRegisterCode_#', 'SO', 'CA01', 1).toString()));
    // }

    public toString() {
        return this.format.split('_').map((element) => {
            if (!this.elementFactory[element]) {
                return '';
            }
            return this.elementFactory[element]();
        }).join('-');
    }

}

export enum SalesOrderNumberResetPeriod {
    NoReset = 'NoReset',
    Monthly = 'Monthly',
    Yearly = 'Yearly'
}