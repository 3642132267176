<div class="dialog__height">
    <mat-dialog-actions class="justify-content-end bg-light">
        <button mat-flat-button type="button" id="btn_print" color="primary" (click)="onShowPrintDialog()">
            {{'buttons.print' | translate }}
        </button>
        <div class="margin-end-auto"></div>
        <button mat-icon-button type="button" id="btn_close" mat-dialog-close>
            <icon name="close"></icon>
        </button>
    </mat-dialog-actions>
    <br />
    <mat-dialog-content class="padding-top-m">
        <div *ngIf="purchaseInvoice">
            <div id="invoiceHeader">
                <!-- Header -->
                <table class="table-bordered width-35" id="table_invoicingPurchaseInvoiceHeader">
                    <tbody id="table_body_purchaseInvoiceHeader">
                        <tr>
                            <td class="cell-title font-bold">
                                {{ "invoicing.purchaseInvoice.print.header.purchaseInvoice" | translate }}
                            </td>
                            <td id="table_cell_code">
                                {{ purchaseInvoice.code?.fullCode }}
                            </td>
                        </tr>
                        <tr>
                            <td class="cell-title font-bold">
                                {{ "invoicing.purchaseInvoice.print.header.supplier" | translate }}
                            </td>
                            <td id="table_cell_supplier">
                                {{ purchaseInvoice.supplier?.name }}
                            </td>
                        </tr>
                        <tr>
                            <td class="cell-title font-bold">
                                {{ "invoicing.purchaseInvoice.print.header.supplierTaxId" | translate }}
                            </td>
                            <td id="table_cell_taxId">
                                {{ purchaseInvoice.supplier?.taxId }}
                            </td>
                        </tr>
                        <tr>
                            <td class="cell-title font-bold">
                                {{ "invoicing.purchaseInvoice.print.header.invoiceDate" | translate }}
                            </td>
                            <td id="table_cell_invoiceDate">
                                {{ purchaseInvoice.invoiceDate | date: 'dd/MM/yyyy HH:mm:ss' }}
                            </td>
                        </tr>
                        <tr>
                            <td class="cell-title font-bold">
                                {{ "invoicing.purchaseInvoice.print.header.dueDate" | translate }}
                            </td>
                            <td id="table_cell_dueDate">
                                {{ purchaseInvoice.dueDate | date: 'dd/MM/yyyy HH:mm:ss' }}
                            </td>
                        </tr>
                        <tr>
                            <td class="cell-title font-bold">
                                {{ "invoicing.purchaseInvoice.print.header.location" | translate }}
                            </td>
                            <td id="table_cell_location">
                                {{ purchaseInvoice.location?.name }}
                            </td>
                        </tr>
                        <tr>
                            <td class="cell-title font-bold">
                                {{ "invoicing.purchaseInvoice.print.header.referenceNo" | translate }}
                            </td>
                            <td id="table_cell_referenceNo">
                                {{ purchaseInvoice.referenceNo }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- #endregion Header -->
            </div>
            <br /><br />
            <div id="invoiceDetails">
                <!-- Details -->
                <table class="table-bordered" id="table_invoicingPurchaseInvoiceLines">
                    <thead id="table_head_purchaseInvoiceLines">
                        <tr id="table_header_purchaseInvoiceLines">
                            <td class="font-bold">
                                {{ "invoicing.purchaseInvoice.print.details.product" | translate }}
                            </td>
                            <td class="font-bold">
                                {{ "invoicing.purchaseInvoice.print.details.description" | translate }}
                            </td>
                            <td class="font-bold">
                                {{ "invoicing.purchaseInvoice.print.details.quantity" | translate }}
                            </td>
                            <td class="font-bold">
                                {{ "invoicing.purchaseInvoice.print.details.price" | translate }}
                            </td>
                            <td colspan="2" class="font-bold">
                                {{ "invoicing.purchaseInvoice.print.details.lineDiscount" | translate }}
                            </td>
                            <td colspan="2" class="font-bold">
                                {{ "invoicing.purchaseInvoice.print.details.taxRate" | translate }}
                            </td>
                            <td class="font-bold">
                                {{ "invoicing.purchaseInvoice.print.details.lineTotal" | translate }}
                            </td>
                        </tr>
                    </thead>
                    <tbody class="PrintTableB" id="table_body_purchaseInvoiceLines">
                        <tr class="border-out" id="table_row{{i}}"
                            *ngFor="let lineItem of purchaseInvoice.lineItems; let i = index;">
                            <td id="table_row{{i}}_productName">
                                {{ lineItem.productName }}
                            </td>
                            <td id="table_row{{i}}_productDescription">
                                {{ lineItem.productDescription }}
                            </td>
                            <td class="text-align-end" id="table_row{{i}}_quantity">
                                {{ lineItem.quantity | quantityFormatterV3 }}
                            </td>
                            <td class="text-align-end" id="table_row{{i}}_price">
                                {{ lineItem.price | currencyFormatterV3 }}
                            </td>
                            <td colspan="1" class="text-align-end border-r-dashed" id="table_row{{i}}_discountRate">
                                {{
                                (lineItem.getDiscountValue / (lineItem.quantity * lineItem.price)) * 100
                                | currencyFormatterV3
                                }}
                                %
                            </td>
                            <td colspan="1" class="text-align-end border-l-dashed" id="table_row{{i}}_discountValue">
                                {{ lineItem.getDiscountValue | currencyFormatterV3 }}
                            </td>
                            <td colspan="1" class="text-align-end border-r-dashed" id="table_row{{i}}_taxRate">
                                {{ lineItem.taxRate | currencyFormatterV3 }} %
                            </td>
                            <td colspan="1" class="text-align-end border-l-dashed" id="table_row{{i}}_taxValue">
                                {{ lineItem.getTax | currencyFormatterV3 }}
                            </td>
                            <td class="text-align-end" id="table_row{{i}}_netTotal">
                                {{ lineItem.getNetTotal | currencyFormatterV3 }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- #endregion Details -->
            </div>
            <br /><br />
            <div id="invoiceTotals">
                <table class="table-bordered width-35" id="table_invoicingPurchaseInvoiceTotals">
                    <tbody id="table_body_purchaseInvoiceTotals">
                        <tr>
                            <td class="cell-title font-bold">
                                {{ "invoicing.purchaseInvoice.print.totals.grossTotal" | translate }}
                            </td>
                            <td class="text-align-end" id="table_cell_grossTotal">
                                {{ purchaseInvoice.getGrossTotal | currencyFormatterV3 }}
                            </td>
                        </tr>
                        <tr>
                            <td class="cell-title">
                                {{ "invoicing.purchaseInvoice.print.totals.itemsDiscountsTotal" | translate }}
                            </td>
                            <td class="text-align-end" id="table_cell_discount">
                                <span *ngIf="purchaseInvoice.getLineDiscount > 0">-</span>
                                <span>
                                    {{ purchaseInvoice.getLineDiscount | currencyFormatterV3 }}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td class="cell-title font-bold">
                                {{ "invoicing.purchaseInvoice.print.totals.subTotal" | translate }}
                            </td>
                            <td class="text-align-end" id="table_cell_subTotal">
                                {{ purchaseInvoice.getSubtotal | currencyFormatterV3 }}
                            </td>
                        </tr>
                        <tr>
                            <td class="cell-title">
                                {{ "invoicing.purchaseInvoice.print.totals.tax" | translate }}
                            </td>
                            <td class="text-align-end" id="table_cell_tax">
                                <span *ngIf="purchaseInvoice.getTax > 0">+</span>
                                <span>
                                    {{ purchaseInvoice.getTax | currencyFormatterV3 }}
                                </span>
                            </td>
                        </tr>
                        <tr class="bg-light">
                            <td class="cell-title font-bold">
                                {{ "invoicing.purchaseInvoice.print.totals.netTotal" | translate }}
                            </td>
                            <td id="table_cell_netTotal">
                                <app-currency-container [amount]="purchaseInvoice.netTotal"
                                    [currencyCode]="purchaseInvoice.currency?.international_code"
                                    displayCodeBeforAmount>
                                </app-currency-container>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </mat-dialog-content>
</div>