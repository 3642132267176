import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '@auth';

export const permissionsGuard: CanActivateFn = (route) => {
    const authService = inject(AuthService);
    const permissions = route.data['permissions'];
    if (permissions && authService.isAuthenticated()) {
        return authService.hasPermissions(permissions);
    }
    return false;
};