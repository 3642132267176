<div class="user-profile">
    <div class="user-profile__avatar">
        <div class="font-medium padding-base text-capitalize">
            <span>{{ userInitals }}</span>
        </div>
    </div>
    <div class="user-profile__account">
        <span class="user-profile__account-name">{{ userProfile?.nickname }}</span>
        <span class="user-profile__account-organization">{{ currentOrganization?.name }}</span>
    </div>
</div>