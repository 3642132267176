import { TranslateService } from "@ngx-translate/core";
import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'agoDate'
})
export class AgoDatePipe implements PipeTransform {

  constructor(private translateService: TranslateService){}

  transform(value: Date | moment.Moment): unknown {
    return moment(value).locale(this.translateService.currentLang).fromNow();
  }

}
