<div>
  <mat-form-field class="full-width mb-0" >
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <input matInput type="text" placeholder="{{placeHolder}}" [formControl]="autoCompleteControl"
g      [matAutocomplete]="auto" [id]="inputId" (input)="onTextChange($event)">
    <mat-autocomplete class="custom-autocomplete" autoActiveFirstOption #auto="matAutocomplete"
      [displayWith]="displayFn.bind(this)" (optionSelected)="onOptionSelected($event.option.value)"
      (opened)="onAutocompleteOpened()" (closed)="onAutocompleteClosed()" >
      <mat-option class="mat-select-actions" *ngFor="let option of filteredOptions | async" [value]="option">
        {{option[textProperty]}}
        <div class="mat-select-actions__buttons opacity-100" *ngIf="enableAddAction && noOptions">
          <button id="btn_Create" mat-stroked-button color="primary" class="opacity-100 border-2">
            {{ 'autoComplete.create' | translate }}
          </button>
        </div>
        <div class="mat-select-actions__buttons" *ngIf="showActionsButtons && !noOptions">
          <button id="btn_delete" mat-icon-button aria-label="more actions" (click)="onDeleteOption(option)">
            <icon name="delete"></icon>
          </button>
          <button id="btn_edit" mat-icon-button aria-label="more actions" (click)="onEditOption(option)">
            <icon name="edit"></icon>
          </button>
        </div>
      </mat-option>
    </mat-autocomplete>
    <div matSuffix class="d-flex">
      <icon name="close" class="cursor-pointer" (click)="onClear($event)" *ngIf="autoCompleteControl.value && !disabled">
      </icon>
      <icon name="info" class="info-icon" matTooltip="{{ infoText }}" matTooltipPosition="above" *ngIf="infoText">
      </icon>
      <mat-spinner id="spinner_autoComplete" diameter="25" *ngIf="selectedOptionsLoading"></mat-spinner>
    </div>
    <mat-error [id]="matErrorId" *ngIf="formSubmitted && autoCompleteControl.errors?.['required'] && requiredText">
      {{ requiredText }}
    </mat-error>
  </mat-form-field>
</div>
