import { Injectable } from '@angular/core';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { SalesOrder } from '../models/sales-order';
import { OtherCost } from 'src/app/shared/models/other-cost';
import { SalesOrderFlat } from 'src/app/shared/models/sales-order-flat';
import { MaterialRequirement } from 'src/app/shared/models/material-requirement';
import { AssetDepreciation, RunDetail } from 'src/app/fixed-assets/models/fixed-asset';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  private EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  private EXCEL_EXTENSION = '.xlsx';
  private salesOrdersHeadersOrder = ['Id', 'Document Code', 'Document Date', 'Paper Number', 'Customer Id', 'Customer Name',
    'Salesperson Id', 'Salesperson Name', 'Warehouse Id', 'Warehouse Name', 'Gross Total', 'Sub Total', 'Net Total', 'Order Status'];


  private materialRequirementsHeadersOrder = ['Material Item Code', 'Material Item Description', 'Quantity', 'Available Balance', 'Expected Receive',
    'Quantity To Purchase'];
  private otherCostsHeadersOrder = ['Cost Type Description', 'Gl Account Description', 'Quantity', 'Cost Per Unit', 'Total'];
  private productionPlanHeadersOrder = ['Product', 'Sales document', 'Customer', 'Sales person', 'Quantity', 'SO status'];
  private depreciationRunHeadersOrder = ['Asset Type', 'Asset Account', 'Accumulated Depreciation Account', 'Depreciation Expense Account', 'Carrying Value'];
  private depreciationSummaryHeadersOrder = ['Asset Name', 'Asset Code', 'Asset Type', 'Historical Cost', 'Opening Accumulated', 'Opening Carrying Value',
    'Purchases', 'Disposals', 'Depreciations', 'Closing Value', 'Current Location', 'Previous Location'];
  private reconciliationDetailsHeadersOrder = [
    this.translateService.instant('productionOrder.reconcile.productionOrder'),
    this.translateService.instant('productionOrder.reconcile.product'),
    this.translateService.instant('productionOrder.reconcile.costPerUnit'),
    this.translateService.instant('productionOrder.reconcile.changeValue'),
    this.translateService.instant('productionOrder.reconcile.costPerUnitAfterReconciliation')
  ];
  private reconciliationResultsHeadersOrder = [
    this.translateService.instant('productionOrder.reconcile.productionOrder'),
    this.translateService.instant('productionOrder.reconcile.failureReason')
  ];
  private bulkStartResultsHeadersOrder = [
    this.translateService.instant('productionOrder.start.productionOrder'),
    this.translateService.instant('productionOrder.start.failureReason')
  ];
  private bulkCompleteResultsHeadersOrder = [
    this.translateService.instant('productionOrder.complete.productionOrder'),
    this.translateService.instant('productionOrder.complete.failureReason')
  ];

  constructor(private translateService: TranslateService) { }

  public exportSalesOrders(salesOrders: any[], excelFileName: string) {
    const newJson = salesOrders.map((res: SalesOrder) => {
      return {
        'Id': res.id,
        'Document Code': res.document_code,
        'Document Date': new DatePipe('en-US').transform(res.document_date, 'medium'),
        'Paper Number': res.paper_number,
        'Customer Id': res.customer_id,
        'Customer Name': res.customer?.name,
        'Salesperson Id': res.salesPerson_id,
        'Salesperson Name': res.salesperson?.name,
        'Warehouse Id': res.warehouse_id,
        'Warehouse Name': res.warehouse?.description,
        'Gross Total': res.gross_total,
        'Sub Total': res.sub_total,
        'Net Total': res.net_total,
        'Order Status': res.order_status
      };
    });

    this.exportAsExcelFile(newJson, this.salesOrdersHeadersOrder, excelFileName);
  }

  public exportMaterialRequirements(materialRequirements: any[], excelFileName: string) {
    const newJson = materialRequirements.map((res: MaterialRequirement) => {
      return {
        'Material Item Code': res.material_item_code,
        'Material Item Description': res.material_item_description,
        'Quantity': res.quantity,
        'Available Balance': res.available_balance,
        'Expected Receive': res.expected_receive,
        'Quantity To Purchase': res.quantity_to_purchase
      };
    });

    this.exportAsExcelFile(newJson, this.materialRequirementsHeadersOrder, excelFileName);
  }

  public exportOtherCosts(otherCosts: any[], excelFileName: string) {
    const newJson = otherCosts.map((res: OtherCost) => {
      return {
        'Cost Type Description': res.cost_type.description,
        'Gl Account Description': res.glaccount_description,
        'Quantity': res.quantity,
        'Cost Per Unit': res.cost_per_unit,
        'Total': res.total
      };
    });

    this.exportAsExcelFile(newJson, this.otherCostsHeadersOrder, excelFileName);
  }

  public exportProductionPlanItems(productionPlanItems: any[], excelFileName: string) {
    const newJson = productionPlanItems.map((res: SalesOrderFlat) => {
      return {
        'Product': res.stock_item_description,
        'Sales document': res.document_code,
        'Customer': res.customer_name,
        'Sales person': res.salesperson_name,
        'Quantity': res.quantity,
        'SO status': res.order_status
      };
    });

    this.exportAsExcelFile(newJson, this.productionPlanHeadersOrder, excelFileName);
  }

  public exportDepreciationRun(runDetail: RunDetail[], excelFileName: string) {
    const newJson = runDetail.map((res: RunDetail) => {
      return {
        'Asset Type': res.asset_type,
        'Asset Account': res.asset_account_name,
        'Accumulated Depreciation Account': res.accumulated_depreciation_name,
        'Depreciation Expense Account': res.depreciation_expense_name,
        'Carrying Value': res.carrying_value
      };
    });

    this.exportAsExcelFile(newJson, this.depreciationRunHeadersOrder, excelFileName);
  }

  public exportDepreciationSummary(assetDepreciation: AssetDepreciation[], monthlyDetails: any[], excelFileName: string) {
    const newJson = assetDepreciation.map((res: AssetDepreciation) => {
      return {
        'Asset Name': res.asset_name,
        'Asset Code': res.asset_code,
        'Asset Type': res.asset_type,
        'Historical Cost': res.historical_cost,
        'Opening Accumulated': res.opening_accumulated_depreciation,
        'Opening Carrying Value': res.opening_carrying_value,
        'Purchases': res.purchases,
        'Disposals': res.disposal,
        'Depreciations': res.depreciation_value,
        'Closing Value': res.closing_value,
        'Current Location': res.current_location,
        'Previous Location': res.previous_location
      };
    });

    const dataWorksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newJson, { header: this.depreciationSummaryHeadersOrder });
    const monthlyDetailsWorksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(monthlyDetails);
    const workbook: XLSX.WorkBook = {
      Sheets: { 'data': dataWorksheet, 'Monthly details': monthlyDetailsWorksheet },
      SheetNames: ['data', 'Monthly details']
    };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportReconciliationDetails(reconciliationDetails: any[], excelFileName: string) {
    const newJson = reconciliationDetails.map(res => {
      let result: { [key: string]: any } = {};
      result[this.translateService.instant('productionOrder.reconcile.productionOrder')] = res.POCode;
      result[this.translateService.instant('productionOrder.reconcile.product')] = res.ProductName;
      result[this.translateService.instant('productionOrder.reconcile.costPerUnit')] = res.OldValue;
      result[this.translateService.instant('productionOrder.reconcile.changeValue')] = res.ChangeValue;
      result[this.translateService.instant('productionOrder.reconcile.costPerUnitAfterReconciliation')] = res.NewValue;
      return result;
    });

    this.exportAsExcelFile(newJson,
      this.translateService.currentLang === 'en' ? this.reconciliationDetailsHeadersOrder : this.reconciliationDetailsHeadersOrder.reverse(),
      excelFileName);
  }

  public exportReconciliationResults(reconciliationResults: any[], excelFileName: string) {
    const newJson = reconciliationResults.map(res => {
      let result: { [key: string]: any } = {};
      result[this.translateService.instant('productionOrder.reconcile.productionOrder')] = res.POCode;
      result[this.translateService.instant('productionOrder.reconcile.failureReason')] = res.Error;
      return result;
    });

    this.exportAsExcelFile(newJson,
      this.translateService.currentLang === 'en' ? this.reconciliationResultsHeadersOrder : this.reconciliationResultsHeadersOrder.reverse(),
      excelFileName);
  }

  public exportExportBulkStartResults(bulkStartResults: any[], excelFileName: string) {
    const newJson = bulkStartResults.map(res => {
      let result: { [key: string]: any } = {};
      result[this.translateService.instant('productionOrder.start.productionOrder')] = res.POCode;
      result[this.translateService.instant('productionOrder.start.failureReason')] = res.Error;
      return result;
    });

    this.exportAsExcelFile(newJson,
      this.translateService.currentLang === 'en' ? this.bulkStartResultsHeadersOrder : this.bulkStartResultsHeadersOrder.reverse(),
      excelFileName);
  }

  public exportExportBulkCompleteResults(bulkCompleteResults: any[], excelFileName: string) {
    const newJson = bulkCompleteResults.map(res => {
      let result: { [key: string]: any } = {};
      result[this.translateService.instant('productionOrder.complete.productionOrder')] = res.POCode;
      result[this.translateService.instant('productionOrder.complete.failureReason')] = res.Error;
      return result;
    });

    this.exportAsExcelFile(newJson,
      this.translateService.currentLang === 'en' ? this.bulkCompleteResultsHeadersOrder : this.bulkCompleteResultsHeadersOrder.reverse(),
      excelFileName);
  }

  exportAsExcelFile(json: any[], header: string[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { header: header });
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: this.EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + this.EXCEL_EXTENSION);
  }
}
