import { AfterContentInit, Directive, ElementRef, Renderer2 } from '@angular/core';
const arabicRegex = /[\u0600-\u06FF]/;

@Directive({
  selector: '[langDirection]'
})
export class LanguageDirectionDirective implements AfterContentInit {

  constructor(private hostRef: ElementRef, private renderer: Renderer2) { }

  ngAfterContentInit(): void {
    const elementText = (this.hostRef.nativeElement as HTMLElement)?.innerText || '';
    if (arabicRegex.test(elementText)) {
      this.renderer.setProperty(this.hostRef.nativeElement, 'dir', 'rtl');
    } else {
      this.renderer.setProperty(this.hostRef.nativeElement, 'dir', 'ltr');
    }
  }
}
