import { Injectable } from '@angular/core';
import { AppSettingsService } from './app-settings.service';
import { HttpClient } from '@angular/common/http';
import { QueryStringHelper } from 'src/app/shared/helpers/query-string.helper';
import { AuditEvent } from 'src/app/shared/models/audit-log';
import { AuditLogQuery } from 'src/app/shared/models/query';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuditLogService {

  private baseUrl = '';

  constructor(private httpClient: HttpClient) {
    this.baseUrl = AppSettingsService.appSettings.edaraCoreApi.baseUrl;
    if (this.baseUrl.endsWith('/')) {
      this.baseUrl = this.baseUrl.slice(0, -1);
    }
  }

  findAuditLogs(query: AuditLogQuery): Observable<AuditEvent[]> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return this.httpClient.get<AuditEvent[]>(this.baseUrl + '/api/auditLog/find?' + queryString);
  }
}