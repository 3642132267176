import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DexieDbProvider } from '@shared';
import { Currency } from '../../shared/models/currency';
import { ApiResponse } from '../../shared/models/api-response';
import { AccAccount } from '../../shared/models/account';
import { AppSettingsService } from './app-settings.service';
import { QueryStringHelper } from 'src/app/shared/helpers/query-string.helper';
import { OnlineOfflineService } from './online-offine.service';
import { ServiceResult } from 'src/app/shared/models/service-result';
import { ChartOfAccountsNode } from 'src/app/shared/models/chart-of-accounts-node';
import { CostCenter } from '@shared/models/cost-center';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountingService {

  baseUrl: string;
  constructor(
    private httpClient: HttpClient,
    private localDbProvider: DexieDbProvider,
    private onlineOfflineService: OnlineOfflineService) {
    this.baseUrl = AppSettingsService.appSettings.edaraApi.baseUrl;
  }

  //#region Currencies

  async loadCurrencies(): Promise<ServiceResult<Currency[]>> {
    const serviceResult = new ServiceResult<Currency[]>();
    const count = await this.countCurrenciesLocalAsync();
    if (!this.onlineOfflineService.isOnline || count > 0) {
      serviceResult.statusCode = 200;
      serviceResult.result = await this.getCurrenciesLocalAsync();
      serviceResult.totalCount = count;
      return serviceResult;
    } else {
      const res = await this.getCurrenciesAsync();
      if (res) {
        this.addCurrenciesToLocalDb(res.result);
        serviceResult.statusCode = res.status_code;
        serviceResult.result = res.result;
        serviceResult.statusMessage = res.error_message;
        serviceResult.totalCount = res.total_count;
      }
      return serviceResult;
    }
  }

  getCurrenciesAsync(): Promise<ApiResponse<Currency[]> | undefined> {
    return firstValueFrom(
      this.httpClient.get<ApiResponse<Currency[]>>(this.baseUrl + 'v2.0/currencies?offset=0&limit=100')
    ).catch(() => { return undefined; });
  }

  private getCurrenciesLocalAsync(): Promise<Currency[]> {
    return this.localDbProvider.db.currencies
      .toArray();
  }

  private countCurrenciesLocalAsync(): Promise<number> {
    return this.localDbProvider.db.currencies.count()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  addCurrenciesToLocalDb(currencies: Currency[]) {
    if (currencies && currencies.length > 0) {
      this.localDbProvider.db.currencies.bulkPut(currencies)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  //#endregion

  //#region Accounts

  async loadAllAccounts(): Promise<ServiceResult<AccAccount[]>> {
    const serviceResult = new ServiceResult<AccAccount[]>();
    const count = await this.countAllAccountsLocalAsync();
    if (!this.onlineOfflineService.isOnline || count > 0) {
      serviceResult.statusCode = 200;
      serviceResult.result = await this.getAllAccountsLocalAsync();
      serviceResult.totalCount = count;
      return serviceResult;
    } else {
      const query = {
        offset: 0,
        limit: 1000
      };
      let response: ApiResponse<AccAccount[]> | undefined;
      let accounts: AccAccount[] = [];
      do {
        response = await this.getAllAccountsAsync(query);
        if (response && response.status_code === 200) {
          query.offset += query.limit;
          accounts = accounts.concat(response.result);
          this.addAccountsToLocalDb(response.result);
        }
      } while (response && response.status_code === 200 && response.total_count > query.offset);
      serviceResult.statusCode = response?.status_code;
      serviceResult.result = accounts;
      serviceResult.statusMessage = response?.error_message;
      serviceResult.totalCount = response?.total_count;
      return serviceResult;
    }
  }

  addAccountsToLocalDb(accounts: AccAccount[]) {
    if (accounts && accounts.length > 0) {
      this.localDbProvider.db.accounts.bulkPut(accounts)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  getAllAccountsAsync(query: any): Promise<ApiResponse<AccAccount[]> | undefined> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return firstValueFrom(
      this.httpClient.get<ApiResponse<AccAccount[]>>(this.baseUrl + 'v2.0/accounts?' + queryString)
    ).catch(() => { return undefined; });
  }

  private getAllAccountsLocalAsync(): Promise<AccAccount[]> {
    return this.localDbProvider.db.accounts.toArray();
  }

  private countAllAccountsLocalAsync(): Promise<number> {
    return this.localDbProvider.db.accounts.count()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  async getAccountById(accountId: number): Promise<ServiceResult<AccAccount>> {
    const serviceResult = new ServiceResult<AccAccount>();
    const account = await this.getAccountByIdLocal(accountId);
    if (account && account.account_id > 0) {
      serviceResult.statusCode = 200;
      serviceResult.result = account;
      return serviceResult;
    } else {
      const res = await this.getAccountByIdAsync(accountId);
      if (res) {
        this.addAccountToLocalDb(res.result);
        serviceResult.statusCode = res.status_code;
        serviceResult.result = res.result;
        serviceResult.statusMessage = res.error_message;
        serviceResult.totalCount = res.total_count;
      }
      return serviceResult;
    }
  }

  private getAccountByIdAsync(accountId: number): Promise<ApiResponse<AccAccount> | undefined> {
    return firstValueFrom(
      this.httpClient.get<ApiResponse<AccAccount>>(this.baseUrl + `v2.0/accounts/${accountId}`)
    ).catch(() => { return undefined; });
  }

  private getAccountByIdLocal(accountId: number): Promise<AccAccount> {
    return this.localDbProvider.db.accounts.get(accountId);
  }

  private addAccountToLocalDb(account: AccAccount) {
    if (account) {
      this.localDbProvider.db.accounts.put(account)
        .catch((err: any) => this.localDbProvider.handleErrors(err));
    }
  }

  //#region Cash Accounts
  async loadCashAccounts(): Promise<ServiceResult<AccAccount[]>> {
    const serviceResult = new ServiceResult<AccAccount[]>();
    const count = await this.countCashAccountsLocalAsync();
    if (!this.onlineOfflineService.isOnline || count > 0) {
      serviceResult.statusCode = 200;
      serviceResult.result = await this.getCashAccountsLocalAsync();
      serviceResult.totalCount = count;
      return serviceResult;
    } else {
      const res = await this.getCashAccountsAsync();
      if (res) {
        this.addAccountsToLocalDb(res.result);
        serviceResult.statusCode = res.status_code;
        serviceResult.result = res.result;
        serviceResult.statusMessage = res.error_message;
        serviceResult.totalCount = res.total_count;
      }
      return serviceResult;
    }
  }

  private countCashAccountsLocalAsync(): Promise<number> {
    return this.localDbProvider.db.accounts.where('account_type').equals('Cash').count()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  getCashAccountsAsync(): Promise<ApiResponse<AccAccount[]> | undefined> {
    return firstValueFrom(
      this.httpClient.get<ApiResponse<AccAccount[]>>(this.baseUrl + 'v2.0/accounts/FindByType/Cash')
    ).catch(() => { return undefined; });
  }

  private getCashAccountsLocalAsync(): Promise<AccAccount[]> {
    return this.localDbProvider.db.accounts.where('account_type').equals('Cash').toArray();
  }
  //#endregion Cash Accounts

  //#region Bank Accounts
  async loadBankAccounts(): Promise<ServiceResult<AccAccount[]>> {
    const serviceResult = new ServiceResult<AccAccount[]>();
    const count = await this.countBankAccountsLocalAsync();
    if (!this.onlineOfflineService.isOnline || count > 0) {
      serviceResult.statusCode = 200;
      serviceResult.result = await this.getBankAccountsLocalAsync();
      serviceResult.totalCount = count;
      return serviceResult;
    } else {
      const res = await this.getBankAccountsAsync();
      if (res) {
        this.addAccountsToLocalDb(res.result);
        serviceResult.statusCode = res.status_code;
        serviceResult.result = res.result;
        serviceResult.statusMessage = res.error_message;
        serviceResult.totalCount = res.total_count;
      }
      return serviceResult;
    }
  }

  private countBankAccountsLocalAsync(): Promise<number> {
    return this.localDbProvider.db.accounts.where('account_type').equals('Bank').count()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  getBankAccountsAsync(): Promise<ApiResponse<AccAccount[]> | undefined> {
    return firstValueFrom(
      this.httpClient.get<ApiResponse<AccAccount[]>>(this.baseUrl + 'v2.0/accounts/FindByType/Bank')
    ).catch(() => { return undefined; });
  }

  private getBankAccountsLocalAsync(): Promise<AccAccount[]> {
    return this.localDbProvider.db.accounts.where('account_type').equals('Bank').toArray();
  }
  //#endregion Bank Accounts

  //#region Other Revenue Accounts
  async loadOtherRevenueAccounts(): Promise<ServiceResult<AccAccount[]>> {
    const serviceResult = new ServiceResult<AccAccount[]>();
    const count = await this.countOtherRevenueAccountsLocalAsync();
    if (!this.onlineOfflineService.isOnline || count > 0) {
      serviceResult.statusCode = 200;
      serviceResult.result = await this.getOtherRevenueAccountsLocalAsync();
      serviceResult.totalCount = count;
      return serviceResult;
    } else {
      const res = await this.getOtherRevenueAccountsAsync();
      if (res) {
        this.addAccountsToLocalDb(res.result);
        serviceResult.statusCode = res.status_code;
        serviceResult.result = res.result;
        serviceResult.statusMessage = res.error_message;
        serviceResult.totalCount = res.total_count;
      }
      return serviceResult;
    }
  }

  private countOtherRevenueAccountsLocalAsync(): Promise<number> {
    return this.localDbProvider.db.accounts.where('account_type').equals('OtherRevenue').count()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  getOtherRevenueAccountsAsync(): Promise<ApiResponse<AccAccount[]> | undefined> {
    return firstValueFrom(
      this.httpClient.get<ApiResponse<AccAccount[]>>(this.baseUrl + 'v2.0/accounts/FindByType/OtherRevenue')
    ).catch(() => { return undefined; });
  }

  private getOtherRevenueAccountsLocalAsync(): Promise<AccAccount[]> {
    return this.localDbProvider.db.accounts.where('account_type').equals('OtherRevenue').toArray();
  }
  //#endregion Other Revenue Accounts

  getAccountByRelatedCustomerAsync(customerId: number): Promise<ApiResponse<AccAccount> | undefined> {
    return firstValueFrom(
      this.httpClient.get<ApiResponse<AccAccount>>(this.baseUrl + `v2.0/accounts/FindByRelatedCustomerId/${customerId}`)
    ).catch(() => { return undefined; });
  }

  //#region AR Accounts
  async loadArAccounts(): Promise<ServiceResult<AccAccount[]>> {
    const serviceResult = new ServiceResult<AccAccount[]>();
    const count = await this.countArAccountsLocalAsync();
    if (!this.onlineOfflineService.isOnline || count > 0) {
      serviceResult.statusCode = 200;
      serviceResult.result = await this.getArAccountsLocalAsync();
      serviceResult.totalCount = count;
      return serviceResult;
    } else {
      const res = await this.getArAccountsAsync();
      if (res) {
        this.addAccountsToLocalDb(res.result);
        serviceResult.statusCode = res.status_code;
        serviceResult.result = res.result;
        serviceResult.statusMessage = res.error_message;
        serviceResult.totalCount = res.total_count;
      }
      return serviceResult;
    }
  }

  private countArAccountsLocalAsync(): Promise<number> {
    return this.localDbProvider.db.accounts.where('account_type').equals('AccountsReceivable').count()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  getArAccountsAsync(): Promise<ApiResponse<AccAccount[]> | undefined> {
    return firstValueFrom(
      this.httpClient.get<ApiResponse<AccAccount[]>>(this.baseUrl + 'v2.0/accounts/FindByType/AccountsReceivable')
    ).catch(() => { return undefined; });
  }

  private getArAccountsLocalAsync(): Promise<AccAccount[]> {
    return this.localDbProvider.db.accounts.where('account_type').equals('AccountsReceivable').toArray();
  }
  //#endregion AR Accounts

  //#region AP Accounts
  async loadApAccounts(): Promise<ServiceResult<AccAccount[]>> {
    const serviceResult = new ServiceResult<AccAccount[]>();
    const count = await this.countApAccountsLocalAsync();
    if (!this.onlineOfflineService.isOnline || count > 0) {
      serviceResult.statusCode = 200;
      serviceResult.result = await this.getApAccountsLocalAsync();
      serviceResult.totalCount = count;
      return serviceResult;
    } else {
      const res = await this.getApAccountsAsync();
      if (res) {
        this.addAccountsToLocalDb(res.result);
        serviceResult.statusCode = res.status_code;
        serviceResult.result = res.result;
        serviceResult.statusMessage = res.error_message;
        serviceResult.totalCount = res.total_count;
      }
      return serviceResult;
    }
  }

  private async countApAccountsLocalAsync(): Promise<number> {
    return this.localDbProvider.db.accounts.where('account_type').equals('AccountsPayable').count()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  getApAccountsAsync(): Promise<ApiResponse<AccAccount[]> | undefined> {
    return firstValueFrom(
      this.httpClient.get<ApiResponse<AccAccount[]>>(this.baseUrl + 'v2.0/accounts/FindByType/AccountsPayable')
    ).catch(() => { return undefined; });
  }

  private getApAccountsLocalAsync(): Promise<AccAccount[]> {
    return this.localDbProvider.db.accounts.where('account_type').equals('AccountsPayable').toArray();
  }
  //#endregion AP Accounts

  getAccountNodesByTypeAsync(accountType: string): Promise<ApiResponse<ChartOfAccountsNode[]> | undefined> {
    return firstValueFrom(
      this.httpClient.get<ApiResponse<ChartOfAccountsNode[]>>(this.baseUrl + `v2.0/accounts/Nodes/FindByType/${accountType}`)
    ).catch(() => { return undefined; });
  }

  //#region Inventory Accounts
  async loadInventoryAccounts(): Promise<ServiceResult<AccAccount[]>> {
    const serviceResult = new ServiceResult<AccAccount[]>();
    const count = await this.countInventoryAccountsLocalAsync();
    if (!this.onlineOfflineService.isOnline || count > 0) {
      serviceResult.statusCode = 200;
      serviceResult.result = await this.getInventoryAccountsLocalAsync();
      serviceResult.totalCount = count;
      return serviceResult;
    } else {
      const res = await this.getInventoryAccountsAsync();
      if (res) {
        this.addAccountsToLocalDb(res.result);
        serviceResult.statusCode = res.status_code;
        serviceResult.result = res.result;
        serviceResult.statusMessage = res.error_message;
        serviceResult.totalCount = res.total_count;
      }
      return serviceResult;
    }
  }

  private countInventoryAccountsLocalAsync(): Promise<number> {
    return this.localDbProvider.db.accounts.where('account_type').equals('Inventory').count()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  private getInventoryAccountsLocalAsync(): Promise<AccAccount[]> {
    return this.localDbProvider.db.accounts.where('account_type').equals('Inventory').toArray();
  }

  getInventoryAccountsAsync(): Promise<ApiResponse<AccAccount[]> | undefined> {
    return firstValueFrom(
      this.httpClient.get<ApiResponse<AccAccount[]>>(this.baseUrl + 'v2.0/accounts/FindByType/Inventory')
    ).catch(() => { return undefined; });
  }
  //#endregion Inventory Accounts

  //#region COGS Accounts
  async loadCogsAccounts(): Promise<ServiceResult<AccAccount[]>> {
    const serviceResult = new ServiceResult<AccAccount[]>();
    const count = await this.countCogsAccountsLocalAsync();
    if (!this.onlineOfflineService.isOnline || count > 0) {
      serviceResult.statusCode = 200;
      serviceResult.result = await this.getCogsAccountsLocalAsync();
      serviceResult.totalCount = count;
      return serviceResult;
    } else {
      const res = await this.getCogsAccountsAsync();
      if (res) {
        this.addAccountsToLocalDb(res.result);
        serviceResult.statusCode = res.status_code;
        serviceResult.result = res.result;
        serviceResult.statusMessage = res.error_message;
        serviceResult.totalCount = res.total_count;
      }
      return serviceResult;
    }
  }

  private countCogsAccountsLocalAsync(): Promise<number> {
    return this.localDbProvider.db.accounts.where('account_type').equals('COGS').count()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  private getCogsAccountsLocalAsync(): Promise<AccAccount[]> {
    return this.localDbProvider.db.accounts.where('account_type').equals('COGS').toArray();
  }

  getCogsAccountsAsync(): Promise<ApiResponse<AccAccount[]> | undefined> {
    return firstValueFrom(
      this.httpClient.get<ApiResponse<AccAccount[]>>(this.baseUrl + 'v2.0/accounts/FindByType/COGS')
    ).catch(() => { return undefined; });
  }
  //#endregion COGS Accounts

  //#region Sales Accounts
  async loadSalesAccounts(): Promise<ServiceResult<AccAccount[]>> {
    const serviceResult = new ServiceResult<AccAccount[]>();
    const count = await this.countSalesAccountsLocalAsync();
    if (!this.onlineOfflineService.isOnline || count > 0) {
      serviceResult.statusCode = 200;
      serviceResult.result = await this.getSalesAccountsLocalAsync();
      serviceResult.totalCount = count;
      return serviceResult;
    } else {
      const res = await this.getSalesAccountsAsync();
      if (res) {
        this.addAccountsToLocalDb(res.result);
        serviceResult.statusCode = res.status_code;
        serviceResult.result = res.result;
        serviceResult.statusMessage = res.error_message;
        serviceResult.totalCount = res.total_count;
      }
      return serviceResult;
    }
  }

  private countSalesAccountsLocalAsync(): Promise<number> {
    return this.localDbProvider.db.accounts.where('account_type').equals('Sales').count()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  private getSalesAccountsLocalAsync(): Promise<AccAccount[]> {
    return this.localDbProvider.db.accounts.where('account_type').equals('Sales').toArray();
  }

  getSalesAccountsAsync(): Promise<ApiResponse<AccAccount[]> | undefined> {
    return firstValueFrom(
      this.httpClient.get<ApiResponse<AccAccount[]>>(this.baseUrl + 'v2.0/accounts/FindByType/Sales')
    ).catch(() => { return undefined; });
  }
  //#endregion Sales Accounts

  //#region Sales Returns Accounts
  async loadSalesReturnsAccounts(): Promise<ServiceResult<AccAccount[]>> {
    const serviceResult = new ServiceResult<AccAccount[]>();
    const count = await this.countSalesReturnsAccountsLocalAsync();
    if (!this.onlineOfflineService.isOnline || count > 0) {
      serviceResult.statusCode = 200;
      serviceResult.result = await this.getSalesReturnsAccountsLocalAsync();
      serviceResult.totalCount = count;
      return serviceResult;
    } else {
      const res = await this.getSalesReturnsAccountsAsync();
      if (res) {
        this.addAccountsToLocalDb(res.result);
        serviceResult.statusCode = res.status_code;
        serviceResult.result = res.result;
        serviceResult.statusMessage = res.error_message;
        serviceResult.totalCount = res.total_count;
      }
      return serviceResult;
    }
  }

  private countSalesReturnsAccountsLocalAsync(): Promise<number> {
    return this.localDbProvider.db.accounts.where('account_type').equals('SalesReturns').count()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  private getSalesReturnsAccountsLocalAsync(): Promise<AccAccount[]> {
    return this.localDbProvider.db.accounts.where('account_type').equals('SalesReturns').toArray();
  }

  getSalesReturnsAccountsAsync(): Promise<ApiResponse<AccAccount[]> | undefined> {
    return firstValueFrom(
      this.httpClient.get<ApiResponse<AccAccount[]>>(this.baseUrl + 'v2.0/accounts/FindByType/SalesReturns')
    ).catch(() => { return undefined; });
  }
  //#endregion Sales Accounts

  //#region SalesDiscount Accounts
  async loadSalesDiscountAccounts(): Promise<ServiceResult<AccAccount[]>> {
    const serviceResult = new ServiceResult<AccAccount[]>();
    const count = await this.countSalesDiscountAccountsLocalAsync();
    if (!this.onlineOfflineService.isOnline || count > 0) {
      serviceResult.statusCode = 200;
      serviceResult.result = await this.getSalesDiscountAccountsLocalAsync();
      serviceResult.totalCount = count;
      return serviceResult;
    } else {
      const res = await this.getSalesDiscountAccountsAsync();
      if (res) {
        this.addAccountsToLocalDb(res.result);
        serviceResult.statusCode = res.status_code;
        serviceResult.result = res.result;
        serviceResult.statusMessage = res.error_message;
        serviceResult.totalCount = res.total_count;
      }
      return serviceResult;
    }
  }

  private countSalesDiscountAccountsLocalAsync(): Promise<number> {
    return this.localDbProvider.db.accounts.where('account_type').equals('SalesDiscount').count()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  getSalesDiscountAccountsAsync(): Promise<ApiResponse<AccAccount[]> | undefined> {
    return firstValueFrom(
      this.httpClient.get<ApiResponse<AccAccount[]>>(this.baseUrl + 'v2.0/accounts/FindByType/SalesDiscount')
    ).catch(() => { return undefined; });
  }

  private getSalesDiscountAccountsLocalAsync(): Promise<AccAccount[]> {
    return this.localDbProvider.db.accounts.where('account_type').equals('SalesDiscount').toArray();
  }
  //#endregion SalesDiscount Accounts

  //#region PurchaseDiscount Accounts
  async loadPurchaseDiscountAccounts(): Promise<ServiceResult<AccAccount[]>> {
    const serviceResult = new ServiceResult<AccAccount[]>();
    const count = await this.countPurchaseDiscountAccountsLocalAsync();
    if (!this.onlineOfflineService.isOnline || count > 0) {
      serviceResult.statusCode = 200;
      serviceResult.result = await this.getPurchaseDiscountAccountsLocalAsync();
      serviceResult.totalCount = count;
      return serviceResult;
    } else {
      const res = await this.getPurchaseDiscountAccountsAsync();
      if (res) {
        this.addAccountsToLocalDb(res.result);
        serviceResult.statusCode = res.status_code;
        serviceResult.result = res.result;
        serviceResult.statusMessage = res.error_message;
        serviceResult.totalCount = res.total_count;
      }
      return serviceResult;
    }
  }

  private countPurchaseDiscountAccountsLocalAsync(): Promise<number> {
    return this.localDbProvider.db.accounts.where('account_type').equals('PurchaseDiscount').count()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  getPurchaseDiscountAccountsAsync(): Promise<ApiResponse<AccAccount[]> | undefined> {
    return firstValueFrom(
      this.httpClient.get<ApiResponse<AccAccount[]>>(this.baseUrl + 'v2.0/accounts/FindByType/PurchaseDiscount')
    ).catch(() => { return undefined; });
  }

  private getPurchaseDiscountAccountsLocalAsync(): Promise<AccAccount[]> {
    return this.localDbProvider.db.accounts.where('account_type').equals('PurchaseDiscount').toArray();
  }
  //#endregion PurchaseDiscount Accounts

  //#region SalesTax Accounts
  async loadSalesTaxAccounts(): Promise<ServiceResult<AccAccount[]>> {
    const serviceResult = new ServiceResult<AccAccount[]>();
    const count = await this.countSalesTaxAccountsLocalAsync();
    if (!this.onlineOfflineService.isOnline || count > 0) {
      serviceResult.statusCode = 200;
      serviceResult.result = await this.getSalesTaxAccountsLocalAsync();
      serviceResult.totalCount = count;
      return serviceResult;
    } else {
      const res = await this.getSalesTaxAccountsAsync();
      if (res) {
        this.addAccountsToLocalDb(res.result);
        serviceResult.statusCode = res.status_code;
        serviceResult.result = res.result;
        serviceResult.statusMessage = res.error_message;
        serviceResult.totalCount = res.total_count;
      }
      return serviceResult;
    }
  }

  private countSalesTaxAccountsLocalAsync(): Promise<number> {
    return this.localDbProvider.db.accounts.where('account_type').equals('SalesTax').count()
      .catch((err: any) => this.localDbProvider.handleErrors(err));
  }

  getSalesTaxAccountsAsync(): Promise<ApiResponse<AccAccount[]> | undefined> {
    return firstValueFrom(
      this.httpClient.get<ApiResponse<AccAccount[]>>(this.baseUrl + 'v2.0/accounts/FindByType/SalesTax')
    ).catch(() => { return undefined; });
  }

  private getSalesTaxAccountsLocalAsync(): Promise<AccAccount[]> {
    return this.localDbProvider.db.accounts.where('account_type').equals('SalesTax').toArray();
  }
  //#endregion SalesTax Accounts

  //#region CostCenters

  getAllCostCenters(): Promise<ApiResponse<CostCenter[]> | undefined> {
    return firstValueFrom(
      this.httpClient.get<ApiResponse<CostCenter[]>>(this.baseUrl + 'v2.0/costcenters')
    ).catch(() => { return undefined; });
  }

  //#endregion CostCenters
}
