import { Component } from '@angular/core';

@Component({
  selector: 'app-keyboard-shortcuts-help',
  templateUrl: './keyboard-shortcuts-help.component.html',
  styleUrls: ['./keyboard-shortcuts-help.component.scss']
})
export class KeyboardShortcutsHelpComponent {

  constructor() { }

}

