<div class="scoped-dialog" *ngIf="isVisibleValue" [ngClass]="class">
  <div [ngStyle]="{ 'z-index': zIndex }"
    class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing animate__animated animate__fadeIn">
  </div>
  <div class="animate__animated animate__zoomIn scoped-dialog--container" [ngStyle]="{ 'z-index': zIndex + 1 }">
    <div class="mat-dialog-title" *ngIf="showHeader">
      <div class="dialog__header">
        <div>
          <h4 class="typo-title typo-color--primary">
            <span> {{ dataTitle }}</span>
          </h4>
        </div>
        <button id="btn_close" mat-icon-button (click)="close()" *ngIf="showClose">
          <icon name="close"></icon>
        </button>
      </div>
    </div>
    <div class="scoped-dialog--body">
      <ng-content></ng-content>
    </div>
  </div>
</div>
