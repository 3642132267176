import { Component } from '@angular/core';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';
import { INoRowsOverlayParams } from 'ag-grid-community';

@Component({
  selector: 'app-ag-no-rows-overlay',
  templateUrl: './ag-no-rows-overlay.component.html',
  styleUrls: ['./ag-no-rows-overlay.component.scss']
})
export class AgNoRowsOverlayComponent implements INoRowsOverlayAngularComp {
  public params!: INoRowsOverlayParams & { noRowsMessage: string };

  agInit(params: INoRowsOverlayParams & { noRowsMessage: string }): void {
    this.params = params;
  }
}
