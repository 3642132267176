import { computed, Injectable, signal } from '@angular/core';
import { PurchaseInvoice } from '../models';

@Injectable()
export class InvoicingPurchaseInvoiceFormService {

  readonly purchaseInvoiceRef = signal<PurchaseInvoice | null>(null);

  readonly isProposedFromPurchaseOrders = computed(() => {
    return this.purchaseInvoiceRef()?.lineItems.some(x => !!x.purchaseOrderItemId)
  });

}
