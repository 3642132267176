import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../src/environments/environment';
import { DOCUMENT } from '@angular/common';

export enum LanguagesKeys {
  ar = 'ar',
  enUS = 'en',
}

@Injectable({
  providedIn: 'root'
})
export class SwitchLanguageService {

  defaultLanguage!: string;
  supportedLanguages!: string[];
  currentLanguage = 'en';

  constructor(private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    private cookieService: CookieService) {
    if (this.cookieService.check('current_language')) {
      this.currentLanguage = this.cookieService.get('current_language');
    }

    //this.translateService.onLangChange.subscribe((event: LangChangeEvent) => this.onLanguageChanged(event.lang));
  }

  load() {
    return new Promise<any>((resolve: any) => {
      console.log('Loading language...');
      this.translateService.setDefaultLang('en');
      this.translateService.use(this.currentLanguage).subscribe({
        next: () => {
          this.setDirection(this.currentLanguage);
          console.info(`Successfully initialized '${this.currentLanguage}' language.'`);
        },
        error: () => {
          console.error(`Problem with '${this.currentLanguage}' language initialization.'`);
        },
        complete: () => {
          resolve(null);
        }
      });
    });
  }

  useCurrentLanguage() {
    this.translateService.use(this.currentLanguage);
    this.setDirection(this.currentLanguage)
  }

  useLanguage(language: string) {
    //this.translateService.use(language);
    this.cookieService.set('current_language', language, 30);
    location.reload();
  }

  private onLanguageChanged(lang: string) {
    if (lang) {
      this.currentLanguage = lang;
      this.cookieService.set('current_language', lang, 30);
    }
  }

  private setDirection(lang: string) {
    this.document.documentElement.lang = lang;
    if (lang === environment.defaultLanguage) {
      this.document.documentElement.dir = 'rtl';
    } else {
      this.document.documentElement.dir = 'ltr';
    }
  }


}
