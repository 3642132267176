<!-- <nav class="side-nav" [ngClass]="{'side-nav--collapsed': appService.state.navState === 'collapsed'}"
  *ngIf="appService.state.navState !== 'closed'">
  <ng-container *ngFor="let item of menuService.menu_items; let i = index">
    <div [attr.id]="item.module" class="side-nav__item"
      [ngClass]="{'side-nav__item--selected': i === menuService.navMenuState.selectedMenuIndex}"
      (mouseleave)="menuService.navMenuState.selectedMenuIndex = -1"
      (mouseenter)="item.details.length? menuService.navMenuState.selectedMenuIndex = i : selectedMenuIndex = -1"
      *ngIf="helpersService.isDesktop || item.primaryMenu">


    </div>
    <hr *ngIf="item.divider" class="side-nav__divider">
  </ng-container>
  <ng-template #moreMenuDialog>
    <ng-container *ngFor="let item of menuService.menu_items; let i = index">
      <div class="side-nav__item side-nav__item--more"
        [ngClass]="{'side-nav__item--selected': i === menuService.navMenuState.selectedMenuIndex}"
        *ngIf="helpersService.isDesktop || !item.primaryMenu">
        <button mat-ripple class="side-nav__link" (click)="selectedMenuIndex = i">
          <icon class="side-nav__icon" [name]="item.icon"></icon>
          <span class="side-nav__title">
            {{ item.label | translate | capitalize }}
          </span>
        </button>
        <div class="side-nav__sub" *ngIf="item.details?.length">
          <div class="side-nav__sub-container">
            <div class="side-nav__back" *ngIf="!helpersService.isDesktop">
              <button mat-icon-button (click)="menuService.navMenuState.selectedMenuIndex = -1">
                <icon class="button__icon" name="arrow_back"></icon>
              </button>
              {{ item.label | translate | capitalize }}
            </div>
            <ng-container *ngFor="let group of item.details">
              <h4 *ngIf="group.title">{{group.title}}</h4>
              <div [@heightAnimation] *ngFor="let modulePage of group.details">
                <a class="side-nav__link" matRipple [routerLinkActive]="['side-nav__link--active']"
                  (click)="menuService.navMenuState.selectedMenuIndex = -1; routeClick()"
                  [routerLink]="modulePage.routerLink" [routerLink]="modulePage.routerLink"
                  *ngIf="modulePage.permissions || modulePage.permissions === true" [id]="modulePage.id">
                  <span>{{ modulePage.title | translate }}</span>
                </a>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <hr *ngIf="item.divider" class="side-nav__divider" />
    </ng-container>
  </ng-template>

</nav> -->


<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
<div class="side-nav__backdrop"
  [ngClass]="{'side-nav__backdrop--show': menuService.navMenuState.selectedMenuIndex >= 0}"
  (click)="menuService.navMenuState.selectedMenuIndex = -1">
  <div class="sr-only">backdrop</div>
</div>
<nav class="side-nav" [ngClass]="{'side-nav--collapsed': appService.state.navState === 'collapsed'}"
  *ngIf="appService.state.navState !== 'closed'">
  <ng-container *ngFor="let item of menuService.menu_items; let i = index">
    <div [attr.id]="item.id" class="side-nav__item"
      [ngClass]="{'side-nav__item--selected': i === menuService.navMenuState.selectedMenuIndex}"
      (mouseleave)="menuService.navMenuState.selectedMenuIndex = -1"
      (mouseenter)="item.details.length? menuService.navMenuState.selectedMenuIndex = i : selectedMenuIndex = -1"
      *ngIf="helpersService.isDesktop || item.primaryMenu">
      <a mat-ripple class="side-nav__link" [class.side-nav__link--active]="isActiveRoute(item.link)"
        [routerLink]="item.routerLink">
        <icon class="side-nav__icon" [name]="item.icon"></icon>
        <span class="side-nav__title">{{ item.label | translate | capitalize }}</span>
      </a>
      <div [class]="'side-nav__sub side-nav__sub--'+item.level2Location" *ngIf="item.details">
        <div class="side-nav__sub-container">
          <div class="side-nav__sub-title"
            *ngIf="appService.state.navState === 'collapsed' && helpersService.isDesktop">
            {{ item.label | translate | capitalize }}
          </div>

          <ng-container>
            <div class="tabs-Visibility">
              <div class="tabs-Visibility--btns margin-bottom-base" *ngIf="item.hasStarredPages" [@heightAnimation]>
                <button mat-button color="primary" id="btn_starredNavItems"
                  (click)="menuService.changeModuleSideNavTab(item, moduleSideNavTabs.starred)"
                  [ngClass]="{'active': item.activeTab === moduleSideNavTabs.starred ,'bg-surface-2': menuService.starredIndicator === item.module}">
                  <icon class="starred-indicator" [isSvg]="true" name="star-indicator" width="20" height="20"
                    *ngIf="menuService.starredIndicator === item.module">
                  </icon>
                  <span>{{ 'nav-menu.starred' | translate }}</span>
                </button>
                <button mat-button color="primary" id="btn_allNavItems"
                  (click)="menuService.changeModuleSideNavTab(item, moduleSideNavTabs.all)"
                  [ngClass]="{'active': item.activeTab === moduleSideNavTabs.all}">
                  {{ 'nav-menu.all' | translate }}
                </button>
              </div>
              <div *ngIf="item.activeTab === moduleSideNavTabs.starred">
                <!-- <ng-container *ngFor="let group of item.details">
                 <div [@heightAnimation] *ngFor="let modulePage of group?.starredItems">
                    <app-side-nav-link [checkStarred]="true" *ngIf="modulePage?.showItem"
                      (onToggleStarred)="toggleStarred(modulePage,item)" [item]="modulePage"
                      [navMenuState]="menuService.navMenuState" (onRouteClick)="routeClick()"></app-side-nav-link>
                  </div>
                </ng-container>-->
                <ng-container *ngFor="let group of item.details">
                  <div [@heightAnimation]>
                    <app-side-nav-link [checkStarred]="true" (onToggleStarred)="toggleStarred(group,item)"
                      [item]="group" [navMenuState]="menuService.navMenuState" (onRouteClick)="routeClick()"
                      [newSalesOrderLayout]="newSalesOrderLayout"></app-side-nav-link>
                  </div>
                </ng-container>
              </div>
              <div *ngIf="item.activeTab === moduleSideNavTabs.all || !item.hasStarredPages">
                <!--<ng-container *ngFor="let group of item.details">
                   <div [@heightAnimation] *ngFor="let modulePage of group.details">
                    <app-side-nav-link (onToggleStarred)="toggleStarred(modulePage,item)" *ngIf="modulePage?.showItem"
                      [item]="modulePage" [navMenuState]="menuService.navMenuState"
                      (onRouteClick)="routeClick()"></app-side-nav-link>
                  </div>
                </ng-container> -->
                <ng-container *ngFor="let group of item.details">
                  <div [@heightAnimation]>
                    <app-side-nav-link (onToggleStarred)="toggleStarred(group,item)" [item]="group"
                      [navMenuState]="menuService.navMenuState" (onRouteClick)="routeClick()"
                      [newSalesOrderLayout]="newSalesOrderLayout"></app-side-nav-link>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>

          <!-- <ng-container *ngFor="let item of item.details">
            <a class="side-nav__link" matRipple [routerLinkActive]="['side-nav__link--active']"
              (click)="selectedMenuIndex = -1; routeClick()" [routerLink]="item.routerLink"
              *ngIf="(item.permissions || item.permissions === true) && (item.checkForNewSalesLayout? item._newSalesOrderLayout  === newSalesOrderLayout: true)"
              [id]="item.id">
              <span>{{ item.label | translate | capitalize }}</span>
            </a>
          </ng-container> -->


        </div>
      </div>
    </div>
    <hr *ngIf="item.divider" class="side-nav__divider">
  </ng-container>
  <ng-template #moreMenuDialog>
    <ng-container *ngFor="let item of menuItems; let i = index">
      <div class="side-nav__item side-nav__item--more" [ngClass]="{'side-nav__item--selected': i === selectedMenuIndex}"
        *ngIf="helpersService.isDesktop || !item.primaryMenu">
        <button mat-ripple class="side-nav__link" (click)="selectedMenuIndex = i">
          <icon class="side-nav__icon" [name]="item.icon"></icon>
          <span class="side-nav__title">
            {{ item.label | translate | capitalize }}
          </span>
        </button>
        <div class="side-nav__sub" *ngIf="item.details">
          <div class="side-nav__sub-container">
            <div class="side-nav__back" *ngIf="!helpersService.isDesktop">
              <button mat-icon-button (click)="selectedMenuIndex = -1">
                <icon class="button__icon" name="arrow_back"></icon>
              </button>
              {{ item.label | translate | capitalize }}
            </div>
            <ng-container *ngFor="let item of item.details">
              <a class="side-nav__link" matRipple [routerLinkActive]="['side-nav__link--active']"
                (click)="selectedMenuIndex = -1; routeClick()" [routerLink]="item.routerLink"
                *ngIf="item.permissions || item.permissions === true" [id]="item.id">
                <span>{{ item.label | translate | capitalize }}</span>
              </a>
            </ng-container>
          </div>
        </div>
      </div>
      <hr *ngIf="item.divider" class="side-nav__divider" />
    </ng-container>
    <div class="side-nav__item side-nav__item--more" *ngIf="!helpersService.isDesktop">
      <button mat-ripple class="side-nav__link" (click)="changeTheme()">
        <icon class="side-nav__icon" [name]="appService.state.theme === 'light' ? 'dark_mode' : 'light_mode'"></icon>
        <span class="side-nav__title">
          {{appService.state.theme === 'light'? ('nav-menu.darkMode' | translate): ('nav-menu.lightMode' | translate)}}
        </span>
      </button>
    </div>
  </ng-template>
  <div class="side-nav__item" *ngIf="helpersService.isDesktop">
    <button mat-ripple class="side-nav__link side-nav__link--change-theme" (click)="changeTheme()">
      <icon class="side-nav__icon" [name]="(appService.state.theme === 'light'? 'dark_mode': 'light_mode')"></icon>
      <span class="side-nav__title">
        {{appService.state.theme === 'light'? ('nav-menu.darkMode' | translate): ('nav-menu.lightMode' | translate)}}
      </span>
    </button>
  </div>
  <div class="side-nav__item" *ngIf="!helpersService.isDesktop">
    <button mat-ripple class="side-nav__link" (click)="openMoreMenu()"
      aria-label="Example icon button with a delete icon">
      <icon class="side-nav__icon" name="more_vert"></icon>
      <span class="side-nav__title">
        more
      </span>
    </button>
  </div>
</nav>