export class ServiceItem {
    id?: number;
    code!: string;
    description!: string;
    price!: number;
    tax_rate?: number;
    tax_id?: number;
    description_keywords: string[] = [];
    isSelected?: boolean;
}
