import { UserProfile } from './user-profile';
import { DeviceInfo } from 'ngx-device-detector';

export enum SyncStatus {
    Synced = 0,
    Pending = 1,
    ConflictOrError = 2,
    Processing = 3,
}

export class OfflineMetadata {
    organizationId!: string;
    createdDate!: Date;
    createdBy!: UserProfile;
    modifiedDate?: Date;
    modifiedBy?: UserProfile;
    syncedDate?: Date;
    syncedBy?: UserProfile;
    syncPending?: number;
    processingStartedAt?: Date;
    processingCompletedAt?: Date;
    syncStatusMessage?: string;
    deviceInfo?: DeviceInfo;
    environmentInfo?: environmentInfo;
}

export class environmentInfo {
    version?: string;
    domain?: string;
    clientIpAddress?: string;
}
