import { Pipe, type PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({
  name: 'appOrderBy',
})
export class OrderByPipe implements PipeTransform {

  transform(value: any[], path: string = '', dir: 'asc' | 'desc' = 'asc'): any[] {
    return orderBy(value, path, dir);
  }
}
