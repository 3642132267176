import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthService } from '@auth';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from './app-settings.service';
import { OnlineOfflineService } from './online-offine.service';

@Injectable({
    providedIn: 'root'
})
export class LoggingService {
    constructor(private injector: Injector) {
        this.authService = injector.get<AuthService>(AuthService);
        this.onlineOfflineService = injector.get<OnlineOfflineService>(OnlineOfflineService);
        this.httpClient = new HttpClient(injector.get<HttpBackend>(HttpBackend));

        this.serverUrl = AppSettingsService.appSettings.logging?.serverUrl;
        this.apiKey = AppSettingsService.appSettings.logging?.apiKey;
        this.ingestUrl = this.apiKey ?
            `${this.serverUrl}/api/events/raw?apiKey=${this.apiKey}&clef` :
            `${this.serverUrl}/api/events/raw?clef`;

        this.checkSeqAvailability();

        if (`serviceWorker` in navigator) {
            navigator.serviceWorker.addEventListener('message', async event => this.swHttpRequestLogHandler(event));
        }
    }

    private httpClient: HttpClient;
    private authService: AuthService;
    private onlineOfflineService: OnlineOfflineService;

    private apiKey;
    private serverUrl;
    private ingestUrl;

    private logs: any[] = [];
    private batchInterval: any;
    private isSeqAvailable = true;

    info(messageTemplate: string, data?: any): void {
        this.ingest({
            '@t': new Date().toISOString(),
            '@mt': messageTemplate,
            '@l': 'Information',
            'Channel': 'Edara PWA',
            'UserName': this.authService.currentUserEmail,
            'TenantId': this.authService.currentTenant,
            ...data
        });
    }

    error(messageTemplate: string, data?: any): void {
        this.ingest({
            '@t': new Date().toISOString(),
            '@mt': messageTemplate,
            '@l': 'Error',
            'Channel': 'Edara PWA',
            'UserName': this.authService.currentUserEmail,
            'TenantId': this.authService.currentTenant,
            ...data
        });
    }

    public ingest(logData: any): void {

        if (!this.isSeqAvailable) return;

        this.logs.push(logData);

        if (!this.batchInterval) {
            this.batchInterval = setInterval(() => {
                this.sendLogs();
            }, 5000); // 5 seconds
        }
    }

    private async swHttpRequestLogHandler(event: any) {
        if (event.data.command === 'logHttpRequest') {
            this.ingest({
                '@t': new Date().toISOString(),
                'Channel': 'ServiceWorker',
                'Host': window.location.hostname,
                'Protocol': window.location.protocol,
                'EnvironmentName': environment.name,
                'ApplicationVersion': environment.version,
                'UserAgent': navigator.userAgent,
                'UserName': this.authService.currentUserEmail,
                'TenantId': this.authService.currentTenant,
                ...event.data.logData
            });
        }
    }

    private checkSeqAvailability() {
        if (!this.serverUrl) {
            this.isSeqAvailable = false;
        }

        // this.httpClient.get(`${this.serverUrl}/health`,
        //     {
        //         headers: { 'Access-Control-Allow-Origin': '*' }
        //     })
        //     .subscribe({
        //         next: () => {
        //             this.isSeqAvailable = true;
        //         },
        //         error: (err) => {
        //             console.error(err);
        //             this.isSeqAvailable = false;
        //         }
        //     });
    }

    private sendLogs() {
        if (this.onlineOfflineService.isOnline &&
            this.isSeqAvailable && this.logs.length > 0) {
            const logsToSend = this.logs.splice(0, 100);
            const body = logsToSend.map(log => JSON.stringify(log)).join('\r\n');

            this.httpClient.post(this.ingestUrl, body).subscribe({
                error: (err) => console.error(err)
            });
        } else {
            clearInterval(this.batchInterval);
            this.batchInterval = null;
        }
    }
}
