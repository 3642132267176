import { PagingQuery } from "@shared";
import { EntityCode } from "@shared/models/entity-code";
import { MoneyHelper } from "@shared/services/money-helper.service";
import { sumBy } from "lodash";
import { Location, Customer, Currency, DiscountDto, ProductVariant, InvoiceType } from "./common";
import { InvoicingProductUnitOfMeasure } from "./invoicing-unit-of-measure";
import { DiscountType } from "@shared/enums/discount-type";
import { KSAEInvoiceResult, KSAEInvoiceStatus } from "./ksa-e-invoice-result";

const moneyHelper = new MoneyHelper();

export class SalesInvoice {
  id!: string;
  code?: EntityCode = new EntityCode();
  date!: Date;
  location?: Location;
  customer?: Customer;
  referenceNo?: string;
  currency?: Currency;
  lineItems: SalesInvoiceItem[] = [];
  priceIncludeTax?: boolean;
  orderDiscount: DiscountDto = new DiscountDto();
  invoiceDiscount?: number;
  totalBeforeTax?: number;
  taxableAmount?: number;
  nonTaxableAmount?: number;
  deliveryFees?: number;
  subTotal?: number;
  taxAmount?: number;
  netTotal!: number;
  type: InvoiceType = InvoiceType.SalesInvoice;
  extraAttributes?: SalesInvoiceExtraAttributes;

  getItemsSum(propName: keyof SalesInvoiceItem) {
    const sum = sumBy(this.lineItems, li => +(li[propName] ?? 0));
    return moneyHelper.round(sum);
  }

  get getGrossTotal(): number { return this.getItemsSum("getGrossTotal"); }
  get getLineDiscount(): number { return this.getItemsSum("getDiscountValue"); }
  get getSubtotal(): number { return this.getItemsSum("getSubtotal"); }
  get getTax(): number { return this.getItemsSum("getTax"); }
  get getNetTotal(): number { return this.netTotal = this.getItemsSum("getNetTotal"); }

  get canBeSubmitted(): boolean {
    return this.extraAttributes?.ksaeInvoiceResult?.Status === KSAEInvoiceStatus.ReadyForClearance ||
      this.extraAttributes?.ksaeInvoiceResult?.Status === KSAEInvoiceStatus.ReadyForReporting;
  }

  get hasXML(): boolean {
    return !!this.extraAttributes?.ksaeInvoiceResult?.XmlFileName;
  }

  public static clone(salesInvoice: any): SalesInvoice | undefined {
    if (!salesInvoice) return undefined;
    const newInvoice: SalesInvoice = Object.assign(
      new SalesInvoice(),
      salesInvoice
    );
    newInvoice.code = Object.assign(new EntityCode(), salesInvoice.code);
    newInvoice.date = new Date(newInvoice.date);
    newInvoice.lineItems = [];
    if (salesInvoice.lineItems) {
      for (let index = 0; index < salesInvoice.lineItems?.length; index++) {
        salesInvoice.lineItems[index].sequence = index + 1;
        newInvoice.lineItems.push(
          SalesInvoiceItem.clone(salesInvoice.lineItems[index])
        );
      }
    }
    return newInvoice;
  }
}

export class SalesInvoiceItem {
  id!: string;
  productId: string;
  productName: string;
  productDescription?: string;
  productVariant?: ProductVariant;
  quantity: number;
  price: number;
  discount: DiscountDto;
  discountAmount?: number;
  total?: number;
  subTotal?: number;
  taxRate: number;
  taxAmount?: number;
  sequence?: number;
  isSelected?: boolean;
  unitOfMeasure?: InvoicingProductUnitOfMeasure;
  netTotal?: number;

  productUnitOfMeasures: InvoicingProductUnitOfMeasure[] = [];
  baseUom?: InvoicingProductUnitOfMeasure;

  constructor(
    productId: string,
    productName: string,
    productdescription: string | undefined,
    quantity: number,
    price: number
  ) {
    this.productId = productId;
    this.productName = productName;
    this.productDescription = productdescription;
    this.quantity = quantity;
    this.price = price;
    this.discount = new DiscountDto();
    this.taxRate = 0;
  }

  get getGrossTotal(): number {
    return moneyHelper.round(this.price * this.quantity);
  }

  get getDiscountValue(): number {
    if (this.discount.type === DiscountType.Amount)
      return moneyHelper.round(this.discount.value);

    const discount = this.getGrossTotal * this.discount.value * 0.01;
    return moneyHelper.round(discount);
  }

  get getSubtotal(): number {
    return moneyHelper.round(this.getGrossTotal - this.getDiscountValue);
  }

  get getTax(): number {
    const tax = this.getSubtotal * this.taxRate * 0.01;
    return moneyHelper.round(tax);
  }

  get getNetTotal(): number {
    return moneyHelper.round(this.getSubtotal + this.getTax);
  }

  public static clone(
    salesInvoiceItem: Partial<SalesInvoiceItem>
  ): SalesInvoiceItem {
    const newSalesInvoiceItem = new SalesInvoiceItem('', '', '', 0, 0);
    return Object.assign(
      newSalesInvoiceItem,
      salesInvoiceItem
    );
  }
}

export class SalesInvoiceQuery extends PagingQuery {
  customers?: string[];
  invoiceDateStart?: Date;
  invoiceDateEnd?: Date;
}

export class SalesInvoiceExtraAttributes {
  ksaeInvoiceResult?: KSAEInvoiceResult;
}