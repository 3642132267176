import { Injectable } from "@angular/core";
import { DexieDbProvider } from "@shared/services/dexie-provider";
import { Guid } from "guid-typescript";

export class BlobImage {
    constructor(url: string, data: Blob, mimeType?: string) {
        this.url = removeUrlParameters(url);
        this.data = data;
        this.mimeType = mimeType;
        this.id = this.url.split('/').pop()!.split('.')[0];
    }

    id: string;
    url: string;
    data: Blob;
    mimeType?: string;
}

@Injectable({
    providedIn: 'root'
})
export class ImageService {

    constructor(private localDbProvider: DexieDbProvider) { }

    async getImageUrl(url?: string) {

        if(!url) return url;

        let image: BlobImage = await this.readImageByUrl(url);
        if (image) {
            return URL.createObjectURL(image.data);
        }

        this.fetchImage(url)
            .then(blob => this.saveImageToDatabase(url, blob));

        return url;
    }

    async fetchImage(url: string): Promise<Blob> {
        return fetch(url)
            .then(response => response.blob());
    }

    async saveImageToDatabase(url: string, blob: Blob) {
        this.saveImage(new BlobImage(url, blob, blob.type));
    }

    saveImage(image: BlobImage): Promise<string> {
        return this.localDbProvider.db.BlobImages.put(image);
    }

    readImageById(id: string): Promise<BlobImage> {
        return this.localDbProvider.db.BlobImages.get(id);
    }

    readImageByUrl(url: string): Promise<BlobImage> {
        url = removeUrlParameters(url);
        return this.localDbProvider.db.BlobImages
            .where("url")
            .equals(url)
            .first();
    }

    deleteImage(id: string): Promise<number> {
        return this.localDbProvider.db.BlobImages
            .where("id")
            .equals(id)
            .delete();
    }
}

export function removeUrlParameters(url: string) {
    try{
      const urlObj = new URL(url);
      urlObj.search = '';
      urlObj.hash = '';

      return urlObj.toString();
    } catch {
      return url;
    }
}

export function getFileName(url: string | undefined, imageExtention: string) {
    if (!url) {
        return `${Guid.create().toString()}.${imageExtention}`;
    }

    const urlObj = removeUrlParameters(url);
    const id = urlObj.split('/').pop()!.split('.')[0];

    return `${id}.${imageExtention}`;
}
