import { Pipe, PipeTransform } from "@angular/core";
import { formatCurrency, getCurrencySymbol } from "@angular/common";
import { MoneyHelper } from "@shared";
import { EdaraSettingsService } from "../../core/services/edara-settings.service";

@Pipe({
  name: "currencyFormatter",
})
export class CurrencyFormatter implements PipeTransform {

  locale = "en";
  currencyCode = " ";

  constructor(private edaraSettingsService: EdaraSettingsService,
    private moneyHelper: MoneyHelper) { }

  async transform(
    value: number,
    currencyCode: string = this.currencyCode,
    display: "code" | "symbol" | "symbol-narrow" | string | boolean = "symbol",
    digitsInfo: string = "",
    locale: string = this.locale
  ) {
    let noOfDecimalPlaces =
      await this.edaraSettingsService.noOfDecimalPlacesMoney();
    if (!noOfDecimalPlaces || noOfDecimalPlaces < 2) {
      noOfDecimalPlaces = 2;
    }
    digitsInfo = `1.2-${noOfDecimalPlaces}`;
    return formatCurrency(
      this.moneyHelper.round(value, noOfDecimalPlaces),
      this.locale,
      getCurrencySymbol(this.currencyCode, "narrow"),
      this.currencyCode,
      digitsInfo
    ).trim();
  }

}
