// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { LanguagesKeys } from '../app/core/index';

export const environment = {
  name: 'dev',
  production: false,
  version: 'Dev',
  defaultLanguage: LanguagesKeys.ar,
  supportedLanguages: [LanguagesKeys.enUS, LanguagesKeys.ar],
  versionCheckURL: '',
  intercomAppId: 'fy6969im',
  sentry: {
    dsn: 'https://6a8f6be12ad247fdb686904ba1d68a43@o998260.ingest.sentry.io/5956884',
    ignoreErrors: [
      /^DatabaseClosedError$/
    ]
  },
  firebase: {
    apiKey: 'AIzaSyAtkJlkEbovtc3kRxyIuWVruCXU-7gSfbk',
    authDomain: 'edarapwa-dev.firebaseapp.com',
    projectId: 'edarapwa-dev',
    storageBucket: 'edarapwa-dev.appspot.com',
    messagingSenderId: '181478020295',
    appId: '1:181478020295:web:d64ff8c356c6a6b0a59ae2'
  },
  authConfig: {
    clientID: '6ucGVdH2DodXiuTWcH3H4dBoeANdVhvv',
    domain: 'getedara.auth0.com',
    apiIdentifier: 'https://api.getedara.com',
    scope: 'openid email profile'
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
