import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@auth';

export const authGuard: CanActivateFn = (route, state) => {
    const authService = inject(AuthService);
    const router = inject(Router);
    if (authService.isAuthenticated()) return true;
    // Not logged in so redirect to login page with the return url
    router.navigate(['/'], { queryParams: { returnUrl: state.url } });
    return false;
};