import { AppSettings } from '../../shared/models/app-settings';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  static appSettings: AppSettings;
  private httpClient: HttpClient;
  constructor(private handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  load() {
    return new Promise((resolve, reject) => {
      this.httpClient.get<AppSettings>(`/assets/app-settings.${environment.name}.json`).subscribe({
        next: (res: AppSettings) => {
          AppSettingsService.appSettings = res;
          resolve(true);
        },
        error: (error: any) => {
          reject(`Could not load file app-settings.json: ${JSON.stringify(error)}`);
        }
      });
    });
  }

}