import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export class MatPaginatorIntlCro extends MatPaginatorIntl {

  override itemsPerPageLabel = 'Items per page';
  override nextPageLabel = 'Next page';
  override previousPageLabel = 'Previous page';
  override firstPageLabel = 'First Page';
  override lastPageLabel = 'Last Page';
  ofWord = "of";

  translate?: TranslateService;

  override getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return ' 0 ' + ' ' + this.ofWord + ' ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' ' + this.ofWord + ' ' + length;
  }

  injectTranslateService(translateService: TranslateService) {
    this.translate = translateService
    this.translate.onLangChange.subscribe(() => {
      this.translateLabels();
    });

    this.translateLabels();
  }

  translateLabels() {
    this.itemsPerPageLabel = this.translate?.instant('pagination.itemsPerPage');
    this.nextPageLabel = this.translate?.instant('pagination.next');
    this.previousPageLabel = this.translate?.instant('pagination.previous');
    this.firstPageLabel = this.translate?.instant('pagination.firstPage');
    this.lastPageLabel = this.translate?.instant('pagination.lastPage');
    this.ofWord = this.translate?.instant('pagination.of');
    this.changes.next()
  }

}