import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getColorFromEmail } from '@shared/helpers/user-avatar-color-generator';
import { ComparisonOperator, WorkFlowConditionType } from 'src/app/purchasing/models/prch-workflow';

@Pipe({
    name: 'workflowAuditLogValueFormatter',
})
export class WorkflowAuditLogValueFormatter implements PipeTransform {

    constructor(private translateService: TranslateService) { }

    transform(value: string): any[] {
        const jsonObject = JSON.parse(value);

        if (Array.isArray(jsonObject)) {
            // Approvers
            if (typeof jsonObject[0] === 'string') {
                return jsonObject.map(option => {
                    const chipInfo: any = {
                        email: option,
                        userInitals: option.charAt(0).toLocaleUpperCase(),
                        avatarBackgroundColor: getColorFromEmail(option)
                    };
                    return chipInfo;
                });
            }

            // Conditions
            if (typeof jsonObject[0] === 'object') {
                return jsonObject.map((condition: any, index: number) => {
                    const typeText = this.translateService.instant(`settingsV3.purchaseOrderSettings.auditLog.${WorkFlowConditionType[condition.Type]}`);
                    const operatorText = this.translateService.instant(`settingsV3.purchaseOrderSettings.auditLog.${ComparisonOperator[condition.Operator]}`);
                    const amount = condition.Amount;
                    return `${index + 1}- ${typeText} ${operatorText} ${amount}`;
                });
            }
        }

        return [];
    }

}
