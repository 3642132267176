import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterArray'
})
export class FilterArrayPipe implements PipeTransform {
    transform(items: any[] = [], key: string, value: any): any[] {
        if (!items?.length || !key) {
            return items;
        }
        return items.filter((item) => item[key] === value);
    }
}
