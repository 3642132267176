import { Directive, ElementRef, AfterViewInit, Input, OnDestroy } from '@angular/core';

@Directive({
  selector: '[agGridIds]'
})
export class AgGridIdsDirective implements AfterViewInit, OnDestroy {
  @Input() headerId: string = 'table_header';
  @Input() bodyId: string = 'table_body';
  @Input() footerId: string = 'table_footer';
  @Input() sideBtnsClasses: string[] = [];
  @Input() tabelHeaderFirstCol: string = 'first-col-checkbox';
  @Input() tabelBodyFirstColId: string = 'first-col-checkbox';
  @Input() tabelBodyFirstRow: string = 'first-row';
  @Input() tabelBodyRows: string = 'tableBody_row';

  private footerObserver!: MutationObserver;
  private bodyObserver!: MutationObserver;

  constructor(private el: ElementRef) { }

  ngAfterViewInit(): void {
    this.setIds();
  }

  ngOnDestroy(): void {
    this.footerObserver.disconnect();
    this.bodyObserver.disconnect();
  }

  setIds() {
    const agGridContainerElement: HTMLElement | undefined = this.el.nativeElement;
    if (agGridContainerElement) {
      const tabelHeader = agGridContainerElement.querySelector<HTMLElement>('.ag-header');
      const tabelBody = agGridContainerElement.querySelector<HTMLElement>('.ag-body-viewport');
      const tabelFooter = agGridContainerElement.querySelector<HTMLElement>('.ag-floating-bottom');
      const sideBtns: HTMLButtonElement[] = Array.from(agGridContainerElement.querySelectorAll<HTMLButtonElement>('.ag-side-button-button'));
      const tabelHeaderFirstCol = agGridContainerElement.querySelector<HTMLElement>('.ag-header .ag-header-cell:first-child .ag-checkbox-input');
      const tabelBodyRows = agGridContainerElement.querySelector<HTMLElement>('.ag-body-viewport .ag-center-cols-container');

      tabelHeader && tabelHeader.setAttribute('id', this.headerId);
      tabelBody && tabelBody.setAttribute('id', this.bodyId);
      tabelFooter && tabelFooter.setAttribute('id', this.footerId);
      tabelFooter && this.observeFooterCells(tabelFooter);

      if (sideBtns.length && this.sideBtnsClasses.length) {
        sideBtns.forEach((btn, i) => {
          if (this.sideBtnsClasses[i]) {
            btn.setAttribute('id', this.sideBtnsClasses[i]);
          }
        });
      }

      tabelHeaderFirstCol && tabelHeaderFirstCol.setAttribute('id', this.tabelHeaderFirstCol);
      tabelBodyRows && this.observeBodyCells(tabelBodyRows);
      tabelBodyRows && tabelBodyRows.setAttribute('id', this.tabelBodyRows);
    }
  }

  observeFooterCells(tabelFooter: HTMLElement) {
    const footerCellsHandler = (mutationsList: MutationRecord[]) => {
      mutationsList.forEach(mutation => {
        if (mutation.type === 'childList') {
          const footerCells = tabelFooter.querySelectorAll<HTMLElement>('.ag-cell-value');
          footerCells.forEach((cell: HTMLElement) => {
            if (!isNaN(+cell.outerText)) {
              cell.setAttribute('id', 'footer_' + cell.getAttribute("col-id"));
            }
          });
        }
      });
    };

    this.footerObserver = new MutationObserver(footerCellsHandler);
    this.footerObserver.observe(tabelFooter, { childList: true, subtree: true });
  }

  observeBodyCells(tabelBodyRows: HTMLElement) {
    const checkboxId = this.tabelBodyFirstColId;
    const bodyFirstRowId = this.tabelBodyFirstRow;

    const bodyFirstRowHandler = (mutationsList: MutationRecord[]) => {
      mutationsList.forEach(mutation => {
        if (mutation.type === 'childList') {
          const bodyFirstRow = tabelBodyRows.querySelector<HTMLElement>('.ag-row:first-child .ag-cell input');
          bodyFirstRow?.setAttribute('id', checkboxId);

          const tabelBodyFirstRow = tabelBodyRows.querySelector<HTMLElement>('.ag-row:first-child');
          tabelBodyFirstRow?.setAttribute('id', bodyFirstRowId);
        }
      });
    };

    this.bodyObserver = new MutationObserver(bodyFirstRowHandler);
    this.bodyObserver.observe(tabelBodyRows, { childList: true, subtree: true });
  }
}
