import { Component, Output, EventEmitter, Input } from '@angular/core';
@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent {

  files: any[] = [];

  @Input() loading: boolean = false;
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  constructor() { }

  filesDropped(files: any): void {
    this.files = files;
    this.onChange.emit(this.files);
  }

  deleteFile() {
    this.files = [];
    this.onChange.emit(this.files);
  }

}
