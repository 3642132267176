import { AfterViewInit, Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MoneyHelper } from '@shared';

@Directive({
  selector: '[appNumberField]'
})
export class NumberFieldDirective implements AfterViewInit {

  @Input() minimumValue: number = 0;
  @Input() maximumValue: number = 999999;
  @Input() initialValue: number = 0;
  @Input() noOfDecimalPlaces: number = 2;
  @Input() allowDecimals: boolean = true;
  @Input() decimalSeparator: string = '.';
  @Input() defaultValidValue?: number;

  lastValidValue?: number;
  invalidChars = ['-', '+', 'e', 'E'];

  constructor(private moneyHelper: MoneyHelper, private control: NgControl) { }

  ngAfterViewInit() {
    this.lastValidValue = this.initialValue;
  }

  /**
  * Event handler for host's paste event
  */
  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    const value = event.clipboardData?.getData('text/plain');
    event.preventDefault();
    this.toValidValue(value ?? '');
  }

  /**
  * Event handler for host's keyup event
  */
  @HostListener('keyup', ['$event']) onKeyUp(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    if (this.invalidChars.includes(event.key) || (!this.allowDecimals && event.key === this.decimalSeparator)) {
      this.control.control?.setValue('');
      this.preventDefault(target.value);
      return;
    }


    if (this.allowDecimals && event.key === this.decimalSeparator)
      return;

    if (!this.isValidNumber(target['value']) ||
      (this.isDeciaml(+target['value']) && this.countDeciamlPaces(target['value']) > this.noOfDecimalPlaces)) {
      this.preventDefault(target['value']);
      return;
    }

    // Decimal
    if (this.isDeciaml(+target['value'])) {
      if (this.countDeciamlPaces(target['value']) > this.noOfDecimalPlaces) {
        this.preventDefault(target['value']);
      } else {
        const roundedValue = this.moneyHelper.round(+target['value'], this.noOfDecimalPlaces);
        if (this.lastValidValue !== roundedValue) {
          this.setValue(roundedValue.toString());
        }
      }
    }
    // Integer or deciaml point
    else {
      this.setValue(target['value']);
    }
  }

  countDeciamlPaces(value: string) {
    if (!value) return 0;
    const indexOfDeciamlPoint = value.indexOf(this.decimalSeparator);
    if (indexOfDeciamlPoint !== -1) {
      return value.length - (indexOfDeciamlPoint + 1);
    }
    return 0;
  }

  preventDefault(value: string) {
    let valueNumber = Number(value);
    if (!isNaN(valueNumber) && valueNumber > this.maximumValue && this.defaultValidValue !== undefined) {
      this.control.control?.setValue(this.defaultValidValue);
    } else {
      this.control.control?.setValue(this.lastValidValue);
    }
  }

  setValue(value: string) {
    const emptyNumberRegex = new RegExp(`^0\.0{0,${this.noOfDecimalPlaces - 1}}$`)// eg. 0.0, 0.00, 0.000, ...
    if(emptyNumberRegex.test(value)) return;
    if (!this.control.control?.value || (!!this.minimumValue && this.control.control.value < this.minimumValue)) {
      this.control.control?.setValue(this.minimumValue);
      this.lastValidValue = this.minimumValue;
    } else if (value) {
      this.lastValidValue = +value;
    }
  }

  /**
   * Check if the value is a valid number (integer or decimal)
   * @param value
   */
  private isValidNumber(value: string): boolean {
    const valueNumber = Number(value);
    return !isNaN(valueNumber) && (this.maximumValue === undefined || valueNumber <= this.maximumValue);
  }

  private isDeciaml(value: number) {
    return value % 1 !== 0;
  }

  private toValidValue(value: string): void {
    let valueNumber = Number(value);
    if (!isNaN(valueNumber)) {
      if (!!this.maximumValue && valueNumber >= this.maximumValue) {
        this.setValue(this.maximumValue.toString());
      } else {
        if (this.isDeciaml(valueNumber) && this.countDeciamlPaces(value) > this.noOfDecimalPlaces) {
          valueNumber = this.moneyHelper.round(valueNumber, this.noOfDecimalPlaces);
        }
        this.setValue(valueNumber.toString());
      }
    }
  }

}
