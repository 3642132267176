export class User {
    username?: string;
    email!: string;
    picture_url?: string;

    constructor(data: Object | User) {
        Object.assign(this, data);
    }
}

export interface Tenant {
    id: string;
    name: string;
}

export interface UserTenant {
    tenant: Tenant;
    edara_user_id: number;
}
