import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@auth';

@Pipe({
  name: 'isInRole'
})
export class IsInRolePipe implements PipeTransform {
  constructor(private authService: AuthService) {}
  transform(roleName:string): boolean {
    if (this.authService.userRoles) {
      return this.authService.userRoles.indexOf(roleName) > -1;
    }
    return false;
  }
}
