import {PagingQuery} from "@shared";
import {EntityCode} from "@shared/models/entity-code";
import {MoneyHelper} from "@shared/services/money-helper.service";
import moment from "moment";
import {InvoicingProductUnitOfMeasure} from "./invoicing-unit-of-measure";
import {sumBy} from "lodash";
import { Location, Supplier, Currency,  } from './common';

const moneyHelper = new MoneyHelper();

export class PurchaseInvoice {
  id!: string;
  code?: EntityCode = new EntityCode();
  invoiceDate!: Date;
  dueDate!: Date;
  location?: Location;
  supplier?: Supplier;
  currency?: Currency;
  netTotal!: number;
  status!: string;
  isCancelled?: boolean;
  referenceNo?: string;
  lineItems: PurchaseInvoiceItem[] = [];
  paymentStatus: PurchaseInvoicePaymentStatus = PurchaseInvoicePaymentStatus.Unpaid;

  get isValid(): boolean {
    const regex = new RegExp("^[a-zA-Z0-9\\-_:\\/\\\\,\\.]+$");
    return (
      !!this.code &&
      !!this.code.fullCode &&
      regex.test(this.code.fullCode) &&
      !!this.supplier &&
      !!this.invoiceDate &&
      !!this.dueDate &&
      moment(this.dueDate).startOf('day').isSameOrAfter(moment(this.invoiceDate).startOf('day')) &&
      !!this.lineItems.length
    );
  }

  getItemsSum(propName: string) {
    const sum = sumBy(this.lineItems, li => +(li[propName as keyof PurchaseInvoiceItem] ?? 0));
    return moneyHelper.round(sum);
  }

  get getGrossTotal(): number { return this.getItemsSum("getGrossTotal"); }
  get getLineDiscount(): number { return this.getItemsSum("getDiscountValue"); }
  get getSubtotal(): number { return this.getItemsSum("getSubtotal"); }
  get getTax(): number { return this.getItemsSum("getTax"); }
  get getNetTotal(): number { return this.netTotal = this.getItemsSum("getNetTotal"); }

  public static clone(purchaseInvoice: any): PurchaseInvoice | undefined {
    if (!purchaseInvoice) return undefined;
    const newInvoice: PurchaseInvoice = Object.assign(
      new PurchaseInvoice(),
      purchaseInvoice
    );
    newInvoice.code = Object.assign(new EntityCode(), purchaseInvoice.code);
    newInvoice.invoiceDate = new Date(newInvoice.invoiceDate);
    newInvoice.dueDate = new Date(newInvoice.dueDate);
    newInvoice.lineItems = [];
    for (let index = 0; index < purchaseInvoice.lineItems?.length; index++) {
      purchaseInvoice.lineItems[index].sequence = index + 1;
      newInvoice.lineItems.push(
        PurchaseInvoiceItem.clone(purchaseInvoice.lineItems[index])
      );
    }
    return newInvoice;
  }

  public addLineItem(lineItem: PurchaseInvoiceItem) {
    this.lineItems.unshift(lineItem);
    this.resetLineItemsSequence();
  }

  public removeLineItem(sequence: number) {
    this.lineItems.splice(sequence - 1, 1);
    this.resetLineItemsSequence();
  }

  private resetLineItemsSequence() {
    this.lineItems.forEach(function (item, index) {
      item.sequence = index + 1;
    });
  }

  private static daysUntilDueDate(purchaseInovice: PurchaseInvoice): number {
    const now = moment().startOf('day');
    const dueDate = moment(purchaseInovice.dueDate).startOf('day');
    const daysUntilDueDate = dueDate.diff(now, 'days');
    return daysUntilDueDate;
  }

  static getStatus(purchaseInovice: PurchaseInvoice): { status: string, daysUntilDueDate: number } {
    if (purchaseInovice.isCancelled)
      return { status: "Cancelled", daysUntilDueDate: 0 };

    if (purchaseInovice.paymentStatus === PurchaseInvoicePaymentStatus.Paid)
      return { status: "Paid", daysUntilDueDate: 0 };

    const daysUntilDueDate = this.daysUntilDueDate(purchaseInovice);

    if (daysUntilDueDate === 0)
      return { status: "DueToday", daysUntilDueDate };
    else if (daysUntilDueDate > 0)
      return { status: "Undue", daysUntilDueDate };
    else
      return { status: "Overdue", daysUntilDueDate: -daysUntilDueDate };
  }
}

export class PurchaseInvoiceItem {
  id!: string;
  productId: string;
  productName: string;
  productDescription?: string;
  quantity: number;
  price: number;
  discount: number;
  discountType: PurchaseInvoiceLineDiscountType;
  taxRate: number;
  purchaseOrderItemId?: string;
  sequence?: number;
  isSelected?: boolean;
  purchaseOrderItemAvailableToInvoice?: number;
  unitOfMeasure?: InvoicingProductUnitOfMeasure;

  productUnitOfMeasures: InvoicingProductUnitOfMeasure[] = [];
  baseUom?: InvoicingProductUnitOfMeasure;
  compoundDiscount?: { discount1: number, discount2: number, discount3: number };

  constructor(
    productId: string,
    productName: string,
    productdescription: string | undefined,
    quantity: number,
    price: number,
    purchaseOrderItemId?: string,
    purchaseOrderItemAvailableToInvoice?: number
  ) {
    this.productId = productId;
    this.productName = productName;
    this.productDescription = productdescription;
    this.quantity = quantity;
    this.price = price;
    this.discount = 0;
    this.discountType = PurchaseInvoiceLineDiscountType.Value;
    this.taxRate = 0;
    this.purchaseOrderItemId = purchaseOrderItemId,
      this.purchaseOrderItemAvailableToInvoice = purchaseOrderItemAvailableToInvoice ?? 0;
  }

  get getGrossTotal(): number {
    return moneyHelper.round(this.price * this.quantity);
  }

  get getDiscountValue(): number {
    if (this.discountType === PurchaseInvoiceLineDiscountType.Value)
      return moneyHelper.round(this.discount);

    const discount = this.getGrossTotal * this.discount * 0.01;
    return moneyHelper.round(discount);
  }

  get getSubtotal(): number {
    return moneyHelper.round(this.getGrossTotal - this.getDiscountValue);
  }

  get getTax(): number {
    const tax = this.getSubtotal * this.taxRate * 0.01;
    return moneyHelper.round(tax);
  }

  get getNetTotal(): number {
    return moneyHelper.round(this.getSubtotal + this.getTax);
  }

  public static clone(
    purchaseInvoiceItem: PurchaseInvoiceItem
  ): PurchaseInvoiceItem {
    const newPurchaseInvoiceItem = new PurchaseInvoiceItem('', '', '', 0, 0);
    const clonedPurchaseInvoiceItem = Object.assign(
      newPurchaseInvoiceItem,
      purchaseInvoiceItem
    );
    return clonedPurchaseInvoiceItem;
  }

  public resetDiscounts() {
    this.discount = 0;
    this.compoundDiscount = undefined;
  }
}

export enum PurchaseInvoiceLineDiscountType {
  Value = 0,
  Percentage = 1
}

export enum PurchaseInvoicePaymentStatus {
  Unpaid = 0,
  Paid = 1,
  Unpaid_Undue = 2,
  Unpaid_Overdue = 3,
  Unpaid_DueToday = 4,
  Cancelled = 5
}


export class PurchaseInvoiceQuery extends PagingQuery {
  suppliers?: string[];
  invoiceDateStart?: Date;
  invoiceDateEnd?: Date;
  dueDateStart?: Date;
  dueDateEnd?: Date;
  paymentStatus?: string
}


export class InvoicingProduct {
  id!: string;
  name!: string;
  description?: string;
  sku!: string;
  partNumber!: string;
  cost!: number;
  salesPrice!: number;
  purchasePrice!: number;
  imageUrl?: string;
  soldByWeight?: boolean;
  createdDate?: Date;
  isSelected?: boolean;
  unitOfMeasures: InvoicingProductUnitOfMeasure[] = [];
}

export class ProductImageUploadUrl {
  fileName!: string;
  uploadUrl!: string;
}


export enum ReceivedStatus {
  NotReceived = 0,
  Received = 1,
  PartiallyReceived = 2
}

export class PurchaseRequestQuery extends PagingQuery {
  code?: string;
  requestDateStart?: Date;
  requestDateEnd?: Date;
  locations?: string[];
  suppliers?: string[];
}


export enum PurchaseRequestListing {
  ByRequest,
  ByLineItem
}
export class PurchaseOrderQuery extends PagingQuery {
  code?: string;
  requestDateStart?: Date;
  requestDateEnd?: Date;
  locations?: string[];
  suppliers?: string[];
}


