import { Component, Input, OnInit } from '@angular/core';
import { UserProfile, Organization } from 'src/app/shared/models/user-profile';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  @Input() userProfile?: UserProfile;
  @Input() currentOrganization?: Organization;
  public userInitals: string = '';

  constructor() { }

  getInitials(name: any) {
    this.userInitals = name.split(' ').map((el: string) => el.charAt(0)?.toLocaleUpperCase()).join('')
  }

  ngOnInit(): void {
    this.getInitials(this.userProfile?.nickname);
  }
}
