import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-currency-container',
  templateUrl: './currency-container.component.html',
  styleUrls: ['./currency-container.component.scss']
})
export class CurrencyContainerComponent implements OnInit {
  @Input() currencyCode?: string;
  @Input() amount?: number;
  _displayCodeBeforAmount: boolean = false;
  @Input() set displayCodeBeforAmount(val: BooleanInput){
    this._displayCodeBeforAmount = coerceBooleanProperty(val);
  }

  constructor() { }

  ngOnInit(): void {
  }

}
