import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { FeaturesConfigService } from '../services/features-config.service';

export const featuresFlagsGuard: CanActivateFn = (route) => {
  const featuresConfigService = inject(FeaturesConfigService);
  // Get the name of the feature from the route data provided
  const feature = route.data['feature'];
  if (feature) {
    return featuresConfigService.isFeatureEnabled(feature) || false;
  }
  return true; // no feature flag supplied for this route
};