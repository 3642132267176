export class EntityCode {
    prefix?: string;
    sequence!: string;

    get fullCode() {
        if (!this.prefix && !this.sequence) return '';
        if (!this.prefix) return this.sequence;

        return `${this.prefix}${this.sequence}`;
    }
}
