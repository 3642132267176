<div class="card card--landing">
  <a href="#" [routerLink]="itemData.routerLink" class="card--landing--link"
    (click)="routeLastTimeClicked(itemData.label)"></a>
  <div class="d-flex flex-column card--landing--content-container">
    <div class="card card--landing--content">
      <h4 class="card__title">{{itemData.label | translate}}</h4>
    </div>
    <!-- <a href="#" class="card--landing--content--text d-block margin-start-none margin-top-s"
            (click)="routeLastTimeClicked(itemData.title)" [matTooltip]="$any(itemData.description)  | translate"
            *ngIf="itemData.lastTimeOpend">Page opened {{itemData.lastTimeOpend | fromNowPipe}}</a> -->
    <a href="#" (click)="routeLastTimeClicked(itemData.label)" [routerLink]="itemData.routerLink"
      class="card--landing--content--text d-block margin-start-none margin-top-s"
      [matTooltip]="$any(itemData.description)  | translate">{{$any(itemData.description) | translate}}</a>
  </div>
  <button mat-icon-button class="card--landing--btn" (click)="toggleStarred()">
    <icon class="card__icon" [isSvg]="true" [name]="itemData.isStarred ?'star_border_purple500':'star'"
      [ngClass]="{'card__icon--active' : itemData.isStarred , 'card__icon--show' : !itemData.isStarred }" width="24"
      height="24"></icon>
  </button>
</div>