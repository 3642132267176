import { Location } from "@shared";
import { PosUnitOfMeasure } from "./pos-unit-of-measure";
import { Tax } from "./tax.model";

export class Product {
    id?: string;
    name!: string;
    description?: string;
    sku!: string;
    partNumber!: string;
    cost?: number;
    salesPrice?: number;
    stockOnHand?: number;
    imageUrl?: string;
    soldByWeight: boolean = false;
    type!: ProductType;
    components: ProductComponent[] = [];
    createdBy?: string;
    createdDate?: Date;
    lastModifiedBy?: string;
    lastModifiedDate?: Date;
    tax?: Tax;
    unitOfMeasures: ProductUnitOfMeasure[] = [];
    balances: ProductBalance[] = [];
    attributes: ProductAttribute[] = [];
    variants: ProductVariant[] = [];

    isSelected: boolean = false;
    uom_barcodes: string[] = [];
    uom_SKUs: string[] = [];
    searchKeywords: string[] = [];
    variant_barcodes: string[] = [];
    variant_SKUs: string[] = [];

    public static clone(product: Product): Product | undefined {
        if (!product) return undefined;

        const newProduct: Product = Object.assign(new Product(), product);
        newProduct.components = [];
        if (product.components?.length) {
            product.components.forEach(element => {
                newProduct.components.push({ ...element });
            });
            newProduct.resetProductComponentsSerials();
        }

        return newProduct;
    }

    public addComponent(component: Product, quantity: number, mergeSimilarItems: boolean = false): ProductComponent {

        if (mergeSimilarItems) {
            // Find Product in Product Component
            const retreivedProduct = this.components.find(itm => itm.component.id === component.id);

            // If Product exists (sum quantities)
            if (retreivedProduct) {
                retreivedProduct.quantity += quantity;
                return retreivedProduct;
            }
        }

        const productComponent = new ProductComponent(component, quantity);

        // Add Product to Components list
        this.components.unshift(productComponent);

        // Reset Product Components serials
        this.resetProductComponentsSerials();

        return productComponent;
    }

    public removeComponent(sequence: number) {
        this.components.splice(sequence - 1, 1);
        this.resetProductComponentsSerials();
    }

    private resetProductComponentsSerials() {
        this.components.forEach((component, index) => {
            component.sequence = index + 1;
        });
    }

    addAttribute() {
        const productAttribute = new ProductAttribute('', []);

        this.attributes.push(productAttribute);

        // Reset Product Attributes Positions
        this.resetProductAttributesPositions();
    }

    removeAttribute(index: number) {
        this.attributes.splice(index, 1);
        this.resetProductAttributesPositions();
    }

    private resetProductAttributesPositions() {
        this.attributes.forEach((attribute, index) => {
            attribute.position = index + 1;
        });
    }

    public removeVariant(index: number) {
        this.variants.splice(index, 1);
        this.removeUnusedAttributeValues();
    }

    public removeSelectedVariants() {
        this.variants = this.variants.filter(variant => !variant.is_selected);
        this.removeUnusedAttributeValues();
    }

    private removeUnusedAttributeValues() {
        this.attributes.forEach(attribute => {
            const usedAttributeValues = attribute.values.filter(value => this.variants.some(variant => Object.values(variant.attributes).includes(value)));
            attribute.values = [...usedAttributeValues];
        });
    }

}

export class ProductImageUploadUrl {
    fileName!: string;
    uploadUrl!: string;
}

export enum ProductType {
    Product = 1,
    Bundle = 2,
    Service = 3
}

export class ProductComponent {
    component!: Product;
    quantity: number;
    cost?: number;
    sequence?: number;

    constructor(product: Product, quantity: number) {
        if (product) {
            this.component = product;
            this.cost = product.cost;
        }

        this.quantity = quantity;
    }
}

export interface ProductUnitOfMeasure {
    id?: string;
    product?: Product;
    unitOfMeasure?: PosUnitOfMeasure;
    sku?: string;
    partNumber?: string;
    salesPrice?: number;
    baseUnit?: boolean;
    ratio?: number;
    displayName?: string;
}

export interface ProductBalance {
    id?: string;
    productId?: string;
    locationId?: string;
    productVariantId?: string;
    location: Location;
    stockOnHand: number;
    createdDate?: string;
    lastModifiedDate?: string;
}

export class ProductAttribute {
    name: string;
    values: string[];
    position!: number;

    is_duplicated?: boolean

    constructor(name: string, values: string[]) {
        this.name = name;
        this.values = values;
    }

    addAttributeValue(value: string) {
        this.values.push(value);
    }

    removeAttributeValue(value: string) {
        this.values = this.values.filter(item => item !== value);
    }
}

export class ProductVariant {
    id?: string;
    title?: string;
    sku!: string;
    partNumber!: string;
    cost?: number;
    salesPrice?: number;
    imageUrl?: string;
    tax!: Tax;
    attributes!: ProductVariantAttributes;

    is_selected?: boolean;
    is_sku_duplicated?: boolean
    is_partNumber_duplicated?: boolean;
    unitOfMeasures?: ProductUnitOfMeasure[] = [];
}

export interface ProductVariantAttributes {
    attribute1: string;
    attribute2: string;
    attribute3: string;
    attribute4: string;
    attribute5: string;
}
