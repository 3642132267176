import moment from "moment";
import { CashRegister } from "./cash-register";
import { SalesOrderItem } from "./sales-order-item";
import { SalesOrder } from "./sales-order";
import { maxBy, sumBy } from "lodash";

export class Promotion {

    id?: string;
    name!: string;
    description?: string;
    startDate!: Date;
    endDate!: Date;
    createdDate?: Date;
    promotionRule: PromotionRule = new PromotionRule();

    get promotionType(): PromotionType {
        const promotionTypeMap: Record<keyof PromotionRule, PromotionType> = {
            productDiscount: PromotionType.ProductDiscount,
            orderDiscount: PromotionType.OrderDiscount,
            buyXGetY: PromotionType.BuyXGetY
        };

        const promotionRule = this.promotionRule;
        const promotionType = Object.keys(promotionTypeMap).find(
            (key): key is keyof PromotionRule => promotionRule[key as keyof PromotionRule] != null
        );

        return promotionTypeMap[promotionType!];
    }

    public static clone(promotion?: Promotion): Promotion | undefined {
        if (!promotion) return undefined;
        const newPromotion = Object.assign(new Promotion(), promotion);
        return newPromotion;
    }

    public static isLineItemPromotionValid(saleOrderItem: SalesOrderItem, promotion: Promotion, salesOrder: SalesOrder) {
      if (!promotion)
        return false;

      // if order discount then it is not valid
      if (promotion.promotionRule.orderDiscount) return false;

      if(promotion.promotionRule.productDiscount)
        return ProductDiscountPromotionRule.canApply(promotion.promotionRule.productDiscount, saleOrderItem, salesOrder.cashRegister);

      if(promotion.promotionRule.buyXGetY)
        return BuyXGetYPromotionRule.canApply(promotion.promotionRule.buyXGetY, saleOrderItem, salesOrder);

      return false;
    }

    public static isOrderPromotionValid(promotion?: Promotion, cashRegister?: CashRegister) {
      if (!promotion)
        return false;

      // Assure target type is Order
      if (!promotion.promotionRule.orderDiscount)
          return false;

      return OrderDiscountPromotionRule.canApply(promotion.promotionRule.orderDiscount, cashRegister);
    }

    static applyToOrder(salesOrder: SalesOrder, promotion?: Promotion) {
      if(!Promotion.isOrderPromotionValid(promotion, salesOrder.cashRegister)) {
        salesOrder.discount_rate = 0;
        salesOrder.discountValue = 0;
        return undefined;
      }

      const orderDiscountRule = promotion!.promotionRule.orderDiscount!;
      if (orderDiscountRule.valueType === DiscountValueType.FixedAmount) {
        salesOrder.discount_rate = 0;
        salesOrder.discountValue = orderDiscountRule.value;
      } else if (orderDiscountRule.valueType === DiscountValueType.Percentage) {
        salesOrder.discountValue = 0;
        salesOrder.discount_rate = orderDiscountRule.value;
      }

      return promotion;
    }

    static applyBestPromotionToOrder(salesOrder: SalesOrder, promotions: Promotion[]): Promotion | undefined {
      const matchedPromos = promotions
        .filter(x =>
          Promotion.isOrderPromotionValid(x, salesOrder.cashRegister) &&
          x.promotionRule.orderDiscount!.entitledCustomerTargetSelection === PromotionTargetSelection.All
        );

      const bestPromotion = maxBy(matchedPromos, x => x.createdDate);
      if(!bestPromotion) return undefined;

      return Promotion.applyToOrder(salesOrder, bestPromotion);
    }

    static applyBestPromotionToItem(salesOrderItem: SalesOrderItem, promotions: Promotion[], salesOrder: SalesOrder): Promotion | undefined {
      const productPromotions = promotions
        .filter(x =>
          !!x.promotionRule.productDiscount &&
          Promotion.isLineItemPromotionValid(salesOrderItem, x, salesOrder)
        );

      const buyXGetYPromotions = promotions
        .filter(x =>
          !!x.promotionRule.buyXGetY &&
          Promotion.isLineItemPromotionValid(salesOrderItem, x, salesOrder)
        );

      const bestProductPromotion = maxBy(productPromotions, x => x.createdDate);
      const bestBuyXGetYPromotion = maxBy(buyXGetYPromotions, x => x.createdDate);

      let appliedPromotion: Promotion | undefined;

      // if (bestBuyXGetYPromotion) {
        // const buyXGetYRule = bestBuyXGetYPromotion.promotionRule.buyXGetY!;
        // const prerequisiteQuantity = buyXGetYRule.prerequisiteQuantity;
        // const entitledQuantity = buyXGetYRule.entitledQuantity;

        // // Calculate total quantity of eligible items in the order
        // const eligibleItems = salesOrder.salesOrder_details.filter(item =>
        //   Promotion.isLineItemPromotionValid(item, bestBuyXGetYPromotion, salesOrder)
        // );
        // const totalEligibleQuantity = sumBy(eligibleItems, 'quantity');

        // const applicableSets = Math.floor(totalEligibleQuantity / (prerequisiteQuantity + entitledQuantity));

        // if (applicableSets > 0) {
        //   const discountedQuantity = Math.min(salesOrderItem.quantity, applicableSets * entitledQuantity);
        //   const regularQuantity = salesOrderItem.quantity - discountedQuantity;

        //   if (buyXGetYRule.benefitType === BuyXGetYBenefitType.FreeProduct) {
        //     salesOrderItem.item_discount_type = DiscountValueType.FixedAmount;
        //     salesOrderItem.item_discount = discountedQuantity * salesOrderItem.price;
        //   } else if (buyXGetYRule.benefitType === BuyXGetYBenefitType.Discount) {
        //     salesOrderItem.item_discount_type = DiscountValueType.FixedAmount;
        //     salesOrderItem.item_discount = discountedQuantity * (salesOrderItem.price * buyXGetYRule.discountValue / 100);
        //   }

        //   salesOrderItem.promotion = bestBuyXGetYPromotion.name;
        //   appliedPromotion = bestBuyXGetYPromotion;

        //   // Apply Product Discount to remaining quantity if available
        //   if (bestProductPromotion && regularQuantity > 0) {
        //     const productDiscountRule = bestProductPromotion.promotionRule.productDiscount!;
        //     let additionalDiscount = 0;

        //     if (productDiscountRule.valueType === DiscountValueType.FixedAmount) {
        //       additionalDiscount = regularQuantity * productDiscountRule.value;
        //     } else if (productDiscountRule.valueType === DiscountValueType.Percentage) {
        //       additionalDiscount = regularQuantity * salesOrderItem.price * (productDiscountRule.value / 100);
        //     }

        //     salesOrderItem.item_discount += additionalDiscount;
        //     salesOrderItem.promotion += ` + ${bestProductPromotion.name}`;
        //   }
        // }
      // }
      /* else */ if (bestProductPromotion) {
        // Apply only Product Discount if BuyXGetY wasn't applicable
        const productDiscountRule = bestProductPromotion.promotionRule.productDiscount!;
        salesOrderItem.item_discount_type = productDiscountRule.valueType;
        salesOrderItem.item_discount = productDiscountRule.value;
        salesOrderItem.promotion = bestProductPromotion.name;
        appliedPromotion = bestProductPromotion;
      }

      return appliedPromotion;
    }
}

export class PromotionRule {
    productDiscount?: ProductDiscountPromotionRule;
    orderDiscount?: OrderDiscountPromotionRule;
    buyXGetY?: BuyXGetYPromotionRule;
}

export class ProductDiscountPromotionRule {
    entitledCashRegisterTargetSelection: PromotionTargetSelection = PromotionTargetSelection.All;
    entitledCashRegisterIds?: string[];
    entitledProductTargetSelection: PromotionTargetSelection = PromotionTargetSelection.All;
    entitledProductSelectionMode?: PromotionSelectionMode;
    entitledProductIds?: string[];
    valueType: DiscountValueType = DiscountValueType.Percentage;
    value!: number;
    period: PromotionPeriod = new PromotionPeriod();

    public static canApply(rule: ProductDiscountPromotionRule, saleOrderItem: SalesOrderItem, cashRegister?: CashRegister) {
      // Check day selection
      if (!PromotionPeriod.isCurrent(rule.period)) return false;

      // Check cash register
      if (
        rule.entitledCashRegisterTargetSelection === PromotionTargetSelection.Entitled
        && (!cashRegister || !rule.entitledCashRegisterIds?.includes(cashRegister.id))
      ) return false;

      // Check item
      if (rule.entitledProductTargetSelection === PromotionTargetSelection.Entitled) {
        const includeProducts = rule.entitledProductSelectionMode === PromotionSelectionMode.Include;
        const excludeProducts = rule.entitledProductSelectionMode === PromotionSelectionMode.Exclude;

        const itemId = saleOrderItem.stock_item_id!.toString();
        const isProductIncluded = includeProducts && rule.entitledProductIds?.includes(itemId);
        const isProductExcluded = excludeProducts && !rule.entitledProductIds?.includes(itemId);

        if (!(isProductIncluded || isProductExcluded)) return false;
      }

      return true;
    }
}

export class OrderDiscountPromotionRule {
    entitledCashRegisterTargetSelection: PromotionTargetSelection = PromotionTargetSelection.All;
    entitledCashRegisterIds?: string[];
    entitledCustomerTargetSelection: PromotionTargetSelection = PromotionTargetSelection.All;
    promoCode?: string;
    valueType: DiscountValueType = DiscountValueType.Percentage;
    value!: number;
    period: PromotionPeriod = new PromotionPeriod();

    public static canApply(rule: OrderDiscountPromotionRule, cashRegister?: CashRegister) {
      return PromotionPeriod.isCurrent(rule.period) &&
             (rule.entitledCashRegisterTargetSelection !== PromotionTargetSelection.Entitled ||
              cashRegister && rule.entitledCashRegisterIds?.includes(cashRegister.id));
    }
}

export class BuyXGetYPromotionRule {
    entitledCashRegisterTargetSelection: PromotionTargetSelection = PromotionTargetSelection.All;
    entitledCashRegisterIds?: string[];
    period: PromotionPeriod = new PromotionPeriod();
    prerequisiteQuantity!: number;
    prerequisiteProductTargetSelection: PromotionTargetSelection = PromotionTargetSelection.All;
    prerequisiteProductSelectionMode?: PromotionSelectionMode;
    prerequisiteProductIds?: string[];
    entitledProductTargetSelection: PromotionTargetSelection = PromotionTargetSelection.All;
    entitledProductSelectionMode?: PromotionSelectionMode;
    entitledProductIds?: string[];
    benefitType: BuyXGetYBenefitType = BuyXGetYBenefitType.FreeProduct;
    entitledQuantity!: number;
    discountValue!: number;

    public static canApply(rule: BuyXGetYPromotionRule, saleOrderItem: SalesOrderItem, salesOrder: SalesOrder) {
      // Check day selection
      if (!PromotionPeriod.isCurrent(rule.period)) return false;

      // Check cash register
      if (
        rule.entitledCashRegisterTargetSelection === PromotionTargetSelection.Entitled
        && (!salesOrder.cashRegister || !rule.entitledCashRegisterIds?.includes(salesOrder.cashRegister.id))
      ) return false;

      // check prerequisite
      if (rule.prerequisiteQuantity > 0) {
        const isEntitled = rule.prerequisiteProductTargetSelection === PromotionTargetSelection.Entitled;
        const includeProducts = isEntitled && rule.prerequisiteProductSelectionMode === PromotionSelectionMode.Include;
        const excludeProducts = isEntitled && rule.prerequisiteProductSelectionMode === PromotionSelectionMode.Exclude;
        const orderQuantity = salesOrder.salesOrder_details.reduce((acc, item) => {
          const productId = item.stock_item_id!.toString();
          const isProductIncluded = includeProducts && rule.prerequisiteProductIds?.includes(productId);
          const isProductExcluded = excludeProducts && !rule.prerequisiteProductIds?.includes(productId);
          const shouldInclude = !isEntitled || isProductIncluded || isProductExcluded;
          return shouldInclude ? acc + item.quantity : acc;
        }, 0);

        if (orderQuantity <= rule.prerequisiteQuantity) return false;
      }

      // Check item
      if (rule.entitledProductTargetSelection === PromotionTargetSelection.Entitled) {
        const includeProducts = rule.entitledProductSelectionMode === PromotionSelectionMode.Include;
        const excludeProducts = rule.entitledProductSelectionMode === PromotionSelectionMode.Exclude;

        const itemId = saleOrderItem.stock_item_id!.toString();
        const isProductIncluded = includeProducts && rule.entitledProductIds?.includes(itemId);
        const isProductExcluded = excludeProducts && !rule.entitledProductIds?.includes(itemId);

        if (!(isProductIncluded || isProductExcluded)) return false;
      }

      return true;
    }
}

export class PromotionPeriod {
    from!: Date;
    to!: Date;
    daySelection: PromotionDaySelection = PromotionDaySelection.All;
    days?: string[];

    static isCurrent(period: PromotionPeriod) {
      return period.daySelection === PromotionDaySelection.All
        || period.days?.includes(DayOfWeek[moment().day()].toString());
    }
}

export enum PromotionTargetSelection {
    All = 0,
    Entitled = 1
}

export enum PromotionSelectionMode {
    Include = 0,
    Exclude = 1
}

export enum DiscountValueType {
    FixedAmount = 0,
    Percentage = 1
}

export enum PromotionDaySelection {
    All = 0,
    Prerequisite = 1
}

export enum BuyXGetYBenefitType {
    FreeProduct = 0,
    Discount = 1
}

export enum PromotionStatus {
    Past = 0,
    Current = 1,
    Upcoming = 2
}

export enum DayOfWeek {
    SUN = 0,
    MON = 1,
    TUE = 2,
    WED = 3,
    THU = 4,
    FRI = 5,
    SAT = 6
}

export enum PromotionType {
    ProductDiscount = 0,
    OrderDiscount = 1,
    BuyXGetY = 2
}
