<div class="dropzone" appDrag (files)="filesDropped($event)">
    <div *ngIf="!files.length" class="text-wrapper">
        <icon fontSize="48" name='backup' class="upload-icon">
        </icon>
        <div class="centered"> {{ 'buttons.dragAndDropFile' | translate }}</div>
        <div class="centered">({{ 'assets.or' | translate }})</div>
        <input #fileInput type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            class="hide" (change)="filesDropped(fileInput.files)">
        <button mat-flat-button color="primary" (click)="fileInput.click()" id="btn_selectFilesFromDevice">
            {{ 'buttons.chooseFile' | translate }}
        </button>
    </div>
    <div *ngIf="files.length" class="text-wrapper">
        <div *ngIf="loading">
            {{ 'agGrid.loadingOoo' | translate }}
        </div>
        <div *ngIf="!loading">
            <div *ngFor="let file of files; let i = index" class="file">
                <button mat-icon-button (click)="deleteFile()" class="delete" id="btn_removeFile">
                    <icon name="delete" color="warn"></icon>
                </button>
                <icon name='description' fontSize="64" class="description-icon">
                </icon>
                <span id="lbl_fileName">
                    {{ file.name }}
                </span>
            </div>
        </div>
    </div>
</div>