<div class="dialog__height">
    <div mat-dialog-title>
        <div class="dialog__header">
            <h4 class="typo-title typo-color--primary">
                {{ "codeConfiguration.title" | translate }}
            </h4>
            <button id="btn_close" mat-icon-button mat-dialog-close>
                <icon name="close"></icon>
            </button>
        </div>
    </div>
    <form [formGroup]="codeConfigurationForm" (ngSubmit)="onSubmit()">
        <mat-dialog-content class="padding-top-s">
            <div class="row" [ngClass]="{'flex-row-reverse': ('uiDirection' | translate) === 'rtl'}">
                <div class="col-sm-4">
                    <div class="matInput--full">
                        <mat-form-field class="mat-custom__input mat-custom__input--bg-white">
                            <mat-label>{{ "codeConfiguration.prefix" | translate }}</mat-label>
                            <input matInput id="textbox_codeConfigurationPrefix" formControlName="prefix" dir="ltr"
                                placeholder="{{ 'codeConfiguration.prefix' | translate }}" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-sm-8">
                    <div class="matInput--full ">
                        <mat-form-field hideRequiredMarker class="mat-custom__input mat-custom__input--bg-white">
                            <mat-label>{{ "codeConfiguration.number" | translate }}</mat-label>
                            <input matInput id="textbox_codeConfigurationSequence" formControlName="sequence" required
                                dir="ltr" placeholder="{{ 'codeConfiguration.number' | translate }}" />
                            <mat-error *ngIf="submitted && f.sequence.errors?.['required']">
                                {{ "codeConfiguration.validation.sequenceRequired" | translate }}
                            </mat-error>
                            <mat-error *ngIf="submitted && f.sequence.errors?.['pattern']">
                                {{ "codeConfiguration.validation.sequencePattern" | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions class="justify-content-end">
            <button mat-button type="button" id="btn_discard" mat-dialog-close>
                {{'buttons.cancel' | translate }}
            </button>
            <button mat-flat-button type="submit" id="btn_saveDialog" color="primary">
                {{'buttons.SAVE' | translate }}
            </button>
        </mat-dialog-actions>
    </form>
</div>