import { TranslateService } from "@ngx-translate/core";

export function setOverlayNoRowsTemplate(translateService: TranslateService) {
    return `<div>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="50" height="50"
                viewBox="0 0 200.000000 200.000000" preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                    <path
                        d="M652 1990 c-300 -54 -547 -282 -628 -580 -28 -102 -25 -302 5 -400 91 -293 303 -490 597 -555 59 -14 104 -16 193 -12 131 6 215 28 324 85 l68 35 272 -270 c293 -292 310 -304 393 -288 51 9 110 68 119 119 16 83 4 100 -288 393 l-270 272 35 68 c57 109 79 193 85 324 4 89 2 134 -12 193 -65 295 -265 510 -555 596 -85 25 -251 35 -338 20z m294 -90 c209 -53 366 -179 464 -372 55 -111 74 -203 68 -346 -6 -131 -29 -212 -95 -325 -47 -79 -161 -193 -240 -240 -113 -66 -194 -89 -325 -95 -189 -7 -338 40 -480 153 -274 216 -339 634 -144 927 165 249 469 369 752 298z m496 -1227 c26 -26 48 -52 48 -57 0 -14 -92 -106 -105 -106 -6 0 -33 23 -60 50 l-49 50 54 55 c30 30 57 55 59 55 3 0 27 -21 53 -47z m478 -513 c0 -46 -33 -80 -78 -80 -32 0 -51 16 -219 182 l-183 182 57 58 58 59 183 -184 c163 -165 182 -188 182 -217z" />
                    <path
                        d="M632 1826 c-29 -8 -85 -29 -125 -49 -131 -66 -222 -158 -287 -292 -46 -96 -60 -159 -60 -268 1 -344 269 -617 611 -619 120 -1 181 13 289 65 119 58 219 158 277 277 52 108 66 169 65 289 -1 154 -55 293 -161 413 -70 80 -201 157 -313 183 -76 18 -223 18 -296 1z m299 -82 c230 -68 400 -292 399 -528 -1 -136 -63 -279 -165 -381 -131 -131 -303 -186 -481 -154 -96 17 -216 81 -287 152 -109 109 -157 228 -157 387 0 154 43 262 148 372 111 117 226 166 388 167 64 1 120 -5 155 -15z" />
                    <path
                        d="M757 1675 c-38 -37 -16 -60 68 -70 121 -14 246 -99 299 -202 32 -64 48 -78 75 -63 26 13 27 40 2 89 -71 139 -213 236 -372 256 -45 6 -59 4 -72 -10z" />
                    <path
                        d="M1185 1244 c-19 -20 -16 -43 8 -58 37 -23 77 35 41 61 -23 17 -29 16 -49 -3z" />
                </g>
            </svg>
            <h3 class="my-3">
                ${translateService.instant('agGrid.noResultsFound')}
            </h3>
            <p class="text-muted" style="font-size: 15px;">
                ${translateService.instant('agGrid.tryAdjustingYourSearch')}
            </p>
        </div>`;
}