import { SyncStatus, environmentInfo } from "@shared";
import { DeviceInfo } from "ngx-device-detector";
import { UserProfile } from "./user-profile";
import { Guid } from "guid-typescript";

export class Metadata {
    localId!: string;
    createdDate!: Date;
    channel: string = 'Edara PWA';
    organizationInfo!: OrganizationInfo;
    createdBy!: UserProfile;
    syncInfo?: SyncInfo;
    deviceInfo?: DeviceInfo;
    environmentInfo?: environmentInfo;
    replicationInfo?: replicationInfo;

    static createMetadata(syncStatus: SyncStatus, organizationInfo: OrganizationInfo): Metadata {
        const metadata = new Metadata();
        metadata.localId = Guid.create().toString();
        metadata.syncInfo = {
            syncStatus: SyncStatus[syncStatus]
        }
        metadata.organizationInfo = organizationInfo;
        return metadata;
    }
}

export class OrganizationInfo {
    id!: string;
    name!: string;
}

export class SyncInfo {
    syncStatus!: string;
    syncedDate?: Date;
    syncedBy?: UserProfile;
    syncStatusMessage?: string;
    processingStartedAt?: Date;
    processingCompletedAt?: Date;
}

export class replicationInfo {
    replicaStorageType: string = ReplicaStorageType.LiteDB;
    replicationDate?: Date;
    restoreDate?: Date;
}

export enum ReplicaStorageType {
    LiteDB = "LiteDB"
}