import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoadingService } from '@shared/services/loading.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
  export class LoadingComponent implements OnInit, OnDestroy {

    loading = false;
    private subscription?: Subscription;
    constructor(private loadingService: LoadingService) { }

    ngOnInit() {
      this.subscription = this.loadingService.getStatus().subscribe(loading => {
        this.loading = loading;
      });
    }

    ngOnDestroy() {
      this.subscription?.unsubscribe();
    }
  }
