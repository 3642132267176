import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { notEmptyValidator } from '../../validators/not-empty.validator';
import { isEqual } from 'lodash';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss']
})
export class AutoCompleteComponent implements OnInit, OnChanges {
  @Input() options: any[] = [];

  _selectedOption: any;

  @Input()
  set selectedOption(value) {
    this._selectedOption = value;
    this.autoCompleteControl.setValue(value);
  }

  get selectedOption() {
    return this._selectedOption;
  }

  @Input() valueProperty: string = '';
  @Input() textProperty: string = '';
  @Input() searchProperties: string[] = [];
  @Input() placeHolder: string = '';
  @Input() label: string = '';
  @Input() infoText = '';
  @Input() selectedOptionsLoading = false;
  _disabled = false;
  get disabled(): boolean { return this._disabled; }
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    if (this._disabled) this.autoCompleteControl.disable();
    else this.autoCompleteControl.enable();
  };
  @Input() required = false;
  @Input() requiredText = '';
  @Input() formSubmitted = false;
  @Input() showActionsButtons = false;
  @Input() enableAddAction = false;
  @Input() inputId = '';
  @Input() matErrorId = '';
  @Input() clearOnTextChange: BooleanInput;
  @Output() searchCompleted = new EventEmitter<any>();
  @Output() editButtonClicked = new EventEmitter<any>();
  @Output() deleteButtonClicked = new EventEmitter<any>();
  @Output() inputCleared = new EventEmitter<boolean>();

  autoCompleteControl = new FormControl();
  filteredOptions?: Observable<any[]>;
  prompt = '" doesn\'t exist';
  noOptions = false;
  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.setFilterdOptions();

    if (this.searchProperties.indexOf(this.textProperty) === -1) {
      this.searchProperties.push(this.textProperty);
    }

    if (this.required) {
      this.autoCompleteControl.setValidators(notEmptyValidator);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
      this.setFilterdOptions();
    }
  }

  displayFn(option: any): string {
    return (option && option[this.textProperty]) ? option[this.textProperty] : '';
  }

  onOptionSelected(value: any) {
    if (value) {
      value[this.textProperty] = this.removePromptFromTextProperty(value[this.textProperty]);
      this.searchCompleted.emit(value);
    }
  }

  onEditOption(option: any) {
    if (option) {
      this.editButtonClicked.emit(option);
    }
  }

  onDeleteOption(option: any) {
    if (option) {
      this.deleteButtonClicked.emit(option);
    }
  }

  onClear(event: any) {
    if (event) {
      this.autoCompleteControl.setValue(null);
      this.inputCleared.emit(true);
    }
  }

  private _filter(queryText: string): any[] {
    const filterValue = queryText?.toLowerCase();
    const result = this.options.filter(option => this.searchProperties.some(property => option[property]?.toLowerCase()?.includes(filterValue)))
      .slice(0, 100);
    this.noOptions = result.length === 0;
    if (this.enableAddAction && this.noOptions) {
      const newOption = {
        [this.textProperty]: '"' + queryText + this.prompt
      };
      result.push(newOption);
    }
    this.autoCompleteControl.setValidators(forbiddenOptionsValidator(this.options));
    return result;
  }

  private removePromptFromTextProperty(textProperty: string) {
    if (textProperty?.endsWith(this.prompt)) {
      textProperty = textProperty.substring(1, textProperty.indexOf(this.prompt));
    }
    return textProperty;
  }

  private setFilterdOptions() {
    this.filteredOptions = this.autoCompleteControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value && value[this.textProperty]),
        tap(_ => this.noOptions = false),
        map(queryText => queryText ? this._filter(queryText) : this.options.slice(0, 100))
      );
  }

  onTextChange(event: Event) {
    event.stopPropagation();
    if (coerceBooleanProperty(this.clearOnTextChange) && this.selectedOption) {
      const value = this.autoCompleteControl.value;
      if (typeof value === 'string' && (!value || (this.selectedOption && this.selectedOption[this.textProperty] !== value))) {
        this.inputCleared.emit(true);
      }
    }
  }

  onAutocompleteOpened() {
    this.document.documentElement.classList.add('overlay-no-scroll')
  }

  onAutocompleteClosed() {
    this.document.documentElement.classList.remove('overlay-no-scroll')
  }
}

export function forbiddenOptionsValidator(options: any[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    // below findIndex will check if control.value is equal to one of our options or not
    const index = options?.findIndex(option => {
      return isEqual(option, control.value);
    });
    return index < 0 ? { required: true } : null;
  };
}
