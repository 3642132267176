export enum AddressState {
    New = 'new',
    Modified = 'modified',
    Deleted = 'deleted'
}

export class CustomerAddress {
    id?: number;
    customer_id!: number;
    name!: string;
    country_id!: number;
    city_id!: number;
    district_id!: number;
    street!: string;
    description?: string;
    is_default?: boolean;
    entity_state?: string;
    sequence?: number;

    public static clone(customerAddress: CustomerAddress): CustomerAddress {
        return Object.assign(new CustomerAddress(), customerAddress);
    }
}

export interface Country {
    id: number;
    name: string;
}

export interface City {
    id: number;
    name: string;
    country_id: number;
}

export interface District {
    id: number;
    name: string;
    city_id: number;
}
