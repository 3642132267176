import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModulePage } from '@shared/models/modules-landing.model';

@Component({
  selector: 'app-landing-list-item',
  templateUrl: './landing-list-item.component.html',
  styleUrls: ['./landing-list-item.component.scss']
})
export class LandingListItemComponent implements OnInit {
  @Input() itemData!: ModulePage | any;
  @Output() onToggleStarred = new EventEmitter<boolean>();
  @Output() onRouteLastTimeClicked = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  toggleStarred() {
    this.onToggleStarred.emit(!this.itemData.isStarred)
  }
  routeLastTimeClicked(e: string) {
    this.onRouteLastTimeClicked.emit(e)
  }
}
