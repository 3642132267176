import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {

    private subject = new Subject<boolean>();
    constructor(private router: Router) {
        // Hide loading on route change
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                // Hide loading
                this.subject.next(false);
            }
        });
    }

    show() {
        this.subject.next(true);
    }

    hide() {
        this.subject.next(false);
    }

    getStatus() {
        return this.subject.asObservable();
    }
}
