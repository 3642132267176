import {DiscountType} from "@shared/enums/discount-type";
import {Tax} from "../../point-of-sale/models/tax.model";
import {ProductUnitOfMeasure} from "../../point-of-sale/models/product";

export class Location {
  id!: string;
  name!: string;
  mobile?: string;
  createdDate?: Date;
}

export class Supplier {
  id!: string;
  name!: string;
  mobile?: string;
  email?: string;
  websiteUrl?: string;
  taxId?: string;
}

export class Customer {
  id!: string;
  name!: string;
  mobile?: string;
  email?: string;
  websiteUrl?: string;
  taxId?: string;
}

export interface Currency {
  id: number;
  symbol: string;
  description: string;
  international_code: string;
  currency_sub_unit: string;
  is_system_currency: boolean;
}

export class DiscountDto {
  value: number = 0;
  type: DiscountType = DiscountType.Amount;
}

export class ProductVariant {
  id?: string;
  title?: string;
  sku!: string;
  partNumber!: string;
  cost?: number;
  salesPrice?: number;
  imageUrl?: string;
  tax!: Tax;
  attributes!: ProductVariantAttributes;

  is_selected?: boolean;
  is_sku_duplicated?: boolean
  is_partNumber_duplicated?: boolean;
  unitOfMeasures?: ProductUnitOfMeasure[] = [];
}

export interface ProductVariantAttributes {
  attribute1: string;
  attribute2: string;
  attribute3: string;
  attribute4: string;
  attribute5: string;
}

export enum InvoiceType
{
  SalesInvoice = 0,
  CreditNote = 1
}
