import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PurchaseInvoiceItem, PurchaseInvoiceLineDiscountType } from '../../models/purchasing-invoice.model';
import { MoneyHelper } from '@shared';

@Component({
  selector: 'app-compound-discount',
  templateUrl: './compound-discount.component.html',
  styleUrls: ['./compound-discount.component.scss']
})
export class CompoundDiscountComponent implements OnInit {

  discountForm = this.formBuilder.group({
    discount1: [0, [Validators.min(0), Validators.max(100)]],
    discount2: [0, [Validators.min(0), Validators.max(100)]],
    discount3: [0, [Validators.min(0), Validators.max(100)]]
  });
  displayedTotalValue: number = 0;

  constructor(private formBuilder: FormBuilder,
    private moneyHelper: MoneyHelper,
    private dialogRef: MatDialogRef<CompoundDiscountComponent>,
    @Inject(MAT_DIALOG_DATA) private lineItem: PurchaseInvoiceItem) { }

  ngOnInit(): void {
    this.setFormValues();
  }

  updateDisplayedTotalValue() {
    const discount1 = this.discountForm.controls.discount1.value || 0;
    const discount2 = this.discountForm.controls.discount2.value || 0;
    const discount3 = this.discountForm.controls.discount3.value || 0;
    const remaining1 = 1 - discount1 / 100;
    const remaining2 = remaining1 * (1 - discount2 / 100);
    const remaining3 = remaining2 * (1 - discount3 / 100);
    this.displayedTotalValue = (1 - remaining3) * 100;
  }

  onSubmit() {
    if (this.discountForm.invalid) {
      return;
    }

    this.lineItem.compoundDiscount = {
      discount1: this.discountForm.controls.discount1.value || 0,
      discount2: this.discountForm.controls.discount2.value || 0,
      discount3: this.discountForm.controls.discount3.value || 0
    };

    let totalDiscount = 0;
    if (this.lineItem.discountType === PurchaseInvoiceLineDiscountType.Value) {
      const baseAmount = this.lineItem.getGrossTotal || 0;
      const firstDiscountedAmount = baseAmount - (baseAmount * this.lineItem.compoundDiscount.discount1 / 100);
      const secondDiscountedAmount = firstDiscountedAmount - (firstDiscountedAmount * this.lineItem.compoundDiscount.discount2 / 100);
      const finalDiscountedAmount = secondDiscountedAmount - (secondDiscountedAmount * this.lineItem.compoundDiscount.discount3 / 100);
      totalDiscount = baseAmount - finalDiscountedAmount;
    } else if (this.lineItem.discountType === PurchaseInvoiceLineDiscountType.Percentage) {
      const remaining1 = 1 - this.lineItem.compoundDiscount.discount1 / 100;
      const remaining2 = remaining1 * (1 - this.lineItem.compoundDiscount.discount2 / 100);
      const remaining3 = remaining2 * (1 - this.lineItem.compoundDiscount.discount3 / 100);
      totalDiscount = (1 - remaining3) * 100;
    }

    this.dialogRef.close(this.moneyHelper.round(totalDiscount));
  }

  private setFormValues() {
    if (this.lineItem.compoundDiscount) {
      this.discountForm.controls.discount1.setValue(this.lineItem.compoundDiscount.discount1);
      this.discountForm.controls.discount2.setValue(this.lineItem.compoundDiscount.discount2);
      this.discountForm.controls.discount3.setValue(this.lineItem.compoundDiscount.discount3);

      this.updateDisplayedTotalValue();
    }
  }

}
