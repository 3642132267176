import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent implements OnInit {
  @Input() on?: string;
  @Input() off?: string;
  @Input() name?: string;
  @Input() autofocus: boolean = false;
  @Input() initValue: boolean = false;
  @Input() checked: boolean = false;
  @Input() disabled: boolean = false;
  @Output() changed = new EventEmitter<boolean>();

  constructor(private translateService: TranslateService) { }

  ngOnInit() {
    this.checked = !!this.initValue;
  }

  checkChanged(e: MatSlideToggleChange) {
    this.checked = e.checked;
    this.changed.emit(e.checked);
  }

  arrowLeft() {
    this.checked = this.translateService.currentLang !== 'en';
    this.changed.emit(this.checked);
  }

  arrowRight() {
    this.checked = this.translateService.currentLang === 'en';
    this.changed.emit(this.checked);
  }

}
