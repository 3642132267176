import { Directive, ElementRef, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[autofocus]'
})
export class AutofocusDirective implements OnInit, OnChanges {

    private _autofocus?: boolean;
    constructor(private el: ElementRef) {
    }

    @Input() set autofocus(condition: boolean) {
        this._autofocus = condition !== false;
    }

    ngOnInit() {
        if (this._autofocus || typeof this._autofocus === 'undefined') {
            this.el.nativeElement.focus();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes['autofocus'].currentValue) {
            setTimeout(() => this.el.nativeElement.focus());
        }
    }
}
