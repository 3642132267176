export interface KSAEInvoiceResult {
    Status: KSAEInvoiceStatus;
    InvoiceHash: string;
    UserName: number;
    IntegrationType: KSAEInvoiceType;
    SubmitDate: Date;
    XmlFileName: string;
    Warnings: KSAEInvoiceResultWarning[];
    Errors: KSAEInvoiceResultError[];
}

export interface KSAEInvoiceResultWarning {
    type: string;
    code: string;
    category: string;
    message: string;
    status: string;
}

export interface KSAEInvoiceResultError {
    type: string;
    code: string;
    category: string;
    message: string;
    status: string;
}

export enum KSAEInvoiceStatus {
    ReadyForReporting,
    NotReported,
    ReportedWithWarnings,
    Reported,
    ReadyForClearance,
    NotCleared,
    ClearedWithWarnings,
    Cleared
}

export enum KSAEInvoiceType {
    Simplified_Invoice,
    Simplified_CreditNote,
    Standard_Invoice,
    Standard_CreditNote,
    Simplified_DebitNote
}
