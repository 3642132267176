import moment from 'moment';

export class QueryStringHelper {

    toQueryString(obj: any) {
        const parts = [];
        // tslint:disable-next-line:forin
        for (const property in obj) {
            const value = obj[property];
            if (!this.isEmpty(value)) {
                if (Array.isArray(value)) {
                    value.forEach(element => {
                        parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(element));
                    });
                } else if (value instanceof Date) {
                    parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(value.toISOString()));
                } else if (value instanceof moment) {
                    parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(moment(value).toDate().toISOString()));
                } else {
                    parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(value));
                }
            }
        }
        return parts.join('&');
    }

    private isEmpty(value: any) {
        if (value === null || value === undefined) {
            return true;
        }

        if (Array.isArray(value) && value.length === 0) {
            return true;
        }

        return false;
    }
}
