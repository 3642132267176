export enum DialogModeEnum {
    New = 0,
    Edit = 1,
    Display = 2
}

export enum SalesOrderDialogTypeEnum {
    none = 0,
    itemDiscount = 1,
    orderDiscount = 2,
    note = 3,
    tags = 4
}
