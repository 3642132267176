import { INativeServiceSyncable } from "./native-service-syncable";

export class CashTransaction {
    id?: string;
    cashRegister!: string;
    transactionType!: CashTransactionType;
    amount!: number;
    recordedBy!: string;
    recordedDate!: Date;
    note?: string;
}

export enum CashTransactionType {
    CashIn = 1,
    CashOut = 2
}

export class CashInCashRegister {
    id?: string;
    amount!: number;
    recordedBy!: string;
    recordedDate!: Date;
    note?: string;
    cashRegister!: string;
    transactionType!: CashTransactionType;
    syncMetadata?: INativeServiceSyncable;
}

export class CashOutCashRegister {
    id?: string;
    amount!: number;
    recordedBy!: string;
    recordedDate!: Date;
    note?: string;
    cashRegister!: string;
    transactionType!: CashTransactionType;
    syncMetadata?: INativeServiceSyncable;
}

export class CashTransactionLog {
    amount!: number;
    note?: string;
    transactionType!: CashTransactionType;
    recordedBy!: string;
    recordedDate!: Date;
    syncMetadata?: INativeServiceSyncable;
}