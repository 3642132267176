<div class="dialog__height">
    <mat-dialog-content>
        <form id="compoundDiscountForm" [formGroup]="discountForm" (ngSubmit)="onSubmit()">
            <div class="bg-sys-surface border-radius-rounded p-4">
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field>
                            <mat-label>
                                {{ "invoicing.purchaseInvoice.form.discount1" | translate }}
                            </mat-label>
                            <input id="textbox_compoundDiscountFormDiscount1" matInput type="number" min="0" max="100"
                                formControlName="discount1" class="font-size-m text-align-end" #discount1Input
                                (focus)="discount1Input.select()" (input)="updateDisplayedTotalValue()" />
                            <span matSuffix class="text-gray font-size-m">
                                %
                            </span>
                            <mat-error *ngIf="discountForm.controls['discount1'].errors">
                                {{ "invoicing.purchaseInvoice.form.validation.percentageValue" | translate }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>
                                {{ "invoicing.purchaseInvoice.form.discount2" | translate }}
                            </mat-label>
                            <input id="textbox_compoundDiscountFormDiscount2" matInput type="number" min="0" max="100"
                                formControlName="discount2" class="font-size-m text-align-end" #discount2Input
                                (focus)="discount2Input.select()" (input)="updateDisplayedTotalValue()" />
                            <span matSuffix class="text-gray font-size-m">
                                %
                            </span>
                            <mat-error *ngIf="discountForm.controls['discount2'].errors">
                                {{ "invoicing.purchaseInvoice.form.validation.percentageValue" | translate }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>
                                {{ "invoicing.purchaseInvoice.form.discount3" | translate }}
                            </mat-label>
                            <input id="textbox_compoundDiscountFormDiscount3" matInput type="number" min="0" max="100"
                                formControlName="discount3" class="font-size-m text-align-end" #discount3Input
                                (focus)="discount3Input.select()" (input)="updateDisplayedTotalValue()" />
                            <span matSuffix class="text-gray font-size-m">
                                %
                            </span>
                            <mat-error *ngIf="discountForm.controls['discount3'].errors">
                                {{ "invoicing.purchaseInvoice.form.validation.percentageValue" | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="d-flex justify-content-between align-items-center bg-surface-2 p-3">
                            <span class="font-size-m">
                                {{ "invoicing.purchaseInvoice.form.total" | translate }}
                            </span>
                            <span class="font-size-m">
                                {{ displayedTotalValue | number: '1.2-2' }}%
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </mat-dialog-content>

    <mat-dialog-actions class="justify-content-end">
        <button id="btn_compoundDiscountDialogGotIt" mat-button mat-dialog-close type="button">
            {{'buttons.gotIt' | translate }}
        </button>
        <button id="btn_compoundDiscountDialogSave" mat-button color="primary" type="submit" form="compoundDiscountForm"
            class="margin-inline-start-s">
            {{'buttons.save' | translate }}
        </button>
    </mat-dialog-actions>
</div>