<span class='currency-container'>
  <span class='currency' [ngClass]="currencyCode" *ngIf="_displayCodeBeforAmount && currencyCode">
    {{ currencyCode }}
  </span>

  <span *ngIf="amount ?? 0 | currencyFormatterV3 as netTotal; else total">{{ netTotal }}</span>

  <span class='currency' [ngClass]="currencyCode" *ngIf="!_displayCodeBeforAmount && currencyCode">
    {{ currencyCode }}
  </span>
</span>

<ng-template #total>
  {{ amount }}
</ng-template>