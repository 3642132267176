import { Component } from '@angular/core';
import { AppService } from '@core/services/app.service';

@Component({
  selector: 'app-quick-actions',
  templateUrl: './quick-actions.component.html',
  styleUrls: ['./quick-actions.component.scss']
})
export class QuickActionsComponent {
  constructor(public appService: AppService) { }
}
