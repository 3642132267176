import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class IPHelper {
    private httpClient: HttpClient;
    constructor(private handler: HttpBackend) {
        this.httpClient = new HttpClient(handler);
    }

    getClientIP() {
        return this.httpClient.get<any>('https://jsonip.com');
    }

    ipCheckRange(ip: string, range: any) {
        if (typeof (range) === 'string') {
            return this.ipToNumber(range) === this.ipToNumber(ip);
        } else if (typeof (range) === 'object') {
            return this.ipToNumber(range[0]) <= this.ipToNumber(ip) && this.ipToNumber(range[1]) >= this.ipToNumber(ip);
        }
        return false;
    }

    private ipToNumber(ip: string) {
        return Number(ip.split('.').map(d => ('000' + d).substr(-3)).join(''));
    }
}
