import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

export const convertArabicNumbersToEnglish = (str: string) => {
    var
        englishNumbers = [/0/g, /1/g, /2/g, /3/g, /4/g, /5/g, /6/g, /7/g, /8/g, /9/g],
        arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];

    if (typeof str === 'string') {
        for (var i: any = 0; i < 10; i++) {
            str = str.replace(englishNumbers[i], i).replace(arabicNumbers[i], i);
        }
    }
    return str;
}


@Pipe({
    name: 'fromNowPipe'
})
export class FromNowPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer, public translate: TranslateService) { }
    transform(date: any) {
        moment.locale(this.translate.currentLang === 'ar' ? 'ar-sa' : 'en-us');
        var timeZone = moment.utc(date).format();
        return convertArabicNumbersToEnglish(moment(timeZone).fromNow());
    }

}
