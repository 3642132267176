import { SalesOrderItem } from './sales-order-item';
import { SalesOrderInstallment } from './sales-order-installment';
import { OfflineMetadata } from './offline-metadata';
import { Customer } from './customer';
import { SalesPerson } from './sales-person';
import { Warehouse } from './warehouse';
import { StockItem, UnitOfMeasure } from './stock-item';
import { ServiceItem } from './service-item';
import { SalesBundle } from './sales-bundle';
import { MoneyHelper } from '@shared/services/money-helper.service';
import { InventoryManagementMethod } from './Inventory-management-method';
import { SalesOrderSettings } from './sales-order-settings';
import { CashRegister } from './cash-register';
import { isEmpty } from 'lodash';

const moneyHelper = new MoneyHelper();
export class SalesOrder {
    id?: number;
    organization_id?: string;
    document_type = 'SO';
    document_code?: string;
    document_date!: Date;
    paper_number?: string;
    currency_id?: number;
    customer_id!: number;
    customer?: Customer;
    salesPerson_id!: number;
    salesperson?: SalesPerson;
    warehouse_id!: number;
    warehouse?: Warehouse;
    gross_total!: number;
    sub_total!: number;
    discount = 0;
    discount_rate = 0;
    net_total!: number;
    taxable = true;
    apply_tax_after_discount?: boolean;
    tax?: number;
    cash_amount?: number;
    onAccount_ammount?: number;
    cash_paid?: number;
    order_status?: string;
    notes?: string;
    external_id?: string;
    channel = 'Edara PWA';
    document_number?: string;
    salesOrder_details: SalesOrderItem[] = [];
    salesOrder_installments: SalesOrderInstallment[] = [];
    settings: SalesOrderSettings = {
        pricesIncludeVAT: false,
        noOfDecimalPlaces: 2,
        useBatchNumber: false,
        inventoryManagementMethod: InventoryManagementMethod.None,
        deductDiscountFromNetTotal: false
    };
    offlineMetadata?: OfflineMetadata;
    tags: string[] = [];
    promo_code?: string;
    cashRegister?: CashRegister;
    discountValue: number = 0;
    fulfillment_date?: Date | string;
    invoice_number?: string;
    device_serial?: string;

    get totalItemsCount(): number {
        let totalItemsCount = 0;
        this.salesOrder_details.forEach(function (orderItem) {
            totalItemsCount += orderItem.quantity;
        });
        return totalItemsCount;
    }

    get totalItemsDiscount(): number {
        let totalDiscount = 0;
        const pricesIncludeVAT = this.settings.pricesIncludeVAT && this.taxable;
        this.salesOrder_details.forEach(function (orderItem) {
            totalDiscount += orderItem.totalDiscount(pricesIncludeVAT);
        });
        return totalDiscount;
    }

    get discountAmount() {
        if (this.discountValue > 0) {
            if (this.settings.deductDiscountFromNetTotal) {
                const taxRate = this.salesOrder_details.find(item => !!item.tax_rate)?.tax_rate ?? 0;
                this.discount = this.discountValue * (100 / (100 + taxRate));
                return this.discount;
            } else {
                this.discount = this.discountValue > this.itemsNetAmount ? this.itemsNetAmount : this.discountValue;
                return this.discount;
            }
        } else {
            this.discount = 0;
            return (this.itemsNetAmount * this.discount_rate) / 100;
        }
    }

    get itemsNetAmount() {
        let itemNetAmount = 0;
        const pricesIncludeVAT = this.settings.pricesIncludeVAT && this.taxable;
        this.salesOrder_details.forEach(function (orderItem) {
            itemNetAmount += orderItem.netAmount(pricesIncludeVAT);
        });
        return itemNetAmount;
    }

    get totalDiscount(): number {
        return this.totalItemsDiscount + this.discountAmount;
    }

    get totalVAT(): number {
        let totalVAT = 0;
        if (this.taxable === true) {
            for (let index = 0; index < this.salesOrder_details.length; index++) {
                const orderItem = this.salesOrder_details[index];
                totalVAT += this.calculateItemTax(orderItem);
            }
        }
        // this.tax = moneyHelper.round(totalVAT, this.noOfDecimalPlaces);
        this.tax = totalVAT;
        return this.tax;
    }

    get grossTotal(): number {
        let grossTotal = 0;
        const pricesIncludeVAT = this.settings.pricesIncludeVAT && this.taxable;
        this.salesOrder_details.forEach(function (orderItem) {
            grossTotal += orderItem.baseAmount(pricesIncludeVAT);
        });
        this.gross_total = grossTotal;
        return moneyHelper.round(this.gross_total, this.settings.noOfDecimalPlaces);
    }

    get subTotal(): number {
        return moneyHelper.round(this.gross_total - this.totalDiscount, this.settings.noOfDecimalPlaces);
    }

    get netTotal(): number {
        this.net_total = moneyHelper.round(this.gross_total - this.totalDiscount + this.totalVAT, this.settings.noOfDecimalPlaces);
        return this.net_total;
    }

    get maxDiscountValue(): number {
        return moneyHelper.round(this.gross_total - this.totalItemsDiscount + this.totalVAT, this.settings.noOfDecimalPlaces);
    }

    public static clone(salesOrder: SalesOrder): SalesOrder {
        const newSalesOrder: SalesOrder = Object.assign(new SalesOrder(), salesOrder);
        newSalesOrder.salesOrder_details = [];
        for (let index = 0; index < salesOrder.salesOrder_details.length; index++) {
            newSalesOrder.salesOrder_details.push(SalesOrderItem.clone(salesOrder.salesOrder_details[index]));
        }

        newSalesOrder.cashRegister = Object.assign({}, salesOrder.cashRegister);

        return newSalesOrder;
    }

    public addOrderItem(orderItem: SalesOrderItem) {
        // Add item to SO's details list
        this.salesOrder_details.unshift(orderItem);

        // Reset SO details serials
        this.resetOrderItemsSerials();
    }

    public addStockItem
        (stockItem: StockItem, quantity: number, uom?: UnitOfMeasure, expiryDate?: Date, mergeSimilarItems?: boolean): SalesOrderItem {

        if (mergeSimilarItems) {
            // Find Item in salesOrder_details
            const retreivedLineItem = this.salesOrder_details.find(itm => itm.stock_item_id === stockItem.id &&
                (!uom || itm.unit_of_measure_id === uom.unit_of_measure_id) &&
                (!expiryDate || itm.expiry_date === expiryDate));

            // If StockItem exists (sum quantities)
            if (retreivedLineItem) {
                retreivedLineItem.quantity += quantity;
                retreivedLineItem.addRelatedSerial(stockItem.stockItemSerial!);
                retreivedLineItem.refresh(this.settings);
                retreivedLineItem.updateBatchQuntity(this.settings);
                return retreivedLineItem;
            }
        }

        const orderItem = new SalesOrderItem(stockItem, undefined, undefined, quantity, uom, expiryDate, this.settings, this.warehouse_id);

        orderItem.refresh(this.settings);

        orderItem.updateBatchQuntity(this.settings);

        // Add item to SO's details list
        this.salesOrder_details.unshift(orderItem);

        // Reset SO details serials
        this.resetOrderItemsSerials();

        return orderItem;
    }

    public addServiceItem(serviceItem: ServiceItem, mergeSimilarItems?: boolean) {

        if (mergeSimilarItems) {
            // Find Item in salesOrder_details
            const retreivedLineItem = this.salesOrder_details.find(itm => itm.service_item_id === serviceItem.id);
            // If ServiceItem exist (sum quantities)
            if (retreivedLineItem) {
                retreivedLineItem.quantity += 1;
                return;
            }
        }

        // Add item SO's details list
        this.salesOrder_details.unshift(new SalesOrderItem(undefined, serviceItem, undefined, 1));

        // Reset items serials
        this.resetOrderItemsSerials();
    }

    public addSalesBundle(salesBundle: SalesBundle, mergeSimilarItems?: boolean) {

        if (mergeSimilarItems) {
            // Find Item in salesOrder_details
            const retreivedLineItem = this.salesOrder_details.find(itm => itm.sales_bundle_id === salesBundle.id);

            // If SalesBundle exist (sum quantities)
            if (retreivedLineItem) {
                retreivedLineItem.quantity += 1;
                return;
            }
        }

        salesBundle = SalesBundle.clone(salesBundle);
        this.salesOrder_details.unshift(new SalesOrderItem(undefined, undefined, salesBundle, 1));

        // Reset items serials
        this.resetOrderItemsSerials();
    }

    public removeOrderItem(serial: number) {
        // Remove SO item
        this.salesOrder_details.splice(serial - 1, 1);

        // Reset SO details serials
        this.resetOrderItemsSerials();
    }

    public removeSelectedOrderItems() {
        this.salesOrder_details = this.salesOrder_details.filter(itm => itm.isSelected !== true);

        // Reset SO details serials
        this.resetOrderItemsSerials();
    }

    public refresh() {

    }

    /**
   * Remove unneeded properties, should be used before sending to the back-end API
   */
    public minify(): SalesOrder {
        const minified = SalesOrder.clone(this);

        delete minified.id;
        delete minified.warehouse;
        delete minified.salesperson;
        delete minified.offlineMetadata;
        delete minified.cashRegister;

        minified.salesOrder_details.forEach(item => {
            item.minify();
        });

        return minified;
    }

    public groupOrderItemsByBatch(): SalesOrder {
        const so = SalesOrder.clone(this);
        if (so.settings.useBatchNumber) {
            if (so.salesOrder_details?.length === 0) return so;
            let groupedItems: SalesOrderItem[] = [];
            so.salesOrder_details.forEach(item => {
                groupedItems = groupedItems.concat(item.toListGroupedByBatch());
            });
            so.salesOrder_details = groupedItems;
        }
        return so;
    }

    private resetOrderItemsSerials() {
        this.salesOrder_details.forEach(function (orderItem, index) {
            orderItem.serial = index + 1;
        });
    }

    private calculateItemTax(orderItem: SalesOrderItem): number {
        const pricesIncludeVAT = this.settings.pricesIncludeVAT && this.taxable;
        if (this.apply_tax_after_discount === true) {
            let lineDiscount = 0;
            if (this.discountAmount > 0 && orderItem.netAmount(pricesIncludeVAT) > 0) {
                const lineWeight = orderItem.netAmount(pricesIncludeVAT) / this.itemsNetAmount;
                lineDiscount = lineWeight * this.discountAmount;
            }
            return (orderItem.netAmount(pricesIncludeVAT) - lineDiscount) * orderItem.taxRate;
        }
        return orderItem.baseAmount(pricesIncludeVAT) * orderItem.taxRate;
    }
}

export enum PaymentType {
    Installments = 'Installments',
    Cash = 'Cash',
    CashOnDelivery = 'CashOnDelivery',
    Credit = 'Credit'
}

export enum CellType {
    None,
    Qty,
    Price,
    DiscountType,
    DiscountValue
}

export interface SyncSalesOrderResult {
    document_code: string;
    external_id: string;
    customer_id: number;
    created_by: number;
    created_date: Date;
    status_code: boolean;
    status_message: string;
}

