import { FormGroup } from "@angular/forms";

export function setErrorsToFormControls(formGroup: FormGroup, formControlKeys: string[], error: any) {
    const errorObj = error?.error?.errors;
    if (!errorObj)
        return;

    const errorValue = (Object.values(errorObj) as any)[0][0];

    if (!errorValue)
        return;

    const errorParts = errorValue.split(':');
    if (errorParts[0].trim() === 'MustBeUnique')
        setDuplicateError(formGroup, formControlKeys, errorObj);
}

function setDuplicateError(formGroup: FormGroup, formControlKeys: string[], error: any) {
    const errorKey = Object.keys(error)[0];
    if (!errorKey)
        return;

    const index = formControlKeys.indexOf(errorKey.charAt(0)?.toLowerCase() + errorKey.substring(1));
    if (index === -1)
        return;

    formGroup.controls[formControlKeys[index]].setErrors({ alreadyExists: true });
}