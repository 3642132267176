import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectToArray'
})
export class ObjectToArrayPipe implements PipeTransform {

  transform(value: { [key: string]: string }): { name: string, value: string }[] {
    if (!value) return [];
    return Object.keys(value)
      .filter(key => !!value[key])
      .map(key => ({ name: key, value: value[key] }));
  }

}
