import { AuthService } from '@auth';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SwitchLanguageService } from '../services/switch-language.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private switchLanguageService: SwitchLanguageService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.get('No-Auth') === 'true' || request.headers.get('Authorization') === 'No-Auth') {
      return next.handle(request.clone());
    }

    if (this.authService.isAuthenticated()) {
      request = request.clone({
        setHeaders: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': request.headers.has('Authorization') ?
          request.headers.get('Authorization')! : `Bearer ${this.authService.accessToken}`,
          'TenantId': `${this.authService.currentTenant}`,
          'Accept-Language': this.switchLanguageService.currentLanguage === 'ar' ? 'ar-EG' : 'en-US'
        }
      });
    }

    return next.handle(request);
  }
}
