import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '@auth';

export const rolesGuard: CanActivateFn = (route) => {
  const authService = inject(AuthService);
  const role = route.data['role'];
  if (authService.isAuthenticated()) {
    return authService.isInRole(role);
  }
  return false;
};
