import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class HelpersService {
  isDesktop:boolean = false;
  constructor(private deviceDetector: DeviceDetectorService) {
    this.isDesktop = this.deviceDetector.isDesktop();
   }
}
