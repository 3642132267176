import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoggingService } from '@core/services/logging.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '@auth';

@Injectable()
export class HttpLoggingInterceptor implements HttpInterceptor {

    constructor(
        private injector: Injector) {
        this.authService = injector.get<AuthService>(AuthService);
        this.loggingService = injector.get<LoggingService>(LoggingService);
    }

    private authService: AuthService;
    private loggingService: LoggingService;

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (location.hostname.includes('localhost') || request.headers.get('X-Ignore-Logging') === 'true') {
            return next.handle(request);
        }

        const startedAt = new Date();

        return next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {
                if (event.type === HttpEventType.Response) {

                    const elapsedTime = Date.now() - startedAt.getTime();

                    const logData: any = {
                        '@t': startedAt.toISOString(),
                        '@mt': 'HTTP {Method} {RequestUrl} responded {StatusCode} in {DurationMs} ms',
                        '@l': 'Information',
                        'Method': request.method,
                        'RequestUrl': request.url,
                        'StatusCode': event.status,
                        'DurationMs': elapsedTime,
                        'Host': window.location.hostname,
                        'Protocol': window.location.protocol,
                        'EnvironmentName': environment.name,
                        'ApplicationVersion': environment.version,
                        'UserAgent': navigator.userAgent,
                        'UserName': this.authService.currentUserEmail,
                        'TenantId': this.authService.currentTenant,
                        'Channel': 'Edara PWA'
                    };

                    const requestUrl = new URL(request.url);
                    if (requestUrl.search) {
                        logData.QueryString = requestUrl.search;
                    }

                    if (request.body) {
                        logData.Request = request.body;
                    }

                    if (event.body) {
                        logData.Response = event.body;
                    }

                    this.loggingService.ingest(logData);
                }
            }, (error) => {

                const elapsedTime = Date.now() - startedAt.getTime();

                const logData: any = {
                    '@t': startedAt.toISOString(),
                    '@mt': 'HTTP {Method} {RequestUrl} responded {StatusCode} in {DurationMs} ms',
                    '@l': 'Error',
                    'Method': request.method,
                    'RequestUrl': request.url,
                    'StatusCode': error.status,
                    'DurationMs': elapsedTime,
                    'Host': window.location.hostname,
                    'Protocol': window.location.protocol,
                    'EnvironmentName': environment.name,
                    'ApplicationVersion': environment.version,
                    'UserAgent': navigator.userAgent,
                    'UserName': this.authService.currentUserEmail,
                    'TenantId': this.authService.currentTenant,
                    'ErrorMessage': error.message,
                    'ErrorStack': error.stack,
                    'Channel': 'Edara PWA'
                };

                const requestUrl = new URL(request.url);
                if (requestUrl.search) {
                    logData.QueryString = requestUrl.search;
                }

                if (request.body) {
                    logData.Request = request.body;
                }

                this.loggingService.ingest(logData);
            })
        );
    }

}