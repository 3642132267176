import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { getColorFromEmail } from '@shared/helpers/user-avatar-color-generator';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-lookup-chips',
  templateUrl: './lookup-chips.component.html',
  styleUrls: ['./lookup-chips.component.scss']
})
export class LookupChipsComponent implements OnInit, OnChanges {

  formCtrl = new FormControl();
  autoCompleteControl = new FormControl();
  filteredOptions?: Observable<any[]>;

  @Input() options: any[] = [];

  _selectedOptions: any[] = [];

  @Input()
  set selectedOptions(value) {
    this._selectedOptions = value;
    this.formCtrl.setValue(value);
  }

  get selectedOptions() {
    return this._selectedOptions;
  }

  @Input() valueProperty: string = '';
  @Input() textProperty: string = '';
  @Input() searchProperties: string[] = [];
  @Input() placeHolder: string = '';
  @Input() label: string = '';
  @Input() infoText = '';
  @Input() selectable = true;
  @Input() removable = true;
  @Input() selectedOptionsLoading = false;
  @Input() required = false;
  @Input() requiredText = '';
  @Input() formSubmitted = false;
  @Input() cssClass = ''
  @Input() inputId = '';
  @Input() avatarChipTheme = false;
  @Input() hideRequiredMarker = false;
  @Output() valueChanged = new EventEmitter<any>();

  _disabled = false;
  get disabled(): boolean { return this._disabled; }
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    if (this._disabled) this.autoCompleteControl.disable();
    else this.autoCompleteControl.enable();
  };

  @ViewChild('chipInput') chipInput!: ElementRef;

  ngOnInit() {
    this.setFilterdOptions();

    if (this.searchProperties.indexOf(this.textProperty) === -1) {
      this.searchProperties.push(this.textProperty);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {

      if (this.avatarChipTheme) {
        this.options.forEach(option => {
          option['userInitals'] = option['email'].charAt(0).toLocaleUpperCase();
          option['avatarBackgroundColor'] = getColorFromEmail(option['email']);
        });
      }

      this.setFilterdOptions();
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      const option = this.options.find(item => item[this.textProperty] === value);
      if (option && !this.selectedOptions.includes(option)) {
        this.selectedOptions.push(option);
        this.formCtrl.setValue(this.selectedOptions);
        this.valueChanged.emit(this.selectedOptions);
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.autoCompleteControl.setValue('');
  }

  remove(option: any): void {
    const index = this.selectedOptions.indexOf(option);

    if (index >= 0) {
      this.selectedOptions.splice(index, 1);
      this.formCtrl.setValue(this.selectedOptions);
      this.valueChanged.emit(this.selectedOptions);
    }
  }

  selected(option: any): void {
    if (!this.selectedOptions.includes(option)) {
      this.selectedOptions.push(option);
      this.formCtrl.setValue(this.selectedOptions);
      this.valueChanged.emit(this.selectedOptions);
      this.chipInput.nativeElement.value = '';
      this.autoCompleteControl.setValue('');
    }
  }

  private setFilterdOptions() {
    this.filteredOptions = this.autoCompleteControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value && value[this.textProperty]),
        map(queryText => queryText ? this._filter(queryText) : this.options.slice(0, 100))
      );
  }

  private _filter(queryText: string): any[] {
    const filterValue = queryText?.toLowerCase();
    return this.options.filter(option => this.searchProperties.some(property => option[property]?.toLowerCase()?.includes(filterValue)))
      .slice(0, 100);
  }
}
