import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { HasPermissionsPipe } from '@shared/pipes/has-permissions.pipe';
import { PagePermissions } from 'src/app/shared/models/permissions';
import { LandingModulesNames, MenuItem, ModulePage, ModulePageOpen } from '@shared/models/modules-landing.model';
import { Subscription } from 'rxjs';
import { AccountSettingsService } from '@core';
import { RolesEnum } from 'src/app/shared/models/roles';
import { IsInRolePipe } from '@shared/pipes/is-in-role.pipe';
import { PagesMenuService } from '@shared/services/pages-menu.service';
import { PageMenuDateService } from '@shared/services/page-menu-date.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-modules-landing-controller',
  template: '',
})
export class ModulesLandingControllerComponent implements AfterViewInit, OnDestroy {
  menuItems: MenuItem[] | any[] = [...this.menuService.menu_items]
  starredPages: ModulePage[] = [];
  openedPages: ModulePageOpen[] = [];
  subscriptions: Subscription[] = [];
  newSalesOrderLayout: boolean | null = false;
  landingModulesNames = LandingModulesNames;
  constructor(public menuService: PagesMenuService, public hasPermissions: HasPermissionsPipe,
    private accountSettingsService: AccountSettingsService, public isInRole: IsInRolePipe, public pageMenuDateService: PageMenuDateService, public route: Router) {
    this.menuService.loadStarredPages();
    this.accountSettingsService.salesOrderLayoutChanged$.subscribe(res => {
      this.newSalesOrderLayout = res;
      this.menuService.menuItemsMapper([...this.menuItems]);
      this.pageMenuDateService.menuItemsMapper([...this.menuItems]);
    });

  }
  public get pagePermissions(): typeof PagePermissions {
    return PagePermissions;
  }
  public get roles(): typeof RolesEnum {
    return RolesEnum;
  }

  private async loadAccountSettings() {
    const accountSettings = await this.accountSettingsService.loadAccountSettings();
    this.newSalesOrderLayout = accountSettings?.newSalesOrderLayout ?? false;
    this.menuService.loadStarredPagesList();
    this.pageMenuDateService.loadOpenedPagesList();
  }

  ngAfterViewInit(): void {
    this.loadAccountSettings();

    const starredPages$ = this.menuService.starredPages$.subscribe((res: ModulePage[]) => {
      this.starredPages = res;
      this.menuService.menuItemsMapper([...this.menuItems]);
    });

    const openedPages$ = this.pageMenuDateService.openedPages$.subscribe((res: ModulePageOpen[]) => {
      this.openedPages = res;
      this.pageMenuDateService.menuItemsMapper([...this.menuItems]);
    });

    this.subscriptions.push(starredPages$);
    this.subscriptions.push(openedPages$);
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub?.unsubscribe());
  }
  onToggleStarred(isStarred: boolean, data: ModulePage, moduleItem: MenuItem) {
    this.menuService.onToggleStarred(isStarred, data, moduleItem);
  }

  onRouteLastTimeClicked(moduleItem: ModulePageOpen | any) {
    this.pageMenuDateService.getClickedDate(moduleItem.id);
  }
}
