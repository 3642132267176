import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppSettingsService } from '@core';
import { PurchaseInvoice, PurchaseInvoicePaymentStatus, PurchaseInvoiceQuery, Supplier, Tax } from '../models';
import { ErrorMessageFormatter, PagedList, QueryStringHelper } from '@shared';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PurchaseInvoiceFormComponent } from '../components/purchase-invoice-form/purchase-invoice-form.component';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { EntityCode } from '@shared/models/entity-code';
import { PurchaseOrder } from 'src/app/purchasing/models/purchasing-purchase-order';
import { PrchLocation } from 'src/app/purchasing/models/prch-location';
import { isEqual } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PurchaseInvoiceService {

  baseUrl: string = '';

  private readonly _invoices = new BehaviorSubject<PurchaseInvoice[]>([]);
  readonly invoices$ = this._invoices.asObservable();
  public get invoices(): PurchaseInvoice[] {
    return this._invoices.getValue();
  }
  private set invoices(val: PurchaseInvoice[]) {
    this._invoices.next(val);
  }

  initialQuery: PurchaseInvoiceQuery = {
    offset: 0,
    limit: 200
  };
  query: PurchaseInvoiceQuery = { ...this.initialQuery };
  totalRowsCount?: number;
  noInvoicesFound = false;
  isLoading: boolean = false;
  currencyNoOfDecimalPlaces: number = 2;
  purchaseInvocieFilter?: BehaviorSubject<PurchaseInvoiceQuery>;

  constructor(private httpClient: HttpClient, private errorMessageFormatter: ErrorMessageFormatter,
    @Inject(MatDialog) private dialog: MatDialog,
    private translateService: TranslateService,
    private snackBar: MatSnackBar) {
    this.baseUrl = AppSettingsService.appSettings.edaraCoreApi.baseUrl;
    if (this.baseUrl.endsWith('/')) {
      this.baseUrl = this.baseUrl.slice(0, -1);
    }
  }

  openNewPurchaseInvoiceDialog() {
    const dialogRef = this.dialog.open(PurchaseInvoiceFormComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      autoFocus: false,
      restoreFocus: false,
      closeOnNavigation: true,
      disableClose: true,
      direction: this.translateService.instant('uiDirection')
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.purchaseInvocieFilter?.next(this.query);
      }
    });
  }

  //#region Purchase Invoice

  findPurchaseInvoices(query: any): Observable<PagedList<PurchaseInvoice[]>> {
    const queryString = new QueryStringHelper().toQueryString(query);
    return this.httpClient.get<PagedList<PurchaseInvoice[]>>(this.baseUrl + '/api/PurchaseInvoices?' + queryString);
  }

  getAllSuppliersAsync(): Promise<Supplier[] | undefined> {
    return firstValueFrom(
      this.httpClient.get<Supplier[]>(this.baseUrl + '/api/Suppliers')
    ).catch(() => { return undefined; });
  }

  loadInvoices() {
    this.invoices = [];
    this.isLoading = true;
    this.findPurchaseInvoices(this.query).subscribe({
      next: res => {
        if (res && res.items && res.items.length > 0) {
          this.isLoading = false;
          this.noInvoicesFound = false;
          this.invoices = res.items.map(x => PurchaseInvoice.clone(x)!);
          this.totalRowsCount = res.totalCount;
        } else {
          this.isLoading = false;
          this.invoices = [];
          this.noInvoicesFound = isEqual(this.query, this.initialQuery);
        }
      },
      error: err => {
        this.noInvoicesFound = true;
        this.isLoading = false;
        this.invoices = [];
        this.handleError(err.error);
      }
    });
  }

  private handleError(err: any) {
    this.snackBar.open(this.errorMessageFormatter.getErrorMessage(err, 'alerts.noPurchaseInvoices'), '✖', { direction: this.translateService.instant('uiDirection') });
  }

  getPurchaseInvoiceById(id: string): Promise<PurchaseInvoice | undefined> {
    return firstValueFrom(
      this.httpClient.get<PurchaseInvoice>(`${this.baseUrl}/api/PurchaseInvoices/${id}`)
    ).catch(() => { return undefined; });
  }

  getPurchaseOrdersByDetailsIds(ids: string[]): Promise<PurchaseOrder[] | undefined> {
    return firstValueFrom(
      this.httpClient.post<PurchaseOrder[]>(`${this.baseUrl}/api/PurchaseOrders/GetByDetailsIds`, {
        PurchaseOrdersItemsIds: ids
      })
    ).catch(() => { return undefined; });
  }

  setPurchaseInvoicePaymentStatus(purchaseInvoiceId: string, paymentStatus: PurchaseInvoicePaymentStatus) {
    return this.httpClient.put(
      `${this.baseUrl}/api/PurchaseInvoices/${purchaseInvoiceId}/PaymentStatus`,
      { id: purchaseInvoiceId, paymentStatus }
    );
  }

  getNextPurchaseInvoiceCode() {
    return this.httpClient.get<EntityCode>(this.baseUrl + '/api/PurchaseInvoices/code');
  }

  async getNextPurchaseInvoiceCodeAsync(): Promise<EntityCode | undefined> {
    return firstValueFrom(
      this.httpClient.get<EntityCode>(this.baseUrl + '/api/PurchaseInvoices/code')
    ).catch(() => { return undefined; });
  }

  getAllLocationsAsync(): Promise<PrchLocation[] | undefined> {
    return firstValueFrom(
      this.httpClient.get<PrchLocation[]>(this.baseUrl + '/api/purchasing/Locations')
    ).catch(() => { return undefined; });
  }

  updatePurchaseInvoice(purchaseInvoice: PurchaseInvoice): Observable<boolean> {
    return this.httpClient.put<boolean>(this.baseUrl + '/api/PurchaseInvoices/' + purchaseInvoice.id, purchaseInvoice);
  }

  createPurchaseInvoice(purchaseInvoice: PurchaseInvoice): Observable<PurchaseInvoice> {
    return this.httpClient.post<PurchaseInvoice>(this.baseUrl + '/api/PurchaseInvoices', purchaseInvoice);
  }

  cancelPurchaseInvoice(id: string) {
    return this.httpClient.put(`${this.baseUrl}/api/PurchaseInvoices/${id}/cancel`, {});
  }

  getAllTaxes(): Observable<Tax[]> {
    return this.httpClient.get<Tax[]>(`${this.baseUrl}/api/Invoicing/taxes`);
  }

  //#endregion

}
