export interface BackgroundJob {
    Id: string;
    Description: string;
    CreatedDate: Date;
    StatusInfo: JobStatusInfo;
}

export interface JobStatusInfo {
    Status: JobStatus;
    Description: string;
    ProgressCounter: string;
    ProgressPercentage: number;
    Result: any;
    current_counter: number;
    total_count: number;
    failed_count: number;
    is_succeeded: boolean;
}

export enum JobStatus {
    Pending,
    Running,
    Completed,
    Failed
}
