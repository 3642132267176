import { CustomerV3 } from "@shared";

export class CustomerSyncResult {
    localId!: string;
    syncStatus!: CustomerV3SyncStatus;
    error?: string;
    customer!: CustomerV3;
}

export enum CustomerV3SyncStatus {
    Pending = 1,
    Processing = 2,
    Success = 3,
    ConflictOrError = 4
}