import { formatCurrency } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { MoneyHelper } from '@shared';

@Pipe({
  name: 'currencyFormatterV3'
})
export class CurrencyFormatterV3Pipe implements PipeTransform {

  locale = 'en-US';
  noOfDecimalPlaces = 2;
  digitsInfo = '1.2-2';
  currencySymbol: string = '';
  currencyCode: string = '';

  constructor(private moneyHelper: MoneyHelper) { }

  transform(value: number) {
    return formatCurrency(
      this.moneyHelper.round(value, this.noOfDecimalPlaces),
      this.locale,
      this.currencySymbol,
      this.currencyCode,
      this.digitsInfo
    ).trim();
  }

}