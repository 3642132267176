export async function everyAsync<T>(arr: T[], predicate: (element: T) => Promise<boolean>): Promise<boolean> {
    for (let e of arr) {
        if (!await predicate(e)) return false;
    }
    return true;
}

export async function findAsync<T>(arr: T[], asyncCallback: (element: T) => Promise<boolean>): Promise<T | undefined> {
    const promises = arr.map(asyncCallback);
    const results = await Promise.all(promises);
    const index = results.findIndex(result => result);
    return index !== -1 ? arr[index] : undefined;
}
