import { PaymentType } from "./sales-order";
import { PaymentType as CashRegisterPaymentType } from "./payment-type";

export class PaymentInfo {
    netTotal!: number;
    grossTotal?: number;
    totalDiscount?: number;
    subTotal?: number;
    vat?: number;
    cashPaid?: number;
    change?: number;
    paymentType: PaymentType = PaymentType.Cash;
    defaultPaymentType?: CashRegisterPaymentType;

    cashAccountId?: number;
    cashAccountName?: string;
    cashAmount?: number;
    bankAccountId?: number;
    bankAccountName?: string;
    bankAmount?: number;
    onAccountAmount?: number;
}
