import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { MoneyHelper } from '@shared';

@Pipe({
  name: 'quantityFormatterV3'
})
export class QuantityFormatterV3Pipe implements PipeTransform {

  locale = 'en-US';
  noOfDecimalPlaces = 3;
  digitsInfo = '1.3-3';

  constructor(private moneyHelper: MoneyHelper) { }

  transform(value: number) {
    return formatNumber(
      this.moneyHelper.round(value, this.noOfDecimalPlaces),
      this.locale,
      this.digitsInfo
    );
  }

}
