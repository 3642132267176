import { Component, OnInit } from '@angular/core';
import { AccountSettingsService } from '@core/services/account-settings.service';

@Component({
  selector: 'app-preview-features',
  templateUrl: './preview-features.component.html',
  styleUrls: ['./preview-features.component.scss']
})
export class PreviewFeaturesComponent implements OnInit {

  newSalesOrderLayout = false;
  loading = false;

  constructor(private accountSettingsService: AccountSettingsService) { }

  ngOnInit(): void {
    this.loadAccountSettings();
  }

  onNewSalesOrderLayoutChanged(event: any) {
    const settings = {
      newSalesOrderLayout: event.checked
    };
    this.accountSettingsService.saveAccountSettings(settings);
    this.accountSettingsService.salesOrderLayoutChanged$.next(event.checked);
  }

  private async loadAccountSettings() {
    this.loading = true;
    const accountSettings = await this.accountSettingsService.loadAccountSettings();
    this.newSalesOrderLayout = accountSettings?.newSalesOrderLayout ?? false;
    this.loading = false;
  }

}
