import { SystemSettingsService } from "src/app/core/services/system-settings.service";

export class InvoicingSettings {
  defaultCurrency?: string;
  compoundDiscount?: boolean;

  public async loadSettings(systemSettingsService: SystemSettingsService) {
    const invoicingSettings = await Promise.all([
      systemSettingsService.defaultCurrency(),
      systemSettingsService.PurchaseInvoicesSettings()
    ]);

    this.defaultCurrency = invoicingSettings[0];
    this.compoundDiscount = invoicingSettings[1].compoundDiscount;
  }

  public get requiredSettingsLoaded(): boolean {
    return !!this.defaultCurrency;
  }
}
