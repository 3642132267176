import { EventEmitter, Injectable } from '@angular/core';
import { AuthService } from '@auth';
import { SecurityService } from './security.service';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from './app-settings.service';
import { AccountSettings, DexieDbProvider } from '@shared';
import { FirestoreService } from './firestore.service';
import { PullDataService } from './pull-data.service';
import { PushDataService } from './push-data.service';
import { Guid } from 'guid-typescript';
import { EdaraNativeService, OnlineOfflineService } from '@core';

@Injectable({
  providedIn: 'root'
})
export class SyncService {

  syncStatusChanged: EventEmitter<boolean>;

  constructor(
    private authService: AuthService,
    private pullDataService: PullDataService,
    private pushDataService: PushDataService,
    private securityService: SecurityService,
    private localDbProvider: DexieDbProvider,
    private firestoreService: FirestoreService,
    private edaraNativeService: EdaraNativeService,
    private onlineOfflineService: OnlineOfflineService) {

    this.syncStatusChanged = pullDataService.syncStatusChanged;

    // Sync data to indexeddb on login
    this.authService.userLoggedIn$.subscribe({
      next: () => {
        this.pullDataService.requestSync();
        this.pushDataService.pushLocalUpdatesToServer();
        // This line cause Bug 17063: Sentry error 'Error: Uncaught (in promise): NotAllowedError: Permission denied'
        //this.requestPeriodicSync(); // Register periodic background sync
        this.edaraNativeService.restoreReplicatedSalesOrdersV3ToIndexedDb();
      }
    });

    this.onlineOfflineService.connectionChanged.subscribe({
      next: () => {
        if (this.onlineOfflineService.isOnline && this.authService.isAuthenticated()) {
          this.edaraNativeService.restoreReplicatedSalesOrdersV3ToIndexedDb();
        }
      }
    });

    // Schedule sync to run every X minutes
    if (environment.production) {
      setInterval(() => {
        this.pullDataService.requestSync();
        this.pushDataService.pushLocalUpdatesToServer();
      }, AppSettingsService.appSettings.offlineMode.syncIntervalMs);
    }

    if (`serviceWorker` in navigator) {
      navigator.serviceWorker.addEventListener('message', async event => this.authHeadersEventHandler(event));
    }
  }

  requestPeriodicSync() {
    navigator.serviceWorker.ready.then((swRegistration: any) => swRegistration.periodicSync.register('requestPeriodicSync', {
      minInterval: 12 * 60 * 60 * 1000 // 12 Hours
    }));
  }

  pushLocalUpdatesToServer() {
    this.pushDataService.pushLocalUpdatesToServer();
  }

  private async authHeadersEventHandler(event: any) {
    if (event.data.message === 'requestingAuthHeaders') {
      if (!this.authService.isAuthenticated()) {
        return;
      }

      const isFireStoreLoggedIn = await this.firestoreService.isLoggedIn();
      if (!isFireStoreLoggedIn) {
        await this.authService.renewFirebaseToken();
      }

      const firestoreIdToken = await this.firestoreService.getIdToken();
      const accountSettings = await this.firestoreService.getDocValueAsync<AccountSettings>(`accountSettings/${this.authService.currentUserId}`);
      const msg = {
        syncBatchId: Guid.create().toString(),
        accessToken: this.authService.accessToken,
        tenantId: this.authService.currentTenant,
        securityKey: this.securityService.key,
        firestoreIdToken: firestoreIdToken,
        userContext: {
          id: this.authService.currentUserId,
          email: this.authService.currentUserEmail,
          username: this.authService.currentUserEmail
        },
        environmentContext: {
          appVersion: environment.version,
          organizationId: this.authService.currentTenant,
          organizationName: this.authService.currentOrganization?.name
        },
        accountSettingsContext: accountSettings
      };

      if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
          'command': 'authHeaders',
          'message': msg,
          'source': event.data.source
        });
      }
    }
  }

}
